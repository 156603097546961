import { makeAutoObservable, runInAction } from "mobx";

export var BatchSortColumns = {
	BatchNumber: 0,
	Type: 1,
	TotalTransactions: 2,
	Amount: 3
}

export default class BatchesStore {
    RetrievingPendingBatches = false;
	RetrievingPendingWithdrawalBatches = false;
	PostingPendingBatches = false;

	SortColumn = BatchSortColumns.BatchNumber;
	SortDirection = 0;

    constructor(batchesService, toastStore) {
        /**@type { import("../Services/BatchesService").BatchesService } */
        this.BatchesService = batchesService;

        /**@type { import("../Stores/ToastStore").ToastStore } */
        this.ToastStore = toastStore;

        makeAutoObservable(this);
    }

    /**
     * Returns all batches that have not been cleared for the day
	 * @returns { Promise<Array<import("../Services/BatchesService").Batch> }
     */
    async GetPendingBatches() {
        this.RetrievingPendingBatches = true;
        var result = await this.BatchesService.GetPendingBatches(this.SortColumn, this.SortDirection);
        runInAction(() => this.RetrievingPendingBatches = false);

        if(result) {
            if(result.Success) {
                return result.Data;
            } else {
                this.ToastStore.ShowErrorToast("An error occurred retrieving the batches: " + result.Message, "Error Retrieving Batches");
            }
        }

        return null;
    }

	/**
     * posts all available pending batches
	 * @returns { Promise<boolean> }
     */
	 async PostPendingBatches() {
        this.PostingPendingBatches = true;
        var result = await this.BatchesService.PostPendingBatches();
        runInAction(() => this.PostingPendingBatches = false);

        if(result) {
            if(result.Success) {
                return result.Success;
            } else {
                this.ToastStore.ShowErrorToast("An error occurred attempting to post pending batches: " + result.Message, "Error Posting Batches");
            }
        }

        return false;
    }

	/**
     * Returns all withdrawal batches that have not been cleared for the day
	 * @returns { Promise<Array<import("../Services/BatchesService").Batch> }
     */
	 async GetPendingWithdrawalBatches() {
        this.RetrievingPendingWithdrawalBatches = true;
        var result = await this.BatchesService.GetPendingWithdrawalBatches(this.SortColumn, this.SortDirection);
        runInAction(() => this.RetrievingPendingWithdrawalBatches = false);

        if(result) {
            if(result.Success) {
                return result.Data;
            } else {
                this.ToastStore.ShowErrorToast("An error occurred retrieving the batches: " + result.Message, "Error Retrieving Batches");
            }
        }

        return null;
    }
}