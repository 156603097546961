import { FieldArray } from "formik";
import { DateTime } from "luxon";
import { useState } from "react";
import { Button, Col, FormLabel, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";

export default function MemberNotes(props) {
    const [addingNote, setAddingNote] = useState(false);
    const [noteText, setNoteText] = useState("");
    const store = useSessionStore();

    function handleCompleteNoteActionClicked (pushCallback) {
        if(pushCallback && noteText && noteText.length > 0) {
            pushCallback({ Username: store.Session.User.Name, Text: noteText, DateCreated: DateTime.now() });
        }

        setAddingNote(false);
        setNoteText("");
    }

    return <div>
        <FieldArray name="MemberNotes">
        {
            ({push}) => 
            <div>
            {
                addingNote === false &&
                <div>
                    <Table>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Note</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                
                        <tbody> 
                        {
                            props.member.MemberNotes.map((note, index) =>
                            <tr key={ index }>                                    
                                <td>{ note.Username }</td>
                                <td>{ note.Text }</td>
                                <td>{ DateTime.fromISO(note.DateCreated).toFormat("yyyy/MM/dd") }</td>
                            </tr>)
                        }
                        {
                            props.member.MemberNotes.length === 0 && 
                            <tr className="text-center">
                                <td colSpan="3"><b className="text-muted">No Notes Available</b></td>
                            </tr>
                        }
                        </tbody>
                    
                    </Table>
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <Button variant="success" onClick={ e => setAddingNote(true) }>Add New Note</Button>
                        </Col>
                    </Row>
                </div>
            }
            {
                addingNote &&
                <div>
                    <Row>
                        <Col>
                            <FormLabel>Note Details</FormLabel>
                            <textarea className="form-control" value={ noteText } onChange={ e => setNoteText(e.target.value) }></textarea>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-3 d-flex justify-content-end">
                            <Button className="mr-3" variant="success" onClick={ e => handleCompleteNoteActionClicked(push) }>Save Note</Button>
                            <Button variant="danger" onClick={ e => handleCompleteNoteActionClicked() }>Cancel</Button>
                        </Col>
                    </Row>
                </div>
            }
        </div>
        }
        </FieldArray>
        
    </div>
}