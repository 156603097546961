import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { DataChangeTypes } from "../../../../Stores/ApprovalsStore";
import { useSessionStore } from "../../../../Stores/SessionStore";
import BusyIndicator from "../../../Core/BusyIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../Core/Modal";
import { DateTime } from "luxon";

export var DataChangeApprovalModal = observer(function DataChangeApprovalModal(props) {
    var store = useSessionStore();

    const [approval, setApproval] = useState(null);

    const [dataFrom, setDataFrom] = useState(null);
    const [dataTo, setDataTo] = useState(null);

    const [rejectReason, setRejectReason] = useState("");
    
    function convertToPropertyArray(data) {
        var array = []

        for (const property in data) {
            //kick out anything with an Id
            if(property.includes("Id")) {
                continue;
            }

            //insert spaces for readability
            var propertyName = "";
            
            for(var i = 0; i < property.length; i++) {
                propertyName += (property[i] === property[i].toUpperCase()) ? " " + property[i] : property[i];
            }

            array.push({ Label: propertyName, Value: data[property] });
        }

        return array;
    }

    useEffect(() => {
        async function getDataChangeApproval() {
            var approval = await store.ApprovalsStore.GetDataChangeApproval(props.approvalId);
    
            if(approval) {
                var from = convertToPropertyArray(JSON.parse(approval.DataFrom));
                var to = convertToPropertyArray(JSON.parse(approval.DataTo));

                setDataFrom(from);
                setDataTo(to);
                setApproval(approval);

            } else {
                props.onSubmitComplete(false)
            }
        }

        if(props.approvalId) {
            getDataChangeApproval();
        }
    }, [props, store.ApprovalsStore]);

    async function handleSubmit (approved) { 
        var result = await store.ApprovalsStore.SubmitDataChangeApproval(props.approvalId, approved, rejectReason);

        if(result) {
            props.onSubmitComplete(true);
        }
    }

    return  <Modal size="lg" show={props.show} title={ <h4><FontAwesomeIcon icon={ faThumbsUp }></FontAwesomeIcon> Approval Details</h4> }>
                {
                    store.ApprovalsStore.GettingApproval === false && approval !== null &&
                    <div>
                        <Row className="mb-1">
                            <Col lg="3">
                            <b>Requested By: </b>
                            </Col>
                            <Col>
                                { approval.RequestUserName }
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col lg="3">
                                <b>Type: </b>
                            </Col>
                            <Col>
                                { DataChangeTypes.GetDataChangeTypeText(approval.DataChangeTypeId) }
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col lg="3">
                                <b>Date Requested: </b>
                            </Col>
                            <Col>
                                { DateTime.fromISO(approval.DateCreated).toFormat("yyyy-MM-dd") }
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row className="mb-3">
                            <Col lg="6">
                                <h5 className="mb-3">Old</h5>
                                {
                                    dataFrom.map((property) =>
                                    <Row className="mb-1">
                                        <Col><b>{property.Label}: </b></Col> <Col>{ property.Value }</Col>
                                    </Row>
                                    )
                                }
                            </Col>
                            <Col lg="6">
                                <h5 className="mb-3">New</h5>
                                {
                                    dataTo.map((property) =>
                                    <Row className="mb-1">
                                        <Col><b>{property.Label}: </b></Col> <Col>{ property.Value }</Col>
                                    </Row>
                                    )
                                }
                            </Col>
                        </Row>
                        
                        {
                            approval.DateRejected &&
                            <Alert variant="success" className="mt-3">
                                <div>
                                    Rejected On: { DateTime.fromISO(approval.DateRejected).toFormat("yyyy-MM-dd") }
                                </div>
                            </Alert>
                        }
                        {
                            approval.DateApproved &&
                            <Alert variant="success" className="mt-3">
                                <div>
                                    Approved On: { DateTime.fromISO(approval.DateApproved).toFormat("yyyy-MM-dd") }
                                </div>
                            </Alert>
                        }
                         
                        <Row className="modal-footer pb-0">
                            {
                                approval.DateRejected === null && approval.DateApproved === null &&
                                <div>
                                    <Button variant="success" onClick={ e => handleSubmit(true) } disabled={ store.ApprovalsStore.SubmittingDataChangeApproval } type="button">{ store.ApprovalsStore.ApprovingDataChange ? "Approving..." : "Approve" }</Button>
                                    <Button className="ml-2" variant="danger" onClick={ e => handleSubmit(false) } disabled={ store.ApprovalsStore.SubmittingDataChangeApproval } type="button">{ store.ApprovalsStore.RejectingDataChange ? "Rejecting..." : "Reject" }</Button>
                                </div>
                            }                            
                            <Button variant="secondary" onClick={ e => props.onSubmitComplete(false) } disabled={ store.ApprovalsStore.SubmittingDataChangeApproval } type="button">Cancel</Button>
                        </Row>
                    </div>
                }
                <BusyIndicator show={ store.ApprovalsStore.GettingApproval } text="Retrieving Approval Details..."></BusyIndicator>
            </Modal>
});

export default DataChangeApprovalModal