import { faEdit, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { DataChangeTypes } from "../../../Stores/ApprovalsStore";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import Paginator from "../../Core/Paginator";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import DataChangeApprovalModal from "./DataChangeApprovals/DataChangeApprovalModal";

export const ApprovalsIndex = observer(function ApprovalsIndex(props) {
    const session = useSessionStore();

    const [detailsModalShown, showDetailsModal] = useState(false);
    const [currentApprovalId, setCurrentApprovalId] = useState(null);

    useEffect(() =>
    {
        session.ApprovalsStore.GetDataChangeApprovals();

        //check if an approval id is in the url
        if(window.location.hash.includes("approvalId")) {
            var approvalId = window.location.hash.split("?")[1].split("=")[1];

            handleViewApprovalClicked(approvalId);
        }
    }, [session.ApprovalsStore]);

    function handleApprovalSubmissionComplete(success) {
        if(success) {
            session.ApprovalsStore.GetDataChangeApprovals();
        }

        showDetailsModal(false);
        setCurrentApprovalId(null);
    }

    function handleViewApprovalClicked(approvalId) {
        setCurrentApprovalId(approvalId);
        showDetailsModal(true);
    }

    return <Panel>
            <PanelHeader icon={ faThumbsUp } title="Approvals"></PanelHeader>
            <Row>
                <Col lg={12}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Requested By</th>
                                <th>Requested On</th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        {
                            session.ApprovalsStore.GettingDataChangeApprovals === false &&
                            <tbody>
                            {
                                session.ApprovalsStore.DataChangeApprovals.length > 0 &&
                                session.ApprovalsStore.DataChangeApprovals.map((approval) =>
                                <tr key={ approval.Id }>
                                    <td>{ DataChangeTypes.GetDataChangeTypeText(approval.DataChangeTypeId) }</td>
                                    <td>{ approval.RequestingUser }</td>
                                    <td>{ DateTime.fromISO(approval.DateCreated).toFormat("yyyy-MM-dd") }</td>
                                    <td>
                                        <Row>
                                            <Col  className="text-center">
                                                <a href="javascript:void(0)" title="View Approval Details" onClick={ e => handleViewApprovalClicked(approval.Id) }><FontAwesomeIcon icon={ faEdit }></FontAwesomeIcon></a>
                                            </Col>
                                        </Row>
                                    </td>
                                </tr>
                                )
                            }

                            {
                                session.ApprovalsStore.DataChangeApprovals.length === 0 &&
                                <tr className="text-center">
                                    <td colSpan="4"><b className="text-muted">No Pending Approvals</b></td>
                                </tr>
                            }
                            </tbody>
                        }
                    </Table>
                    {
                        session.ApprovalsStore.GettingDataChangeApprovals === false && session.ApprovalsStore.DataChangeApprovals.length > 0 &&
                        <Paginator paginator={ session.ApprovalsStore.Paginator }></Paginator>
                    }
                    <BusyIndicator text="Retrieving Outstanding Approvals..." show={ session.ApprovalsStore.GettingDataChangeApprovals }></BusyIndicator>
                </Col>
            </Row>
            <DataChangeApprovalModal onSubmitComplete={ handleApprovalSubmissionComplete } show={ detailsModalShown } approvalId={ currentApprovalId }></DataChangeApprovalModal>
        </Panel>
});

export default ApprovalsIndex;