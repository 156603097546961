import { faPlusCircle, faUserPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { MemberStatuses } from "../../../Stores/Members/MemberStore";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import Modal from "../../Core/Modal";
import Paginator from "../../Core/Paginator";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import SearchBox from "../../Core/SearchBox";
import MemberActions from "./Actions/MemberActions";
import MemberDetails from "./MemberDetails";

export const MembersIndex = observer(function Index(props) {
    const session = useSessionStore();

    const [detailsModalShown, showDetailsModal] = useState(false);

    useEffect(() => {
        session.MembersStore.CRUD.GetEntries();
    }, [session.MembersStore]);

    function handleAddNewMemberClicked() {
        showDetailsModal(true);
    }

    function handleMemberAddComplete(success) {
        if(success) {
            session.MembersStore.CRUD.GetEntries();
        }

        showDetailsModal(false);
    }

	function handleFilterApply() {
		session.MembersStore.Paginator.SetPageIndex(0);
	}

    return <Panel>
            <PanelHeader icon={ faUsers } title="Members">
                <Button variant="outline-primary" onClick={ handleAddNewMemberClicked }><FontAwesomeIcon icon={ faPlusCircle }></FontAwesomeIcon> Add Member</Button>
            </PanelHeader>
            <Form className="mb-3 d-flex">
                <SearchBox action={ handleFilterApply } placeholder="Search Members..." value={ session.MembersStore.Search } onChange={ e => session.MembersStore.Search = e.target.value }></SearchBox>
                <Button onClick={ handleFilterApply }>Apply</Button>
            </Form>
            <Row>
                <Col lg={12}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Service Number (SNO)</th>
                                <th>Department</th>
                                <th>Status</th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        {
                            session.MembersStore.CRUD.GettingEntries === false &&
                            <tbody>
                            {
                                session.MembersStore.CRUD.Entries.length > 0 &&
                                session.MembersStore.CRUD.Entries.map((member) =>
                                <tr key={ member.Id }>
                                    <td>{ member.Name }</td>
                                    <td>{ member.ServiceNumber }</td>
                                    <td>{ member.Department }</td>
                                    <td>{ MemberStatuses.GetMemberStatusText(member.MemberStatusId) }</td>
                                    <td className="align-middle">
                                        <MemberActions member={ member }></MemberActions>
                                    </td>
                                </tr>
                                )
                            }

                            {
                                session.MembersStore.CRUD.Entries.length === 0 &&
                                <tr className="text-center">
                                    <td colSpan="5"><b className="text-muted">No members returned</b></td>
                                </tr>
                            }
                            </tbody>
                        }
                    </Table>
                    {
                        session.MembersStore.CRUD.GettingEntries === false && session.MembersStore.CRUD.Entries.length > 0 &&
                        <Paginator paginator={ session.MembersStore.Paginator }></Paginator>
                    }
                    <BusyIndicator text="Retrieving Members..." show={ session.MembersStore.CRUD.GettingEntries }></BusyIndicator>
                </Col>
            </Row>
            <Modal size="lg" show={ detailsModalShown } title="Add New Member" icon={ faUserPlus } onHide={ e => handleMemberAddComplete(false)}>
                <MemberDetails onEditComplete={ handleMemberAddComplete }></MemberDetails>
            </Modal>
        </Panel>
});

export default MembersIndex;