import { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { SaveReportFile } from "../../../../../Stores/AdhocReportsStore";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import NumberField from "../../../../Core/NumberField";

export default function IT3BForms(props) {
	const store = useSessionStore();

	const [downloadingReport, setDownloadingReport] = useState(false);
	const [value, setValue] = useState(200);

	async function DownloadReport() {
		if(value && value > 0) {
			setDownloadingReport(true);
			var result = await store.PeriodEndService.GetIT3BReport(value);
			setDownloadingReport(false);
	
			if(result && result.Success) {			
				SaveReportFile(result.Data);			
			} else {
				store.ToastStore.ShowErrorToast("An error ocurred trying to retrieve the IT3B report: " + result.Message, "Error");
			}
		}
	}

	return 	<div className="m-n1">
				<Form.Group>
					<Form.Label>Print for interest over</Form.Label>
					<InputGroup>
						<InputGroup.Text>R</InputGroup.Text>
						<NumberField disabled={ downloadingReport } value={ value } onChange={ e => setValue(e.target.value) } className="form-control"></NumberField>
					</InputGroup>
				</Form.Group>
				<Row className="clearfix">
					<Col>
						<Button onClick={ DownloadReport } className="float-right" disabled={ downloadingReport || props.disabled } type="button">{ downloadingReport ? "Downloading..." : "Download IT3B Forms" }</Button>
					</Col>
				</Row>
			</div>
}