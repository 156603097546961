import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import EndOfDay from "./EndOfDay/EndOfDay";
import EndOfMonth from "./EndOfMonth/EndOfMonth";
import EndOfYear from "./EndOfYear/EndOfYear";

export default function PeriodEndIndex() {
	const [gettingPeriodEndDetails, setGettingPeriodEndDetails] = useState(true);
	const [endOfDayError, setEndOfDayError] = useState("");

	const store = useSessionStore();

	async function getPeriodEndDetails() {
		setGettingPeriodEndDetails(true);

		await store.ControlsStore.GetActiveControl();
		var details = await store.PeriodEndService.GetPeriodEndDetails();

		if(details) {
			if(details.Data === false) {
				setEndOfDayError(details.Message);
			} else {
				setEndOfDayError(null);
			}
		}

		setGettingPeriodEndDetails(false);
	}

	useEffect(() => {
		getPeriodEndDetails();
	}, []);

	return 	<Panel>
				<PanelHeader icon={ faCalendarCheck } title="Period End"></PanelHeader>
				{
					(gettingPeriodEndDetails || store.ControlsStore.ActiveControl === null) &&
					<BusyIndicator show text="Retrieving Period End Details..."></BusyIndicator>
				}
				{
					gettingPeriodEndDetails === false && store.ControlsStore.ActiveControl &&
					<Row className="p-3 m-3">
						<Col className="d-flex justify-content-center">
							<EndOfDay onProcessingComplete={ getPeriodEndDetails } control={ store.ControlsStore.ActiveControl } error={ endOfDayError }></EndOfDay>
						</Col>
						<Col className="d-flex justify-content-center">
							<EndOfMonth onProcessingComplete={ getPeriodEndDetails }  control={ store.ControlsStore.ActiveControl }></EndOfMonth>
						</Col>
						<Col className="d-flex justify-content-center">
							<EndOfYear onProcessingComplete={ getPeriodEndDetails }  control={ store.ControlsStore.ActiveControl }></EndOfYear>
						</Col>
					</Row>
				}
			</Panel>
}