import { Accordion, Col, Row } from "react-bootstrap";
import AccordianItem from "../../Core/AccordianItem";
import MissingMembersList from "./MissingMembersList";
import TerminatedMembersList from "./TerminatedMembersList";

export default function FailedDepositsVerificationResults(props) {
	return 	<Row>
				<Col>
					<Accordion defaultActiveKey="0">
						<AccordianItem eventKey="0" title={`Terminated Members (${props.result.TerminatedMembers.length})`}>
							<TerminatedMembersList members={ props.result.TerminatedMembers } onMemberReinstated={ props.onMemberReinstated } ></TerminatedMembersList>
						</AccordianItem>
						<AccordianItem eventKey="1" title={`Missing Members (${props.result.MissingMembers.length})`}>
							<MissingMembersList members={ props.result.MissingMembers } onMemberAdded={ props.onMemberAdded }></MissingMembersList>
						</AccordianItem>
					</Accordion>
				</Col>
			</Row>
}