import APIBase from "./APIBase";

/**
 * @typedef MemberBalances
 * @property {string} MemberId
 * @property {string} Id
 * @property {number} OpeningBalance
 * @property {number} CurrentBalance
 * @property {number} TotalRandage
 * @property {number} TotalInterest
 * @property {number} TotalDeposits
 * @property {number} TotalWithdrawals
 * @property {number} TotalBankCharges
 * @property {number} TotalDeathBenefitPremiums
 * @property {number} TotalWriteOffs
 * @property {number} TotalOnHold
 * @property {string} LastDeposit
 * @property {string} LastWithdrawal
 * @property {string} LastRandage
 */

/**
 * @typedef MemberBalanceSummary
 * @property {number} MaxTransfer
 * @property {number} FreeBalance
 * @property {number} CurrentBalance
 */

/**
 * @typedef MemberBalancesService
 * @type {MemberBalancesService}
 */
export default class MemberBalancesService extends APIBase {
    /**
     * @param {string} apiUrl 
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
     constructor(apiUrl, sessionStore) {
        super(apiUrl + "MemberBalances", sessionStore);
    }

    /**
     * @param {string} memberId The id of the member whose balances should be retrieved
     * @returns { import("./APIBase").RequestResult<MemberBalances> }
     */
    async GetMemberBalances(memberId) {
        return this.Get(`GetMemberBalances?memberId=${memberId}`);
    }

    /**
     * @param {string} memberId The id of the member whose balance summary should be retrieved
     * @returns { import("./APIBase").RequestResult<MemberBalanceSummary> }
     */
     async GetBalanceSummaryForMember(memberId) {
        return this.Get(`GetBalanceSummaryForMember?memberId=${memberId}`);
    }
}