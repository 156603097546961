import { faPlusCircle, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Table } from "react-bootstrap";
import ClickIcon from "../../Core/ClickIcon";
import Modal from "../../Core/Modal";
import MemberDetails from "../Members/MemberDetails";

export default function MissingMembersList(props) {
	const [addMemberModalShown, showAddMemberModal] = useState(false);
	const [currentServiceNumber, setCurrentServiceNumber] = useState(false);

	function handleAddMemberClicked(serviceNumber) {
		setCurrentServiceNumber(serviceNumber);
		showAddMemberModal(true);
	}

    function handleMemberAddComplete(success) {
        if(success && props.onMemberAdded) {
            props.onMemberAdded(currentServiceNumber);
        }

        showAddMemberModal(false);
    }

	return <Table striped bordered hover>
		<thead>
			<tr>
				<th>Service Number</th>
				<th>Member Name</th>
				<th>Actions</th>
			</tr>
		</thead>
		<tbody>
			{
				props.members.length > 0 &&props.members.map((member) =>
				<tr>
					<td>{ member.ServiceNumber }</td>
					<td>{ member.Name }</td>
					<td>
						<ClickIcon title="Add Member" onClick={ e => handleAddMemberClicked(member.ServiceNumber) } icon={ faPlusCircle }></ClickIcon>
					</td>
				</tr>)
			}
			{
				props.members.length === 0 &&
				<tr><td colSpan="3" className="text-muted text-center">There are no missing members in this import file</td></tr>
			}
		</tbody>
		<Modal size="lg" show={ addMemberModalShown } title="Add New Member" icon={ faUserPlus } onHide={ e => handleMemberAddComplete(false)}>
			<MemberDetails onEditComplete={ handleMemberAddComplete }></MemberDetails>
		</Modal>
	</Table>
}