import { Button, Card, Col, Row } from "react-bootstrap";
import InfoLabel from "../../Core/InfoLabel";

export default function DepositsImportSummary(props) {
	return 	<Card className="mb-3">
				<Card.Header>
					Verification Results
				</Card.Header>
				<Card.Body>
					<Row className="mb-3">
						<Col>
							<InfoLabel label="Total Deposits" text={ props.result.TotalDeposits }></InfoLabel>
						</Col>
						<Col>
							<InfoLabel label="Deposits Amount" text={ `R ${props.result.DepositsAmount.toFixed(2)}`}></InfoLabel>
						</Col>
						<Col>
							<InfoLabel label="Terminated Members" text={ props.result.TerminatedMembers.length }></InfoLabel>
						</Col>
						<Col>
							<InfoLabel label="Missing Members" text={ props.result.MissingMembers.length }></InfoLabel>
						</Col>
					</Row>
					<hr></hr>
					{
						props.result.ImportValid === false &&
						<Row>
							<Col lg="8">
								<h6 className="mb-3 text-danger">This Deposits File cannot be imported due to issues picked up during verification. Fix all outstanding issues and re-verify the file before attempting to import.</h6>
							</Col>
							<Col>
								<Button className="float-right" disabled={ props.verifyingFile } size="lg" variant="success" onClick={ props.onVerifyFileClicked }>{ props.verifyingFile ? "Verifying..." : "Re-verify Deposits File"} </Button>
							</Col>
						</Row>
					}
					{
						props.result.ImportValid === true &&
						<Row>
							<Col lg="8">
								<h6 className="mb-3 text-success">The Deposits File has been verified and is ready to be imported.</h6>
							</Col>
							<Col>
								<Button className="float-right" disabled={ props.importingFile } size="lg" variant="success" onClick={ props.onImportFileClicked }>{ props.importingFile ? "Importing..." : "Import Deposits File"} </Button>
							</Col>
						</Row>
					}

				</Card.Body>
			</Card>
}