import { useState, useEffect } from "react"
import { Formik, Form as FormikForm } from "formik";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { Button, Col, Row } from "react-bootstrap";
import { Form } from "react-bootstrap-formik";
import { useSessionStore } from "../../../Stores/SessionStore";
import Modal from "../../Core/Modal";
import * as yup from 'yup';
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export var CreateControlModal = observer(function CreateControlModal(props) {
    const store = useSessionStore();
	const [initialValues, setInitialValues] = useState(null);

	useEffect(() => {
		if(props.show) {
			setInitialValues(store.ControlsStore.ActiveControl ? {
				...store.ControlsStore.ActiveControl,
				ProcessingDate: DateTime.fromISO(store.ControlsStore.ActiveControl.ProcessingDate).toFormat("yyyy-MM-dd"),
				MonthEndDate: DateTime.fromISO(store.ControlsStore.ActiveControl.MonthEndDate).toFormat("yyyy-MM-dd"),
				YearEndDate: DateTime.fromISO(store.ControlsStore.ActiveControl.YearEndDate).toFormat("yyyy-MM-dd")} : {
				BankCharges: 0.0,
				RandageRate: 0.0,
				DeathBenefitFundFee: 0.0,
				ProcessingDate: DateTime.now().toFormat("yyyy-MM-dd"),
				MonthEndDate: DateTime.now().toFormat("yyyy-MM-dd"),
				YearEndDate: DateTime.now().toFormat("yyyy-MM-dd")
			})
		}
	}, [props.show])

    async function handleSubmit(values) {
        var result = await store.ControlsStore.CreateControl(values);

        if(result) {
            props.onClose(true);
        }
    }

    const validationSchema =
        yup.object({
            BankCharges: yup.number().typeError("Bank Charges must be a valid number.").required("Bank Charges is required."),
            RandageRate: yup.number().typeError("Randage Rate must be a valid number.").required("Randage Rate is required."),
            DeathBenefitFundFee: yup.number().typeError("Death Benefit Fund Fee must be a valid number.").required("Death Benefit Fund Fee is required."),
            ProcessingDate: yup.date().required("Processing Date is required."),
            MonthEndDate: yup.date().required("Month End Date is required."),
            YearEndDate: yup.date().required("Year End Date is required.")
        }).required();

    return <Modal size="lg" show={props.show} title={ <h4><FontAwesomeIcon icon={ faClipboardCheck }></FontAwesomeIcon> Set Control</h4> }>
        <Formik initialValues={ initialValues } enableReinitialize validationSchema={ validationSchema } onSubmit={ handleSubmit }>
            <FormikForm>
                <Form.Input type="date" label="Processing Date" name="ProcessingDate" />
                <Row>
                    <Col>
                        <Form.Input type="date" label="Month End Date" name="MonthEndDate" />
                    </Col>
                    <Col>
                        <Form.Input type="date" label="Year End Date" name="YearEndDate" />
                    </Col>
                </Row>
                <hr></hr>
                <Form.Input label="Bank Charges" name="BankCharges" />
                <Row>
                    <Col>
                        <Form.Input label="Randage Rate" name="RandageRate" />
                    </Col>
                    <Col>
                        <Form.Input label="Death Benefit Fund Fee" name="DeathBenefitFundFee" />
                    </Col>
                </Row>
                <Row className="modal-footer pb-0">
                    <Button disabled={ store.ControlsStore.CreatingControl } type="submit">{ store.ControlsStore.CreatingControl ? "Saving..." : "Save" }</Button>
                    <Button onClick={ e => props.onClose(false) } className="ml-2" variant="danger" disabled={ store.ControlsStore.CreatingControl } type="button">Cancel</Button>
                </Row>
            </FormikForm>
        </Formik>
    </Modal>
});

export default CreateControlModal;