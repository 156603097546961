import APIBase from "./APIBase";

/**
 * @typedef Batch
 * @property {string} Id
 * @property {number} BatchNumber
 * @property {string} Date
 * @property {number} TransactionTypeId
 * @property {number} TotalTransactions
 * @property {number} Amount
 * @property {boolean} HasTransfers
 * @property {boolean} IsReversalBatch
 */

/**
 * @typedef BatchesService
 * @type {BatchesService}
 */
export default class BatchesService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Batches", sessionStore);
    }

    /**
     * @returns { import("./APIBase").EnumerableRequestResult<Batch> }
     */
     async GetPendingBatches(sortColumn, sortDirection) {
        return this.Get(`GetPendingBatches?sortColumn=${sortColumn}&sortDirection=${sortDirection}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async PostPendingBatches() {
        return this.Post(`PostPendingBatches`);
    }

	/**
     * @returns { import("./APIBase").EnumerableRequestResult<Batch> }
     */
     async GetPendingWithdrawalBatches(sortColumn, sortDirection) {
        return this.Get(`GetPendingWithdrawalBatches?sortColumn=${sortColumn}&sortDirection=${sortDirection}`);
    }
}