import { DateTime } from "luxon";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { useEffect, useState } from "react";
import BusyIndicator from "../../../Core/BusyIndicator";

var MemberBalances = observer(function MemberBalances(props) {
    var currentBalance = 0;
    const store = useSessionStore();
    const [balances, setBalances] = useState({
        OpeningBalance: 0.00,
        CurrentBalance: 0.00,
        TotalRandage: 0.00,
        TotalInterest: 0.00,
        TotalDeposits: 0.00,
        TotalWithdrawals: 0.00,
        TotalBankCharges: 0.00,
        TotalDeathBenefitPremiums: 0.00,
        TotalWriteOffs: 0.00,
        TotalOnHold: 0.00,
        LastDeposit: "None",
        LastWithdrawal: "None",
        LastRandage: "None"
    });

    function updateCurrentBalance(amount) {
        currentBalance += amount;
        return currentBalance.toFixed(2);
    }

    useEffect(() => {
        async function getMemberBalances() {
            var balances = await store.MemberBalancesStore.GetMemberBalances(props.memberId);

            setBalances(balances);
        }

        getMemberBalances();
    }, [props.memberId]);

    return <div>
        <BusyIndicator text="Retrieving Member Balances..." show={ store.MemberBalancesStore.GettingMemberBalances || balances === null }></BusyIndicator>
        {
            store.MemberBalancesStore.GettingMemberBalances === false && balances !== null &&
            <div>
                <Row className="d-flex justify-content-between">
                    <Col><b>Last Withdrawal:</b> { balances.LastWithdrawal ? DateTime.fromISO(balances.LastWithdrawal).toFormat("yyyy-MM-dd") : "None" }</Col>
                    <Col><b>Last Deposit:</b> { balances.LastDeposit ? DateTime.fromISO(balances.LastDeposit).toFormat("yyyy-MM-dd") : "None" }</Col>
                    <Col><b>Last Randage:</b> { balances.LastRandage ? DateTime.fromISO(balances.LastRandage).toFormat("yyyy-MM-dd") : "None" }</Col>
                </Row>
                <Row className="d-flex justify-content-between mt-2">
                    <Col><b>On Hold: </b>R { balances.TotalOnHold.toFixed(2) }</Col>
                </Row>
                <hr className="mx-n3"/>
                <table className="table table-sm text-right table-borderless mb-0">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-left font-weight-bold">Opening Balance</td> <td></td> <td>R { updateCurrentBalance(balances.OpeningBalance) }</td>
                            <td>R { balances.OpeningBalance.toFixed(2) }</td>
                        </tr>
                        <tr>
                            <td class="text-left">Deposits</td> <td></td> <td>R { balances.TotalDeposits.toFixed(2) }</td> <td>R { updateCurrentBalance(balances.TotalDeposits) }</td>
                        </tr>
                        <tr>
                            <td class="text-left">Interest</td> <td></td> <td>R { balances.TotalInterest.toFixed(2) }</td> <td>R { updateCurrentBalance(balances.TotalInterest) }</td>
                        </tr>

                        <tr>
                            <td class="text-left">Withdrawals</td> <td>R { Math.abs(balances.TotalWithdrawals).toFixed(2) }</td> <td></td> <td>R { updateCurrentBalance(balances.TotalWithdrawals) }</td>
                        </tr>
                        <tr>
                            <td class="text-left">Randage</td> <td>R { Math.abs(balances.TotalRandage).toFixed(2) }</td> <td></td> <td>R { updateCurrentBalance(balances.TotalRandage) }</td>
                        </tr>
                        <tr>
                            <td class="text-left">Bank Charges</td> <td>R { Math.abs(balances.TotalBankCharges).toFixed(2) }</td> <td></td> <td>R { updateCurrentBalance(balances.TotalBankCharges) }</td>
                        </tr>
                        <tr>
                            <td class="text-left">Death Benefit Premiums</td> <td>R { Math.abs(balances.TotalDeathBenefitPremiums).toFixed(2) }</td> <td></td> <td>R { updateCurrentBalance(balances.TotalDeathBenefitPremiums) }</td>
                        </tr>
                        <tr>
                            <td class="text-left">Write Offs</td> 
							<td>{ balances.TotalWriteOffs < 0 && <span>R { balances.TotalWriteOffs.toFixed(2) }</span> }</td> 
							<td>{ balances.TotalWriteOffs >= 0 && <span>R { balances.TotalWriteOffs.toFixed(2) }</span> }</td> 
							<td>R { updateCurrentBalance(balances.TotalWriteOffs) }</td>
                        </tr>
                        <tr className="border-top border-bottom">
                            <td class="text-left font-weight-bold">Closing Balance</td> <td></td> <td>R { currentBalance.toFixed(2) }</td> <td>R { currentBalance.toFixed(2) }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
    </div>
});

export default MemberBalances;