import { makeAutoObservable } from "mobx";
import Paginator from "../Utilities/Paginator";
import SimpleCRUD from "../Utilities/SimpleCRUD";

/**
 * @typedef DepartmentStore
 * @type { DepartmentStore }
 */
export default class DepartmentStore {
    Search = "";

    constructor(departmentsService, toastStore) {
        /**@type { import("../Services/DepartmentsService").DepartmentsService } */
        this.DepartmentsService = departmentsService;

        /**@type { import("../Stores/ToastStore").ToastStore } */
        this.ToastStore = toastStore;

        this.Paginator = new Paginator(async () => this.CRUD.GetEntries());
        this.CRUD = new SimpleCRUD("Department", this.ToastStore, this.Paginator, this.GetDepartment, this.GetDepartments,
                                        this.CreateDepartment, this.UpdateDepartment, this.DeleteDepartment);

        makeAutoObservable(this);
    }

    /**
     * @param {string} departmentId
     */
    GetDepartment = async (departmentId) => {
        return this.DepartmentsService.GetDepartment(departmentId);
    }

    GetDepartments = async () => {
        return this.DepartmentsService.GetDepartments(this.Paginator.PageIndex, this.Paginator.PageCount, this.Search);
    }

    /**
     * @param { import('../Services/DepartmentsService').Department } department
     * @returns { Promise<import("../Services/APIBase").RequestResult<boolean>>  } True if the create was successful, false if not
    */
    CreateDepartment = async (department) => {
        return this.DepartmentsService.CreateDepartment(department);
    }

    /**
     * @param { import('../Services/DepartmentsService').Department } department
     * @returns { Promise<import("../Services/APIBase").RequestResult<boolean>>  } True if the update was successful, false if not
    */
     UpdateDepartment = async (department) => {
        return this.DepartmentsService.UpdateDepartment(department);
    }

    /**
     * @param { string } departmentId the Id of the department to delete
     * @returns { Promise<import("../Services/APIBase").RequestResult<boolean>>  } True if the delete was successful, false if not
    */
    DeleteDepartment = async (departmentId) => {
        return this.DepartmentsService.DeleteDepartment(departmentId);
    }
}