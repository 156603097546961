import { makeAutoObservable, runInAction } from "mobx";
import Paginator from "../Utilities/Paginator";

export var DataChangeTypes = {
    MemberBankAccount: 1,
    
    GetDataChangeTypeText: function(dataChangeTypeId) {
        switch (dataChangeTypeId) {
            case this.MemberBankAccount:
                return "Member Bank Account Change";
            default:
                return "Unknown";
        }
    }
}

/**
 * @typedef ApprovalsStore
 * @type { ApprovalsStore }
 */
export default class ApprovalsStore {     
    /** @type { Array<import("../Services/DataChangeApprovalsService").DataChangeApproval> } */
    DataChangeApprovals = [];
       
    GettingDataChangeApprovals = false;
    ApprovingDataChange = false;
    RejectingDataChange = false;

    GettingApproval = false;
    
    constructor(dataChangeApprovalsService, toastStore) {        
        /**@type { import("../Services/DataChangeApprovalsService").DataChangeApprovalsService } */
        this.DataChangeApprovalsService = dataChangeApprovalsService;

        /**@type { import("../Stores/ToastStore").ToastStore } */
        this.ToastStore = toastStore;

        this.Paginator = new Paginator(async () => this.GetDataChangeApprovals());

        makeAutoObservable(this);
    }
    
    /**
     * Retrieves the approval with the given Id from the server
     */
    async GetDataChangeApproval(approvalId) {
        this.GettingApproval = true;

        var result = await this.DataChangeApprovalsService.GetApproval(approvalId);
        runInAction(() => this.GettingApproval = false );

        if(result) {
            if(result.Success) {
                return result.Data;
            } else {
                this.ToastStore.ShowErrorToast(`Failed to retrieve the Approval: ${result.Message}`);
                return null;
            }
        }
        
        return null;
    }

    /**
     * Returns the pending data change approvals
     */
    async GetDataChangeApprovals() {
        this.GettingDataChangeApprovals = true;

        var result = await this.DataChangeApprovalsService.GetPendingApprovals(this.Paginator.PageIndex, this.Paginator.PageCount);
        runInAction(() => this.GettingDataChangeApprovals = false );
        
        if(result) {
            if(result.Success) {
                runInAction(() => {
                    this.DataChangeApprovals = result.Data ;
                    this.Paginator.TotalData = result.Total;
                });
            } else {
                this.ToastStore.ShowErrorToast(`Failed to retrieve Pending Approvals: ${result.Message}`);
            }
        }
    }

    /**
     * Submits the approval decision
     * @param {string} approvalId The Id of the data change approval request
     * @param {boolean} approve Whether or not the data change is approved
     * @param {string} rejectionReason The reason the approval was rejected (only required for approval rejections)
     */
     async SubmitDataChangeApproval(approvalId, approve, rejectionReason) {        
        var result = {};

        if(approve) {
            this.ApprovingDataChange = true;
            result = await this.DataChangeApprovalsService.Approve(approvalId);
            runInAction(() => this.ApprovingDataChange = false );
        }
        else {
            this.RejectingDataChange = true;
            result = await this.DataChangeApprovalsService.Reject(approvalId, rejectionReason);
            runInAction(() => this.RejectingDataChange = false );
        }

        if(result) {
            if(result.Success) {
                this.ToastStore.ShowSuccessToast(`Approval decision submitted successfully.`);
            } else {
                this.ToastStore.ShowErrorToast(`Failed to submit approval decision: ${result.Message}`);
            }

            return result.Success;
        }

        return false;
    }

    get SubmittingDataChangeApproval() {
        return this.ApprovingDataChange || this.RejectingDataChange;
    }
}