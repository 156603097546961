import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";

export default function PanelHeader(props) {

    return <div>
            <Row className="border-bottom mb-3">
                <Col lg="6">
                    <h5 className="p-2"><FontAwesomeIcon icon={ props.icon }></FontAwesomeIcon> { props.title }</h5>
                </Col>
                <Col className="d-flex justify-content-end pb-3">
                    {
                        props.children
                    }
                </Col>
            </Row>
    </div> 
}