import { DateTime } from "luxon"
import { observer } from "mobx-react-lite"
import { Alert, Col, Row } from "react-bootstrap";

var PendingWithdrawals = observer(function PendingWithdrawals(props) {

    if(props.pendingWithdrawals && props.pendingWithdrawals.length > 0) {
        return <Alert variant="danger">
                <Row>
                    <Col><b>There are Pending Withdrawals for this Member:</b></Col>
                    <Col>
                        <table className="w-100">
                            <tbody>
                                {
                                    props.pendingWithdrawals.map((value, index) => 
                                        <tr key={index}>
                                            <td>TNO {value.TransactionNumber}</td>
                                            <td>{ DateTime.fromISO(value.TransactionDate).toFormat("yyyy-MM-dd")}</td>
                                            <td className="text-right">R {value.Amount.toFixed(2)}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Alert>
    } else {
        return <span></span>
    }
    
});

export default PendingWithdrawals;