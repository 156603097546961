import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, InputGroup, Row } from "react-bootstrap";
import Modal from "../../../Core/Modal";
import InfoLabel from "../../../Core/InfoLabel"
import { Form as BootstrapForm } from "react-bootstrap"
import { Formik, Form as FormikForm, Field } from "formik";
import { useSessionStore } from "../../../../Stores/SessionStore";
import NumberField from "../../../Core/NumberField";
import * as yup from 'yup';
import { useState } from "react";

export default function CreateWriteOffModal(props) {
    const store = useSessionStore();
    const [creatingWriteOff, setCreatingWriteOff] = useState(false);

    async function saveWithdrawal(data) {
        setCreatingWriteOff(true);
        var result = await store.TransactionsService.CreateWriteOff(props.member.Id, data.Amount);
		setCreatingWriteOff(false);

        if(result) {
			if(result.Success) {
				store.ToastStore.ShowSuccessToast("Write Off Created Successfully");
				props.onClose(true);
			} else {
				store.ToastStore.ShowErrorToast("An error ocurred creating the Write Off: " + result.Message);
			}
        }
    }

	const validationSchema =
		yup.object({
			Amount: yup.number().required("Amount is required.")
		}).required();

    return 	<Modal show={ props.show } title="Create Write Off" icon={ faEraser }>
				<Formik validationSchema={ validationSchema } initialValues={{ Amount: 0.00 }} onSubmit={ saveWithdrawal }>
					{
						({ errors }) =>
						<FormikForm>
							<Row>
								<Col>
									<InfoLabel invert label="Member Name" text={ props.member.Name }></InfoLabel>
								</Col>
								<Col>
									<InfoLabel invert label="Service Number (SNO)" text={ props.member.ServiceNumber }></InfoLabel>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<BootstrapForm.Group>
										<BootstrapForm.Label>Write Off Amount</BootstrapForm.Label>
										<InputGroup>
											<InputGroup.Text className="border-right-0">R</InputGroup.Text>
											<Field className={ "form-control " + (errors.Amount ? "is-invalid" : "") } component={ NumberField } name="Amount" />
										</InputGroup>
										{
											<small className="text-danger pb-3">{ errors.Amount }</small>
										}
									</BootstrapForm.Group>
								</Col>
							</Row>
							<Row className="modal-footer pb-0">
								<Button disabled={ creatingWriteOff } type="submit">{ creatingWriteOff ? "Saving..." : "Save" }</Button>
								<Button disabled={ creatingWriteOff } variant="danger" onClick={ props.onClose }>Cancel</Button>
							</Row>
						</FormikForm>
					}
				</Formik>
    		</Modal>
}