export default class Paginator {
    TotalData = 0;
    PageIndex = 0;
    PageCount = 10;

    constructor(fetchDataMethod) {
        this.GetData = fetchDataMethod;
    }
    
    get TotalPages() {
        if(this.PageCount === 0) {
            return 0;
        }

        return Math.ceil(this.TotalData / this.PageCount);
    }

    async SetPageIndex(index) {
        this.PageIndex = index;
        this.GetData();
    }
}