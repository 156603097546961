import { observer } from "mobx-react-lite";
import { useState } from "react"
import { Button, Form, Row, Col, Card } from "react-bootstrap"
import { useSessionStore } from "../../Stores/SessionStore";

const Login = observer(function Login(props) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const session = useSessionStore();

    async function handleLoginClicked () {

        setError("");
        var result = await session.Login(email, password);

        if(result) {
            setError(result.Message);
        }
        else {
            setError("An error occurred attempting to log in. Check your internet connection and try again.")
        }
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            handleLoginClicked();
        }
    }

    return <Row className="pt-5 justify-content-center">
        <Col lg="3" xs="12" className="pt-5">
            <Card className="shadow" style={{ border: "none", borderRadius: "5px" }}>
                <Card.Header className="bg-primary p-3 text-center" style={{ border: "none", borderRadius: "5px 5px 0 0" }}>
                    <h5 className="text-white mt-2">Durban Municipal Thrift Fund</h5>
                </Card.Header>
                <Card.Body>
                    <p className="text-muted text-center mb-4"><b>LOGIN TO CONTINUE</b></p>
                    <Form>
                        <Form.Group>
                            <Form.Control placeholder="Email Address" value={ email } onChange={ (e) => setEmail(e.target.value) } type="email"></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control onKeyDown={ handleKeyDown } placeholder="Password" value={ password } onChange={ (e) => setPassword(e.target.value) } type="password"></Form.Control>
                        </Form.Group>
                    </Form>
                    <small style={{ color: "crimson" }}>{ error }</small>
                    <Button className="col-lg-12 btn-success mt-4" disabled={ session.LoggingIn } onClick={ handleLoginClicked }>
                        Login
                    </Button>
                </Card.Body>
            </Card>
        </Col>
    </Row>
});

export default Login;