import { Formik, Form as FormikForm } from "formik";
import { DateTime } from "luxon";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Form } from "react-bootstrap-formik";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import * as yup from 'yup';

export default function YearEndRollover(props) {
	const [processing, setProcessing] = useState(false);

	const store = useSessionStore();

	async function handleSubmit(data) {
		setProcessing(true);
		var result = await store.PeriodEndService.ProcessEndOfYear(data.NewYearEndDate)
		setProcessing(false);

		if(result) {
			if(result.Success) {
				store.ToastStore.ShowSuccessToast("Processing Year End Complete.");
				props.onClose(result.Data);
			} else {
				store.ToastStore.ShowErrorToast(result.Message);
			}
		}
	}

	function getNewYearEndDate() {
		var currentYearEnd = DateTime.fromISO(props.control.YearEndDate);
		var nextYear = currentYearEnd.plus({ years: 1 });
		var endOfMonth = nextYear.endOf("month")

		while(endOfMonth.weekday > 5) {
			endOfMonth = endOfMonth.minus({ days: 1 });
		}

		return endOfMonth.toFormat("yyyy-MM-dd");
	}

	const validationSchema =
		yup.object({
			NewYearEndDate: yup.date().required("New Year End Date is required.")
		}).required();

	return <div className="m-n1">
				<Formik initialValues={ { NewYearEndDate: getNewYearEndDate() } } validationSchema={ validationSchema } onSubmit={ handleSubmit }>
					<FormikForm>
						<Form.Input min={ DateTime.fromISO(props.control.YearEndDate).toISODate() } type="date" label="New Year End Date" name="NewYearEndDate" disabled={ processing } />
						<Row className="clearfix">
							<Col>
								<Button className="float-right" disabled={ processing || props.disabled } type="submit">{ processing ? "Processing..." : "Process Year End" }</Button>
							</Col>
						</Row>
					</FormikForm>
				</Formik>
			</div>
}