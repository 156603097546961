import { observer } from "mobx-react-lite";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { useEffect, useState } from "react";
import BusyIndicator from "../../../Core/BusyIndicator";
import { getTransactionType, TransactionSortColumns } from "../../../../Stores/TransactionsStore";
import { DateTime } from "luxon";
import { Table } from "react-bootstrap";
import MemberTransactionActions from "./MemberTransactionActions";
import SortableTH from "../../../Core/SortableTH";

var MemberTransactions = observer(function MemberTransactions(props) {
    const store = useSessionStore();
    const [transactions, setTransactions] = useState(null);

    async function getMemberTransactions() {
        await store.ControlsStore.GetActiveControl();
        var transactions = await store.TransactionsStore.GetPostedTransactionsForYearForMember(store.ControlsStore.ActiveControl.YearEndDate, props.memberId);

        setTransactions(transactions);
    }

    useEffect(() => {
        getMemberTransactions();
    }, [props.memberId]);

	async function sortTransactions() {
		setTransactions(null);
		var transactions = await store.TransactionsStore.GetPostedTransactionsForYearForMember(store.ControlsStore.ActiveControl.YearEndDate, props.memberId);

        setTransactions(transactions);
	}

    return  <div className="m-n2">
                <BusyIndicator text="Retrieving Member Transactions..." show={ store.TransactionsStore.GettingPostedTransactionsForMember || transactions === null }></BusyIndicator>
                {
                    store.TransactionsStore.GettingPostedTransactionsForMember === false && transactions !== null &&
                    <div>
                        <Table striped hover bordered size="sm">
                            <thead>
                                <tr>
								<SortableTH onUpdate={ sortTransactions } column={ TransactionSortColumns.Batch } store={ store.TransactionsStore }>Batch</SortableTH>
								<SortableTH onUpdate={ sortTransactions } column={ TransactionSortColumns.Type } store={ store.TransactionsStore }>Type</SortableTH>
								<SortableTH onUpdate={ sortTransactions } column={ TransactionSortColumns.Date } store={ store.TransactionsStore }>Date</SortableTH>
								<SortableTH onUpdate={ sortTransactions } column={ TransactionSortColumns.Reference } store={ store.TransactionsStore }>Reference</SortableTH>
								<SortableTH onUpdate={ sortTransactions } column={ TransactionSortColumns.Debit } store={ store.TransactionsStore } className="text-right">Debit</SortableTH>
								<SortableTH onUpdate={ sortTransactions } column={ TransactionSortColumns.Credit } store={ store.TransactionsStore } className="text-right">Credit</SortableTH>
								<SortableTH onUpdate={ sortTransactions } column={ TransactionSortColumns.Period } store={ store.TransactionsStore } className="text-right">Period</SortableTH>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                store.TransactionsStore.GettingPostedTransactionsForMember === false && transactions !== null && transactions.length > 0 &&
                                transactions.map((transaction) =>
                                <tr key={ transaction.Id }>
                                    <td>{ transaction.BatchNumber }</td>
                                    <td>{ getTransactionType(transaction.TransactionTypeId) }</td>
                                    <td>{ DateTime.fromISO(transaction.TransactionDate).toFormat("yyyy-MM-dd") }</td>
                                    <td>{ transaction.Reference }</td>
                                    <td className="text-right">{ (transaction.Debit < 0 ? `R ${Math.abs(transaction.Debit).toFixed(2)}` : "") }</td>
                                    <td className="text-right">{ (transaction.Credit > 0 ? `R ${Math.abs(transaction.Credit).toFixed(2)}` : "") }</td>
                                    <td>{ (transaction.Period ? transaction.Period : store.ControlsStore.GetCurrentPeriod() ) }</td>
                                    <td className="text-center"><MemberTransactionActions onTransactionReversed={ getMemberTransactions } transactionId={ transaction.Id }></MemberTransactionActions></td>
                                </tr>
                                )
                            }
                            {
                                store.TransactionsStore.GettingPostedTransactionsForMember === false && transactions !== null && transactions.length === 0 &&
                                <tr className="text-center">
                                    <td colSpan="8"><b className="text-muted">No transactions returned</b></td>
                                </tr>
                            }
                            </tbody>
                        </Table>
                    </div>
                }

            </div>
});

export default MemberTransactions;