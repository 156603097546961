import APIBase from "./APIBase";

/**
 * @typedef DepositsImportVerificationResult
 * @property {number} DepositsAmount
 * @property {number} TotalDeposits
 * @property {boolean} ImportValid
 * @property {number} DepositsImportTypeId
 * @property {Array<import("./MembersService/Member"))>} TerminatedMembers
 * @property {Array<import("./MembersService/Member")>} MissingMembers
 * @property {Array<string>} Errors
 */

/**
 * @typedef DepositsImportsService
 * @type {DepositsImportsService}
 */
export default class DepositsImportsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "DepositsImports", sessionStore);
    }

    /**
	 * @param {string} content The content of the import file to verify
     * @returns { import("./APIBase").RequestResult<DepositsImportVerificationResult> }
     */
     async VerifyDepositsImportFile(content) {
        return this.Post(`VerifyDepositsImportFile`, content);
    }

	/**
	 * @param {string} content The content of the import file to verify
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
	async ImportDepositsFile(content) {
		return this.Post("ImportDepositsFile", content);
	}
}