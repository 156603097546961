import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { Groups, Names } from "../../../Stores/SettingsStore";
import { Roles } from "../../../Stores/UserStore";
import BusyIndicator from "../../Core/BusyIndicator";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

var Settings = observer(function Settings() {
    var store = useSessionStore();

    const [users, setUsers] = useState([]);
    const [gettingSupportData, setGettingSupportData] = useState(false);

    useEffect(() => {
        async function getData() {
            store.SettingsStore.GetAvailableSettings();

            //retrieve support data for settings
            setGettingSupportData(true);

            var users = await store.UsersService.GetUsers(0, 25, "", Roles.Admin)
            setUsers(users.Data);

            setGettingSupportData(false);
        }

        getData();

    }, [store.SettingsStore, store.UsersService])

    function updateSetting(settingName, settingGroup, value) {
        store.SettingsStore.SetSetting(settingName, settingGroup, null, null, value);
    }

    function saveSettings() {
        store.SettingsStore.UpdateSettings();
    }

	function getApprovalUsersOptions() {
		var options = users.map((user) => { return  { key: user.Id.toUpperCase(), label: `${user.FirstName} ${user.LastName}` } });

		return options;
	}

	function getSelectedApprovalUsers() {
		var selected = store.SettingsStore.GetSetting(Names.DataChangeApprover, Groups.Approvals).Value.split(",");
		var selectedAndAvailable = [];

		//make sure the selected users are actually in the available set of users
		for(let i = 0; i < selected.length; i++) {
			var user = users.find((u) => u.Id.toUpperCase() === selected[i]);

			if(user) {
				selectedAndAvailable.push(selected[i]);
			}
		}

		return selectedAndAvailable;
	}

	function updateApprovalUsers(values) {
		updateSetting(Names.DataChangeApprover, Groups.Approvals, values.join(","));
	}

    return <Panel>
                <PanelHeader icon={ faCogs } title="Settings"></PanelHeader>
                    {
                        store.SettingsStore.GettingSettings === false && gettingSupportData === false && store.SettingsStore.AvailableSettings.length > 0 &&
                        <div>
                            <Row className="justify-content-center mb-3">
                                <Col lg={ 6 } >
                                    <Row>
                                        <Col lg={6} className="d-flex align-items-center">
                                                Data Change Approval Users
                                        </Col>
                                        <Col lg={6}>
											<DropdownMultiselect options={ getApprovalUsersOptions() } selected={ getSelectedApprovalUsers() }
																handleOnChange={(selected) => updateApprovalUsers(selected)}></DropdownMultiselect>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="modal-footer pb-0 justify-content-center">
                                <Col lg={ 6 } className="d-flex justify-content-end m-0" >
                                    <Button onClick={ saveSettings } disabled={ store.SettingsStore.UpdatingSettings } type="button">{ store.SettingsStore.UpdatingSettings ? "Saving..." : "Save" }</Button>
                                </Col>
                            </Row>
                        </div>
                    }
                <BusyIndicator text="Retrieving Settings..." show={ store.SettingsStore.GettingSettings || gettingSupportData }></BusyIndicator>
            </Panel>
});

export default Settings;