import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import ClickIcon from "../../Core/ClickIcon";
import ConfirmationModal from "../../Core/ConfirmationModal";

var DailyWithdrawalActions = observer(function DailyWithdrawalActions(props) {
    const [deletingWithdrawal, setDeletingWithdrawal] = useState(false);
    const [confirmDeleteModalShown, showDeleteConfirmModal] = useState(false);

    const store = useSessionStore();
    
    function handleDeleteWithdrawalClicked() {
        showDeleteConfirmModal(true);
    }

    async function handleDeleteResponse(response) {
        showDeleteConfirmModal(false);
                
        if(response) {
            setDeletingWithdrawal(true);
            var result = await store.MemberWithdrawalsStore.DeleteUnbatchedWithdrawal(props.transactionId);
            setDeletingWithdrawal(false);
            
            if(result) {
                props.onWithdrawalDeleted();
            }
        }
    }

    return <div className="text-center">
                {
                    deletingWithdrawal === false && 
                    <Row className="text-center">                        
                        <Col>
                            <ClickIcon icon={ faTrash } title="Delete Withdrawal" onClick={ handleDeleteWithdrawalClicked }></ClickIcon>
                        </Col>
                    </Row>
                }
                <BusyIndicator sm show={deletingWithdrawal }></BusyIndicator>
                <ConfirmationModal onResponse={ handleDeleteResponse } show={ confirmDeleteModalShown } title="Delete Withdrawal" message="Are you sure you want to delete this Withdrawal?"></ConfirmationModal>
    </div>
});

export default DailyWithdrawalActions;