import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import ClickIcon from "../../Core/ClickIcon";
import ConfirmationModal from "../../Core/ConfirmationModal";
import Modal from "../../Core/Modal";
import DepartmentDetails from "./DepartmentDetails";

var DepartmentActions = observer(function DepartmentActions(props) {
    const [deletingDepartment, setDeletingDepartment] = useState(false);
    const [detailsModalShown, showDetailsModal] = useState(false);
    const [confirmDeleteModalShown, showDeleteConfirmModal] = useState(false);

    const session = useSessionStore();

    function handleDepartmentEditComplete(success) {
        //just refresh departments and then close the modal  
        if(success) {      
            session.DepartmentsStore.CRUD.GetEntries();
        }

        showDetailsModal(false);
    }

    function handleEditDepartmentClicked() {
        showDetailsModal(true);
    }

    function handleDeleteDepartmentClicked() {
        showDeleteConfirmModal(true);
    }

    async function handleDeleteResponse(response) {
        showDeleteConfirmModal(false);
                
        if(response) {
            setDeletingDepartment(true);
            var result = await session.DepartmentsStore.CRUD.DeleteEntry(props.departmentId)   
            setDeletingDepartment(false);
            
            if(result) {
                session.DepartmentsStore.CRUD.GetEntries();
            }
        }
    }

    return <div>
                {
                    deletingDepartment === false && 
                    <Row  className="d-flex justify-content-center">
                        <Col lg="1">
                            <ClickIcon icon={ faEdit } title="Edit Department" onClick={ handleEditDepartmentClicked }></ClickIcon>
                        </Col>
                        <Col lg="1">
                            <ClickIcon icon={ faTrash } title="Delete Department" onClick={ handleDeleteDepartmentClicked }></ClickIcon>
                        </Col>
                    </Row>
                }
                <BusyIndicator sm show={deletingDepartment }></BusyIndicator>
            
            <Modal show={ detailsModalShown } title="Edit Department" onHide={ e => handleDepartmentEditComplete(false)} >
                <DepartmentDetails departmentId={ props.departmentId } onEditComplete={ e => handleDepartmentEditComplete(true) }></DepartmentDetails>
            </Modal>
            <ConfirmationModal onResponse={ handleDeleteResponse } show={ confirmDeleteModalShown } title="Delete Department" message="Are you sure you want to delete this Department?"></ConfirmationModal>
    </div>
});

export default DepartmentActions;