import { useState } from "react";
import { Formik, Form as FormikForm, Field } from "formik";
import { Form as BootstrapForm, Button, InputGroup, Row, Col } from "react-bootstrap";
import * as yup from 'yup';
import NumberField from "../../../../Core/NumberField";
import { useSessionStore } from "../../../../../Stores/SessionStore";

export default function InterestDistribution(props) {
	const [processingInterest, setProcessingInterest] = useState(false);
	const [interestDistribution, setInterestDistribution] = useState({ InterestRate: props.details.InterestRate, TotalInterest: props.details.TotalInterest });

	const store = useSessionStore();

	const interestPayoutValidationScheme =
		yup.object({
			InterestRate: yup.number().required("Interest Rate is required."),
			TotalInterest: yup.number().required("Total Interest is required.")
		}).required();

	function handleUpdateTotalInterestClicked(interestRate) {
		var newTotalInterest = interestDistribution.TotalInterest / (interestDistribution.InterestRate / 100.0) * (interestRate / 100.0);
		setInterestDistribution({ InterestRate: interestRate, TotalInterest: newTotalInterest });
	}

	function handleUpdateInterestRateClicked(totalInterest) {
		var newInterestRate = (interestDistribution.InterestRate / 100.0) / interestDistribution.TotalInterest * totalInterest;
		setInterestDistribution({ InterestRate: newInterestRate * 100.0, TotalInterest: totalInterest });
	}

	async function handleProcessInterestClicked(data) {
		setProcessingInterest(true);

		var result = await store.PeriodEndService.ProcessEndOfYearInterest(data.InterestRate);

		if(result) {
			setProcessingInterest(false);

			if(result.Success) {
				props.onInterestProcessed(data.InterestRate, data.TotalInterest);
				store.ToastStore.ShowSuccessToast("End of Year interest distributed successfully");
			} else {
				store.ToastStore.ShowErrorToast("An error ocurred trying to process End of Year Interest: " + result.Message);
			}
		}
	}

	return 	<div className="m-n1">
				<Formik enableReinitialize initialValues={ interestDistribution } validationSchema={ interestPayoutValidationScheme } onSubmit={ handleProcessInterestClicked }>
					{
                        ({ errors, values }) =>
						<FormikForm>
							<BootstrapForm.Group>
								<BootstrapForm.Label>Interest Rate To Pay</BootstrapForm.Label>
								<InputGroup>
									<Field disabled={ processingInterest } className={ "form-control " + (errors.InterestRate ? "is-invalid" : "") } component={ NumberField } name="InterestRate" />
									<InputGroup.Text className="border-left-0">%</InputGroup.Text>
									<InputGroup.Text className="border-0 bg-success text-white px-3" style={{ cursor: "pointer" }} onClick={ e => handleUpdateTotalInterestClicked(values.InterestRate) }>...</InputGroup.Text>
								</InputGroup>
								{
									<small className="text-danger pb-3">{ errors.InterestRate }</small>
								}
							</BootstrapForm.Group>

							<BootstrapForm.Group>
								<BootstrapForm.Label>Interest To Distribute</BootstrapForm.Label>
								<InputGroup>
									<InputGroup.Text className="border-right-0">R</InputGroup.Text>
									<Field disabled={ processingInterest } className={ "form-control " + (errors.TotalInterest ? "is-invalid" : "") } component={ NumberField } name="TotalInterest" />
									<InputGroup.Text className="border-0 bg-success text-white px-3" style={{ cursor: "pointer" }} onClick={ e => handleUpdateInterestRateClicked(values.TotalInterest) }>...</InputGroup.Text>
								</InputGroup>
								{
									<small className="text-danger pb-3">{ errors.TotalInterest }</small>
								}
							</BootstrapForm.Group>

							<Row className="clearfix">
								<Col>
									<Button className="float-right" disabled={ processingInterest || props.details.InterestForYearAlreadyPaidOut || props.disabled } type="submit">{ processingInterest ? "Processing..." : "Process Interest" }</Button>
								</Col>
							</Row>

						</FormikForm>
					}
				</Formik>
			</div>
}