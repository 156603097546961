import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Accordion, Card, Col, Row, useAccordionToggle, AccordionContext } from "react-bootstrap";

export default function AccordianItem(props) {

    function AccordianHeader({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);
        const onClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

        return <div onClick={ onClick }>
                    <Row style={{ padding: "12px 15px 12px 20px" }}>
                        <Col>
                            { children }
                        </Col>
                        <Col lg={ 1 } className="text-right text-muted">
                        {
                            currentEventKey === eventKey &&
                            <FontAwesomeIcon icon={ faCaretUp }></FontAwesomeIcon>
                        }
                        {
                            currentEventKey !== eventKey &&
                            <FontAwesomeIcon icon={ faCaretDown }></FontAwesomeIcon>
                        }
                        </Col>
                    </Row>
                </div>
    }

    return <Card>
                <Card.Header className="p-0">
                    <AccordianHeader eventKey={props.eventKey}>{ props.title }</AccordianHeader>
                </Card.Header>
                <Accordion.Collapse eventKey={props.eventKey}>
                    <Card.Body>
                        { props.children }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
}