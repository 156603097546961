import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import BatchesList from "../Batches/BatchesList";
import CATSTransferActions from "./CATSTransferActions";

const CATSTransfersIndex = observer(function CATSTransfersIndex() {
    const [batches, setBatches] = useState([]);

    const store = useSessionStore();

    async function getPendingWithdrawalBatches() {
        var batches = await store.BatchesStore.GetPendingWithdrawalBatches();

        if(batches) {
			batches = batches.filter(b => b.IsReversalBatch === false);
            setBatches(batches);
        }
    }

	function handleCATSFileDownloaded(batchId) {
		var index = batches.findIndex(batch => batch.Id === batchId);

		if(index > -1) {
			batches[index].HasTransfers = true;
		}

		setBatches([...batches]);
	}

    useEffect(() => {
        getPendingWithdrawalBatches();
    }, []);

    return 	<Panel>
				<PanelHeader icon={ faDownload } title="CATS Transfers"></PanelHeader>
				<Row>
					<Col lg={12}>
						<BatchesList onSort={ getPendingWithdrawalBatches } showTransfers busy={ store.BatchesStore.RetrievingPendingWithdrawalBatches } batches={ batches }
									actions={ <CATSTransferActions onTransactionsModalClosed={ getPendingWithdrawalBatches } onCATSFileDownloaded={ handleCATSFileDownloaded }></CATSTransferActions> }>

						</BatchesList>

						<BusyIndicator text="Retrieving Batches..." show={ store.BatchesStore.RetrievingPendingWithdrawalBatches }></BusyIndicator>
					</Col>
				</Row>
        	</Panel>

});

export default CATSTransfersIndex;