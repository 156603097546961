import { makeAutoObservable, runInAction } from "mobx";

/**
 * @enum {number}
 */
export var ToastTypes = {
    Error: 1,
    Success: 2,
    Warning: 3
}

/**
 * @typedef Toast
 * @property {string} Title
 * @property {string} Message
 * @property {ToastTypes} Type
 * @property {boolean} Show
 */

/**
 * @typedef ToastStore
 * @type {ToastStore}
 */
export default class ToastStore {
    /**@type {Array<Toast>} */
    Toasts = [];

    constructor() {
        makeAutoObservable(this);
    }

    /**
     * @param { Toast } toast
     */
    HideToast(toast) {
        runInAction(() => {
            toast.Show = false;

            var index = this.Toasts.indexOf((value) => value === toast);

            if(index !== -1) {
                this.Toasts.splice(index, 1);
            }

        });
    }

    /**
     * @param {string} title
     * @param {string} message
     * @param {ToastTypes} type
     */
    ShowToast(title, message, type) {        
        this.Toasts.push({ Message: message, Title: title, Type: type, Show: true});
    }

    /**
     * @param {string} message
     * @param {string} title
     */
    ShowErrorToast(message, title) {
        this.ShowToast(title ?? "Error", message, ToastTypes.Error);
    }

    /**
     * @param {string} message
     * @param {string} title
     */
     ShowSuccessToast(message, title) {
        this.ShowToast(title ?? "Success", message, ToastTypes.Success);
    }

    /**
     * @param {string} message
     * @param {string} title
     */
     ShowWarningToast(message, title) {
        this.ShowToast(title ?? "Warning", message, ToastTypes.Warning);
    }
}