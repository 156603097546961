import { makeAutoObservable, runInAction } from "mobx";

/**
 * @typedef MemberBalancesStore
 * @type { MemberBalancesStore }
 */

export default class MemberBalancesStore {
    GettingMemberBalances = false;
    GettingMemberBalanceSummary = false;
    
    constructor(memberBalancesService, toastStore) {        
        /**@type { import("../Services/MemberBalancesService").MemberBalancesService } */
        this.MemberBalancesService = memberBalancesService;

        /**@type { import("../Stores/ToastStore").ToastStore } */
        this.ToastStore = toastStore;

        makeAutoObservable(this);
    }

    /**
     * Retrieves the balances for the given member
     */
     async GetMemberBalances(memberId) {
        this.GettingMemberBalances = true;

        var result = await this.MemberBalancesService.GetMemberBalances(memberId);
        runInAction(() => this.GettingMemberBalances = false );

        if(result)
        {
            if(result.Success) {
                return result.Data
            } else {
                this.ToastStore.ShowErrorToast(`Failed to retrieve the member's balances: ${result.Message}`);
            }
        }

        return null;
    }

    /**
     * Retrieves the balance summary data for the given member
     * @returns { Promise<import("../../Services/MemberBalancesService").MemberBalanceSummary> }
     */
     async GetBalanceSummaryForMember(memberId) {
        this.GettingMemberBalanceSummary = true;

        var result = await this.MemberBalancesService.GetBalanceSummaryForMember(memberId);
        runInAction(() => this.GettingMemberBalanceSummary = false );

        if(result)
        {
            if(result.Success) {
                return result.Data
            } else {
                this.ToastStore.ShowErrorToast(`Failed to retrieve the member's balance summary: ${result.Message}`);
            }
        }

        return null;
    }
}