import APIBase from "./APIBase";

/**
 * @typedef PeriodEndResult
 * @property {boolean} MustProcessNextPeriod
 * @property {Array<import("./APIBase").DownloadFile>} Reports
 */

/**
 * @typedef EndOfYearDetails
 * @property {number} InterestRate
 * @property {number} TotalInterest
 * @property {number} InterestPaidOut
 * @property {boolean} InterestForYearAlreadyPaidOut
 */

/**
 * @typedef PeriodEndService
 * @type {PeriodEndService}
 */
export default class PeriodEndService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "PeriodEnd", sessionStore);
    }

    /**
     * @returns { Promise<import("./APIBase").RequestResult<boolean>> }
     */
     async GetPeriodEndDetails() {
        return this.Get(`GetPeriodEndDetails`);
    }

	/**
     * @returns { Promise<import("./APIBase").RequestResult<PeriodEndResult>> }
     */
     async ProcessEndOfDay(nextDay) {
        return this.Post(`ProcessEndOfDay?nextDay=${encodeURIComponent(nextDay)}`);
    }

	/**
     * @returns { Promise<import("./APIBase").RequestResult<PeriodEndResult>> }
     */
     async ProcessEndOfMonth(nextMonthEnd) {
        return this.Post(`ProcessEndOfMonth?nextMonthEnd=${encodeURIComponent(nextMonthEnd)}`);
    }

	/**
     * @returns { Promise<import("./APIBase").RequestResult<PeriodEndResult>> }
     */
     async ProcessEndOfYear(nextYearEnd) {
        return this.Post(`ProcessEndOfYear?nextYearEnd=${encodeURIComponent(nextYearEnd)}`);
    }

	/**
     * @returns { Promise<import("./APIBase").RequestResult<boolean>> }
     */
     async ProcessEndOfYearInterest(interestRate) {
        return this.Post(`ProcessEndOfYearInterest?interestRate=${encodeURIComponent(interestRate)}`);
    }

	/**
     * @returns { Promise<import("./APIBase").RequestResult<EndOfYearDetails>> }
     */
     async GetEndOfYearDetails() {
        return this.Get(`GetEndOfYearDetails`);
    }

	/**
	 * @returns { Promise<import("./APIBase").RequestResult<Array<import("./APIBase").DownloadFile>> }
	 */
	async GetMemberStatements(message) {
		return this.Get(`GetMemberStatements?message=${encodeURIComponent(message)}`);
	}

	/**
	 * @returns { Promise<import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
	 */
	 async GetIT3BReport(amount) {
		return this.Get(`GetIT3BReport?amount=${encodeURIComponent(amount)}`);
	}
}