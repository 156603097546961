import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, InputGroup, Row } from "react-bootstrap";
import Modal from "../../../Core/Modal";
import BalanceSummary from "../MemberDetails/BalanceSummary";
import InfoLabel from "../../../Core/InfoLabel"
import { Form as BootstrapForm } from "react-bootstrap"
import { Form } from "react-bootstrap-formik";
import { Formik, Form as FormikForm, Field } from "formik";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useSessionStore } from "../../../../Stores/SessionStore";
import NumberField from "../../../Core/NumberField";
import * as yup from 'yup';
import PendingWithdrawals from "./PendingWithdrawals";
import BusyIndicator from "../../../Core/BusyIndicator";
import { observer } from "mobx-react-lite";

var CreateWithdrawalModal = observer(function CreateWithdrawalModal(props) {

    const store = useSessionStore();
	const [withdrawalCounts, setWithdrawalCounts] = useState(null);
    const [withdrawalState, setWithdrawalState] = useState({ MaxAmount: 0.00, CurrentDate: DateTime.now().toISODate(), PendingWithdrawals: [] });
    const [showingConfirmTransactionUpdate, showConfirmTransactionUpdate] = useState(false);
    const [withdrawalFormData, setWithdrawalFormData] = useState(null);
    const [creatingWithdrawal, setCreatingWithdrawal] = useState(false);

    async function handleSaveClicked(data) {
        //try to find if a transaction already exists for the set period
        var currentDate = DateTime.fromISO(data.CurrentDate);

        var existingWithdrawal = withdrawalState.PendingWithdrawals.find((value) => {
            var withdrawalDate = DateTime.fromISO(value.TransactionDate);
            return (withdrawalDate.year === currentDate.year && withdrawalDate.month === currentDate.month);
        });

        if(existingWithdrawal) {
            setWithdrawalFormData(data);
            showConfirmTransactionUpdate(true);

        } else {
            saveWithdrawal(data);
        }
    }

    async function saveWithdrawal(data) {
        showConfirmTransactionUpdate(false);

        setCreatingWithdrawal(true);
        var result = await store.MemberWithdrawalsStore.CreateWithdrawalForMember(data.MaxAmount, data.CurrentDate, props.memberId);
        setCreatingWithdrawal(false);

        if(result) {
            props.onClose(true);
        }
    }

    async function retrieveNewWithdrawalStateForMember() {
        var state = await store.MemberWithdrawalsStore.GetStateForNewMemberWithdrawal(memberId);

        if(state) {
            setWithdrawalState(state);
        }
        else {
            props.onClose();
        }
    }

	async function retrieveWithdrawalCountsForMember () {
		let counts = await store.MemberWithdrawalsService.GetWithdrawalCountsForMember(memberId);

		if(counts && counts.Success) {
			setWithdrawalCounts(counts.Data);
		}
	}

    const isShowing = props.show;
    const memberId = props.memberId;

    useEffect(() => {
        if(isShowing === true) {
            retrieveNewWithdrawalStateForMember();
			retrieveWithdrawalCountsForMember();
        }
    }, [isShowing, memberId]);

    const validationSchema =
        yup.object({
            CurrentDate: yup.date().required("Withdrawal Date is required"),
            MaxAmount: yup.number().required("Withdrawal Amount is required.")
            .min(0.01, "Withdrawal Amount must be greater than 0")
            .max(withdrawalState.MaxAmount, "Withdrawal Amount cannot be greater than R " + withdrawalState.MaxAmount.toFixed(2))
        }).required();

    return <Modal show={ props.show } size="lg" title="Create New Withdrawal" icon={ faWallet }>
        <BalanceSummary memberId={ props.memberId }></BalanceSummary>
        <BusyIndicator show={ store.MemberWithdrawalsStore.RetrievingNewWithdrawalState } text="Retrieving Data for Withdrawal..."></BusyIndicator>

        {
            store.MemberWithdrawalsStore.RetrievingNewWithdrawalState === false &&
            <div>
                {
                    showingConfirmTransactionUpdate === false &&
                    <PendingWithdrawals pendingWithdrawals={ withdrawalState.PendingWithdrawals }></PendingWithdrawals>
                }
                <Formik validationSchema={ validationSchema } initialValues={withdrawalState} enableReinitialize={true} onSubmit={ handleSaveClicked }>
                    {
                        ({ errors }) =>
                        <FormikForm>
                            {
                                showingConfirmTransactionUpdate === false &&
                                <div>
                                    <Row>
                                        <Col>
                                            <InfoLabel invert label="Member Name" text={ props.memberName }></InfoLabel>
                                        </Col>
                                        <Col>
                                            <InfoLabel invert label="Service Number (SNO)" text={ props.memberServiceNumber }></InfoLabel>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <BootstrapForm.Group>
                                                <BootstrapForm.Label>Withdrawal Amount</BootstrapForm.Label>
                                                <InputGroup>
                                                    <InputGroup.Text className="border-right-0">R</InputGroup.Text>
                                                    <Field className={ "form-control " + (errors.MaxAmount ? "is-invalid" : "") } component={ NumberField } name="MaxAmount" />
                                                </InputGroup>
                                                {
                                                    <small className="text-danger pb-3">{ errors.MaxAmount }</small>
                                                }
                                            </BootstrapForm.Group>
                                        </Col>
                                        <Col>
                                            <Form.Input min={ withdrawalState.CurrentDate } type="date" label="Withdrawal Date" name="CurrentDate" />
                                        </Col>
                                    </Row>
									{
										withdrawalCounts &&
										<Row className="my-2">
											<Col>
												<span className={ (withdrawalCounts.TotalPendingWithdrawals > 0 ? "text-danger" : null) }>{ withdrawalCounts.TotalPendingWithdrawals } Withdrawals Pending</span>
											</Col>
											<Col>
												<span className={ (withdrawalCounts.TotalWithdrawalsThisMonth > 0 ? "text-danger" : null) }>{ withdrawalCounts.TotalWithdrawalsThisMonth } Withdrawals this Month</span>
											</Col>
											<Col>
												<span>{ withdrawalCounts.TotalWithdrawalsThisYear } this Year</span>
											</Col>
										</Row>
									}
                                    <Row className="modal-footer pb-0">
                                        <Button disabled={ creatingWithdrawal } type="submit">{ creatingWithdrawal ? "Saving..." : "Save" }</Button>
                                        <Button disabled={ creatingWithdrawal } variant="danger" onClick={ props.onClose }>Cancel</Button>
                                    </Row>
                                </div>
                            }
                            {
                                showingConfirmTransactionUpdate &&
                                <div>
                                    <h6 className="pb-3 pt-1">A pending withdrawal for this period already exists and will be updated, would you like to continue?</h6>
                                    <Row>
                                        <Col className="d-flex justify-content-end">
                                            <Button onClick={ e => saveWithdrawal(withdrawalFormData) }>Yes</Button>
                                            <Button onClick={ e => showConfirmTransactionUpdate(false) } variant="secondary" className="ml-2">No</Button>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </FormikForm>
                    }
                </Formik>
            </div>
        }
    </Modal>
});

export default CreateWithdrawalModal;