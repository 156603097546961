import APIBase from "./APIBase";

/**
 * @typedef BankAccount
 * @property { string } Id
 * @property { string } Payee
 * @property { string } BankName
 * @property { string } BranchCode
 * @property { string } AccountNumber
 * @property { boolean } BankAccountTypeId
 */

/**
 * @typedef Address
 * @property { string } Id
 * @property { string } Line1
 * @property { string } Line2
 * @property { string } Line3
 * @property { string } Line4
 */

/**
 * @typedef MemberNote
 * @property { string } Id
 * @property { string } Username
 * @property { string } Text
 */

/**
 * @typedef Member
 * @property { string } Id
 * @property { string } ServiceNumber
 * @property { string } Name
 * @property { string } Surname
 * @property { string } EmailAddress
 * @property { string } IdNumber
 * @property { string } TaxNumber
 * @property { boolean } FICACompliant
 * @property { string } JoinDate
 * @property { string } TerminationDate        
 * @property { number } MemberStatusId
 * @property { string } DepartmentId
 * @property { string } AddressId
 * @property { string } BankAccountId
 * @property { Address } Address
 * @property { BankAccount } BankAccount
 * @property { Array<MemberNote> } MemberNotes
 */

/**
 * @typedef MembersService
 * @type {MembersService}
 */
export default class MembersService extends APIBase {

    /**
     * @param {string} apiUrl 
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Members", sessionStore);
    }
    
    /**
     * @param {Member} member The Member object to create
     * @returns { Promise<import("./APIBase").RequestResult<string>> }
     */
    async CreateMember(member) {
        return this.Post("CreateMember", member);
    }
    
    /**
     * @param {Member} member The member object to update
     */
    async UpdateMember(member, updateBankAccount) {
        return this.Post(`UpdateMember?updateBankAccount=${updateBankAccount}`, member);
    }

    /**
     * @param {string} memberId The Id of the member to terminate
     * @param {Date} terminationDate The date of the members termination
     * @param {boolean} payInterest Whether the member should pay interest or not
     * @param {number} interest The amount of interest the member should
     * @returns {Promise<import("./APIBase").RequestResult<boolean>>} The result of the termination process
     */
     async TerminateMember(memberId, terminationDate, payInterest, interest) {
        return this.Post(`TerminateMember?memberid=${memberId}&terminationDate=${terminationDate}&payInterest=${payInterest}&interest=${encodeURIComponent(interest)}`, null);
    }

    /**
     * @param {string} memberId The Id of the member to reinstate
     * @returns {Promise<import("./APIBase").RequestResult<boolean>>} The result of the reinstate
     */
     async ReinstateMember(memberId) {
        return this.Post(`ReinstateMember?memberid=${memberId}`, null);
    }
        
    /**
     * @param {number} startIndex The 0 based start page index
     * @param {number} pageCount The total amount of results to return for the page
     * @param {number} memberStatusId The status value to filter by
     * @param {string} search String containing a string to filter results by
     * @returns { import("./APIBase").EnumerableRequestResult<Member> }
     */
    async GetMembers(startIndex, pageCount, memberStatusId, search) {
        return this.Get(`GetMembers?startIndex=${startIndex}&pageCount=${pageCount}&memberStatusId=${memberStatusId}&search=${encodeURIComponent(search)}`);
    }
    
    /**
     * @param {string} memberId The Id of the member to retrieve
     * @returns { Promise<import("./APIBase").RequestResult<Member>> }
     */
    async GetMember(memberId) {
        return this.Get(`GetMember?memberId=${memberId}`);
    }

    /**
     * @param {string} memberId The Id of the member
     * @param {string} terminationDate The date the member will be terminated
     * @returns { Promise<import("./APIBase").RequestResult<number>> }
     */
     async GetMemberTerminationInterest(memberId, terminationDate) {
        return this.Get(`GetMemberTerminationInterest?memberId=${memberId}&terminationDate=${terminationDate}`);
    }
}