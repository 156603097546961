import { Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import { Form } from "react-bootstrap-formik";
import { MemberStatuses } from "../../../../Stores/Members/MemberStore";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useSessionStore } from "../../../../Stores/SessionStore";
import { useState } from "react";
import { Field } from "formik";
import { DateTime } from "luxon";

export default function PersonalDetails(props) {
    const store = useSessionStore();
    const [departments, setDepartments] = useState([]);
    const [isLoadingDepartments, setIsLoadingDepartments ] = useState(false);

    async function handleDepartmentsSearch(query) {
        setIsLoadingDepartments(true);
        var departments = await store.DepartmentsService.GetDepartments(0, 5, query);

        if(departments.Success) {
            setDepartments(departments.Data);
            setIsLoadingDepartments(false);
        }
    }

    function handleDepartmentChange(department) {
        props.setFieldValue('DepartmentId', department && department[0] ? department[0].Id : props.member.DepartmentId);
    }

    return <div>
        <Row>
            <Col>
                <Form.Input type="number" disabled={ props.member.Id } label="Service Number" name="ServiceNumber" />
            </Col>
            <Col>
                <Form.Input label="ID Number" name="IdNumber" />
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Input label="Name" name="Name" />
            </Col>
            <Col>
                <Form.Input label="Surname" name="Surname" />
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Input type="email" label="Email Address" name="EmailAddress" />
            </Col>
			<Col>
                <Form.Input label="Phone Number" name="PhoneNumber" />
            </Col>
        </Row>
		<Row>
			<Col>
                <FormGroup>
                    <FormLabel>Department</FormLabel>
                    {
                        props.member.Department &&
                        <AsyncTypeahead inputProps={{ className: props.errors["DepartmentId"] ? "is-invalid" : "" }} id="Department"
                        onChange={ handleDepartmentChange } isLoading={isLoadingDepartments} onSearch={ handleDepartmentsSearch } options={ departments }
                        labelKey={ dept => `${dept.Code} - ${dept.Name}`} defaultSelected={ [props.member.Department] }></AsyncTypeahead>
                    }
                    {
                        props.member.DepartmentId === "" &&
                        <AsyncTypeahead inputProps={{ className: props.errors["DepartmentId"] ? "is-invalid" : "" }} id="Department"
                        onChange={handleDepartmentChange } isLoading={isLoadingDepartments} onSearch={ handleDepartmentsSearch } options={ departments }
                        labelKey={ dept => `${dept.Code} - ${dept.Name}`}></AsyncTypeahead>
                    }
                    <small className="text-danger">{ props.errors["DepartmentId"] }</small>
                </FormGroup>
            </Col>
        </Row>
        <hr></hr>
        <div className="mb-2">
           Postal Address
        </div>
        <Row>
            <Col>
                <Form.Input label="Line 1" name="Address.Line1" />
            </Col>
            <Col>
                <Form.Input label="Line 2" name="Address.Line2" />
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Input label="Line 3" name="Address.Line3" />
            </Col>
            <Col>
                <Form.Input label="Line 4" name="Address.Line4" />
            </Col>
        </Row>
        <hr></hr>
        <Row>
            <Col>
                <Form.Input label="Tax Number" name="TaxNumber" />
            </Col>
            <Col className="d-flex flex-column justify-content-center pt-2">
                <FormGroup className="d-flex align-items-center mb-1">
                    <Field type="checkbox" name="FICACompliant"></Field>
                    <label className="ml-2 mb-0"><span> FICA Compliant</span></label>
                </FormGroup>
                <FormGroup className="d-flex align-items-center mb-0">
                    <Field type="checkbox" name="ChargeDeathBenefitFundFee"></Field>
                    <label className="ml-2 mb-0"><span> Charge Death Benefit Fund Fee</span></label>
                </FormGroup>
            </Col>
        </Row>
        <hr></hr>
        <Row>
            <Col>
                <Form.Input type="date" label="Join Date" name="JoinDate" />
            </Col>
            <Col>
                <div name="TerminationDate" className="form-group">
                    <label className="form-label" htmlFor="TerminationDate">Termination Date</label>
                    <div className="mt-2">
                        <b name="TerminationDate" id="TerminationDate">{ props.member.MemberStatusId === MemberStatuses.Terminated ? DateTime.fromISO(props.member.TerminationDate).toFormat("yyyy/MM/dd") : "N/A" }</b>
                    </div>
                </div>
            </Col>
        </Row>
        <hr></hr>
        {
            props.member.MemberStatusId !== MemberStatuses.Terminated &&
            <Form.Select label="Member Status" name="MemberStatusId">
                <option value={ MemberStatuses.ActiveMember }>{ `${MemberStatuses.ActiveMember} - Active Member` }</option>
                <option value={ MemberStatuses.ActivePensioner }>{ `${MemberStatuses.ActivePensioner} - Active Pensioner` }</option>
            </Form.Select>
        }
        {
            props.member.MemberStatusId === MemberStatuses.Terminated &&
            <div className="form-group">
                <label className="form-label" htmlFor="MemberStatus">Member Status</label>
                <div>
                    <b name="MemberStatus" id="MemberStatuse">4 - Terminated</b>
                </div>
            </div>
        }
    </div>
}