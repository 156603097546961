import { Col, Pagination, Row } from "react-bootstrap";

export default function Paginator(props) {

    function handleLastPageClicked() {
        props.paginator.SetPageIndex(props.paginator.TotalPages - 1);
    }

    function handleFirstPageClicked() {
        props.paginator.SetPageIndex(0);
    }

    function handlePreviousPageClicked() {
        props.paginator.SetPageIndex(props.paginator.PageIndex - 1);
    }

    function handleNextPageClicked() {
        props.paginator.SetPageIndex(props.paginator.PageIndex + 1);
    }

    return  <Row >
                <Col lg="12" className="d-flex justify-content-center">
                    <Pagination size="lg" className="mb-0">
                        <Pagination.First href="javascript:void(0);" disabled={ props.paginator.PageIndex === 0 } onClick={  handleFirstPageClicked } />
                        <Pagination.Prev href="javascript:void(0);" disabled={ props.paginator.PageIndex === 0 } onClick={  handlePreviousPageClicked } />
                        <span className="my-auto mx-4">Page { props.paginator.PageIndex + 1 } of { props.paginator.TotalPages }</span>
                        <Pagination.Next href="javascript:void(0);" disabled={ props.paginator.PageIndex === (props.paginator.TotalPages - 1) }  onClick={ handleNextPageClicked } />
                        <Pagination.Last href="javascript:void(0);" disabled={ props.paginator.PageIndex === (props.paginator.TotalPages - 1) }  onClick={ handleLastPageClicked } />
                    </Pagination>
                </Col>
            </Row>
}