import { faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useSessionStore } from "../../../../Stores/SessionStore";
import BusyIndicator from "../../../Core/BusyIndicator";
import ClickIcon from "../../../Core/ClickIcon";
import ConfirmationModal from "../../../Core/ConfirmationModal";

export default function ReinstateMember(props) {
	const [reinstateModalShown, showReinstateModal] = useState(false);
	const [reinstatingMember, setReinstatingMember] = useState(false);

	const store = useSessionStore();

	function isReinstating(reinstating) {
		setReinstatingMember(reinstating);

		if(props.onReinstating) {
			props.onReinstating(reinstating);
		}
	}

	async function handleReinstateResponse(response) {
        showReinstateModal(false);

        if(response) {
            isReinstating(true);
            var result = await store.MembersStore.ReinstateMember(props.memberId);
            isReinstating(false);

            if(result && props.onMemberReinstated) {
				props.onMemberReinstated();
            }
        }
    }

	return <span>
		{
			reinstatingMember === false && !props.showText &&
			<ClickIcon title="Reinstate Member" onClick={ e => showReinstateModal(true) } icon={ faUserCheck }></ClickIcon>
		}
		{
			reinstatingMember === false && props.showText &&
			<span onClick={ e => showReinstateModal(true) }>
				<span style={{ width: "22px", display: "inline-block" }}>
					<ClickIcon title="Reinstate Member"  icon={ faUserCheck }></ClickIcon>
				</span>
				<span className="ml-2"> Reinstate Member</span>
			</span>
		}
		<BusyIndicator sm show={ reinstatingMember }></BusyIndicator>
		<ConfirmationModal show={ reinstateModalShown } onResponse={ handleReinstateResponse } title="Reinstate Member?" message="Are you sure you want to reinstate this member?"></ConfirmationModal>
	</span>
}