import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { BatchSortColumns } from "../../../Stores/BatchesStore";
import { useSessionStore } from "../../../Stores/SessionStore";
import { getTransactionType } from "../../../Stores/TransactionsStore";
import SortableTH from "../../Core/SortableTH";

export default function BatchesList(props) {
	const store = useSessionStore();

	useEffect(() => {
		async function initialise() {
			if(store.ControlsStore.ActiveControl === null) {
				await store.ControlsStore.GetActiveControl();
			}
		}

		initialise();
	}, []);

    return (
		<Table striped bordered hover>
			<thead>
				<tr>
					{
						props.showTransfers &&
						<th>Downloaded</th>
					}
					{
						props.showPosted &&
						<th>Posted</th>
					}

					<SortableTH onUpdate={ props.onSort } column={ BatchSortColumns.BatchNumber } store={ store.BatchesStore }>Batch No</SortableTH>
					<SortableTH onUpdate={ props.onSort } column={ BatchSortColumns.Type } store={ store.BatchesStore }>Type</SortableTH>
					<th>Batch Date</th>
					<SortableTH onUpdate={ props.onSort } column={ BatchSortColumns.TotalTransactions } store={ store.BatchesStore }>Total Transactions</SortableTH>
					<SortableTH className="text-right" onUpdate={ props.onSort } column={ BatchSortColumns.Amount } store={ store.BatchesStore }>Amount</SortableTH>
					<th className="text-center">Actions</th>
				</tr>
			</thead>
			{
				props.busy === false && store.ControlsStore.ActiveControl &&
				<tbody>
				{
					props.batches.length > 0 &&
					props.batches.map((batch) =>
					<tr key={ batch.Id }>
						{
							props.showTransfers &&
							<td className="text-center text-success">
							{
								batch.HasTransfers &&
								<FontAwesomeIcon title="A CATS File for this Batch has been Downloaded" icon={ faCheckCircle }></FontAwesomeIcon>
							}
							</td>
						}

						{
							props.showPosted &&
							<td className="text-center text-success">
							{
								batch.PostedDate &&
								<FontAwesomeIcon title="This Batch has been Posted" icon={ faCheckCircle }></FontAwesomeIcon>
							}
							</td>
						}
						<td>{ batch.BatchNumber }</td>
						<td>{ getTransactionType(batch.TransactionTypeId) }</td>
						<td>{ DateTime.fromISO(store.ControlsStore.ActiveControl.ProcessingDate).toFormat("yyyy-MM-dd") }</td>
						<td>{ batch.TotalTransactions }</td>
						<td className="text-right">R { batch.Amount.toFixed(2) }</td>
						<td  className="text-center">
							{
								React.cloneElement(props.actions, { batch: batch })
							}
						</td>
					</tr>
					)
				}

				{
					props.batches.length === 0 &&
					<tr className="text-center">
						<td colSpan={ ((props.showTransfers || props.showPosted) ? "7" : "6")}><b className="text-muted">No Batches Available</b></td>
					</tr>
				}
				</tbody>
			}
		</Table>)
}