import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import DepositsImportSummary from "./DepositsImportSummary";
import FailedDepositsVerificationResults from "./FailedDepositsVerificationResults";

export default function DepositsImportIndex() {
	const [fileName, setFileName] = useState("No File Selected")
	const [file, setFile] = useState(null)

	const [verifyingFile, setVerifyingFile] = useState(false);
	const [verificationResult, setVerificationResult] = useState(null);
	const [verificationError, setVerificationError] = useState("");
	const [verificationErrorRows, setVerificationErrorRows] = useState([]);

	const [importingFile, setImportingFile] = useState(false);

	const store = useSessionStore();
	const fileInputRef = useRef(null)

	async function handleFileChange(target) {
		setVerificationResult(null);
		setVerificationError("");
		setVerificationErrorRows([]);

		if(target.files.length > 0) {
			setFileName(target.files[0].name);

			var fileContents = await target.files[0].text();
			setFile(fileContents);
		} else {
			setFileName("No File Selected");
			setFile(null);
		}
	}

	async function handleVerifyFileClicked() {
		setVerifyingFile(true);
		var result = await store.DepositsImportsService.VerifyDepositsImportFile(file);

		if(result) {
			setVerifyingFile(false);

			if(result.Success) {
				setVerificationResult(result.Data);
			} else {
				setVerificationError(result.Message);
				setFile(null);
				setFileName("No File Selected");
				fileInputRef.current.value = "";

				if(result.Data !== null && result.Data.Errors.length > 0) {
					setVerificationErrorRows(result.Data.Errors);
				}
			}
		}
	}

	async function handleImportFileClicked() {
		setImportingFile(true);
		var result = await store.DepositsImportsService.ImportDepositsFile(file);

		if(result) {
			setImportingFile(false);

			if(result.Success) {
				setVerificationResult(null);
				setVerificationError("");
				setFileName("No File Selected");
				setFile(null);

				store.ToastStore.ShowSuccessToast("Deposits Imported Successfully", "Deposits Import");
			} else {
				store.ToastStore.ShowErrorToast("Deposits Import Failed: " + result.Message, "Deposits Import");
			}
		}
	}

	function removeMemberFromList(serviceNumber, list) {
		var index = list.findIndex((member) => member.ServiceNumber === serviceNumber);

		if(index !== -1) {
			list.splice(index, 1);

			setVerificationResult({
				DepositsAmount: verificationResult.DepositsAmount,
				TotalDeposits: verificationResult.TotalDeposits,
				ImportValid: verificationResult.ImportValid,
				DepositsImportTypeId: verificationResult.DepositsImportTypeId,
				TerminatedMembers: verificationResult.TerminatedMembers,
				MissingMembers: verificationResult.MissingMembers,
				Errors: verificationResult.Errors
			 })
		}
	}

	function handleMemberAdded(serviceNumber) {
		//remove the member from the missing members list
		removeMemberFromList(serviceNumber, verificationResult.MissingMembers);
	}

	function handleMemberReinstated(serviceNumber) {
		//remove the member from the terminated members list
		removeMemberFromList(serviceNumber, verificationResult.TerminatedMembers);
	}

	return 	<Panel >
				<PanelHeader icon={ faFileUpload } title="Deposits Import"></PanelHeader>
				<Row>
					<Col>
						<input ref={fileInputRef} disabled={ verifyingFile || importingFile } hidden type="file" id="ImportFile" onChange={ e => handleFileChange(e.target) }></input>
						<label htmlFor="ImportFile" className="p-3 d-flex justify-content-center align-items-center text-primary" style={{ backgroundColor: "#0166c210", border: "2px dashed #0166c2", borderRadius: "5px", cursor: "pointer" }}>
							<FontAwesomeIcon icon={ faFileUpload } size="3x"></FontAwesomeIcon>
							<h5 className="mb-0 ml-3">{ file === null ? "Select Deposits File" : fileName }</h5>
						</label>
					</Col>
				</Row>
				<hr></hr>
				{
					verificationResult === null &&
					<Row>
						<Col className="d-flex flex-column align-items-center">
							{
								file === null &&
								<h5 className="text-muted text-center m-3 p-3">No Deposits File Selected</h5>
							}
							{
								file !== null &&
								<Button disabled={ verifyingFile } size="lg" variant="success" className="m-3" onClick={ handleVerifyFileClicked }>{ verifyingFile ? "Verifying..." : "Verify Deposits File"} </Button>
							}
							{
								verificationError !== "" &&
								<h5 className="text-danger text-center m-3 p-3">{ verificationError }</h5>
							}
							{
								verificationErrorRows.length > 0 &&
								verificationErrorRows.map((error) =>
								<h6 className="text-danger">{error}</h6>)
							}
						</Col>
					</Row>
				}
				{
					verificationResult !== null &&
					<DepositsImportSummary result={ verificationResult } verifyingFile={ verifyingFile } onVerifyFileClicked={ handleVerifyFileClicked }
											importingFile={ importingFile } onImportFileClicked={ handleImportFileClicked }></DepositsImportSummary>
				}
				{
					verificationResult !== null && verificationResult.ImportValid === false &&
					<FailedDepositsVerificationResults  result={ verificationResult } onMemberAdded={ handleMemberAdded } onMemberReinstated={ handleMemberReinstated }></FailedDepositsVerificationResults>
				}
			</Panel>
}