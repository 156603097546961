import { faArchive, faEye, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import ClickIcon from "../../Core/ClickIcon";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import BatchesList from "./BatchesList";
import BatchTransactionsModal from "./BatchTransactionsModal";

const BatchesIndex = observer(function BatchesIndex() {
    const [batches, setBatches] = useState([]);
    const [showTransactions, setShowTransactions] = useState(false);
    const [activeBatch, setActiveBatch] = useState(null);

    const store = useSessionStore();

    async function getPendingBatches() {
        var batches = await store.BatchesStore.GetPendingBatches();

        if(batches) {
            setBatches(batches);
        }
    }

    async function handleViewTransactionsClicked(batch) {
        setActiveBatch({ BatchId: batch.Id, BatchNumber: batch.BatchNumber, PostedDate: batch.PostedDate });
        setShowTransactions(true)
    }

	async function handlePostBatchesClicked() {
        var result = await store.BatchesStore.PostPendingBatches();

        if(result) {
            getPendingBatches();
        }
    }

    useEffect(() => {
        getPendingBatches();
    }, []);

	const Actions = function(props) {
		return <ClickIcon icon={ faEye } title="View Transactions" onClick={ (e) => handleViewTransactionsClicked(props.batch) }></ClickIcon>;
	}

    return <Panel>
            <PanelHeader icon={ faArchive } title="Batches">
				<Button disabled={ store.BatchesStore.PostingPendingBatches || batches.length === 0  } variant="outline-primary" onClick={ handlePostBatchesClicked }>
                    <FontAwesomeIcon icon={ faUpload }></FontAwesomeIcon> { store.BatchesStore.PostingPendingBatches ? "Posting Batches..." : "Post Batches" }
				</Button>
			</PanelHeader>
            <Row>
                <Col lg={12}>
					<BatchesList onSort={ getPendingBatches } showPosted busy={ store.BatchesStore.RetrievingPendingBatches } batches={ batches } actions={ <Actions></Actions> }></BatchesList>
                    <BusyIndicator text="Retrieving Batches..." show={ store.BatchesStore.RetrievingPendingBatches }></BusyIndicator>
                </Col>
            </Row>
            <BatchTransactionsModal allowDelete={ (activeBatch ? (activeBatch.PostedDate === null) : false) } onClose={ () => setShowTransactions(false) } show={ showTransactions } batchId={ activeBatch ? activeBatch.BatchId : null } batchNumber={ activeBatch ? activeBatch.BatchNumber : null }></BatchTransactionsModal>
        </Panel>

});

export default BatchesIndex;