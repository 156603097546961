import { Formik, Form as FormikForm } from "formik";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Row, Button } from "react-bootstrap";
import { Form } from "react-bootstrap-formik";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import * as yup from 'yup';

export var DepartmentDetails = observer(function Details(props) {
    const store = useSessionStore();
    const [department, setDepartment] = useState({ Code: "", Name: "" })

    useEffect(() => {
        async function getDepartment() {
            var department = await store.DepartmentsStore.CRUD.GetEntry(props.departmentId);
    
            if(department) {
                setDepartment(department)

            } else {
                props.onEditComplete(false)
            }
        }

        if(props.departmentId) {
            getDepartment();
        }
    }, [props, store.DepartmentsStore]);

    async function handleSubmit (data) { 
        var result = false;
        
        if(department.Id) {
            result = await store.DepartmentsStore.CRUD.UpdateEntry(data);
        } else {
            result = await store.DepartmentsStore.CRUD.CreateEntry(data);
        }

        if(result) {
            props.onEditComplete(true);
        }
    }

    const validationSchema = 
        yup.object({ 
            Code: yup.string().required("Code is required."), 
            Name: yup.string().required("Name is required") }
        ).required();
    
    return  <div>
                {
                    store.DepartmentsStore.CRUD.GettingEntry === false &&
                    <Formik validationSchema={ validationSchema } enableReinitialize={true} initialValues={department} onSubmit={ handleSubmit }>
                        <FormikForm>
                            <Form.Input label="Code" name="Code" />
                            <Form.Input label="Name" name="Name" />
                            <Row className="modal-footer pb-0">
                                <Button disabled={ store.DepartmentsStore.CRUD.UpdatingEntry } type="submit">{ store.DepartmentsStore.CRUD.UpdatingEntry ? "Saving..." : "Save" }</Button>
                                <Button onClick={ e => props.onEditComplete(false) } variant="danger" disabled={ store.DepartmentsStore.CRUD.UpdatingEntry } type="button">Cancel</Button>
                            </Row>  
                        </FormikForm>
                    </Formik>
                }                
                <BusyIndicator show={ store.DepartmentsStore.CRUD.GettingEntry } text="Retrieving Department..."></BusyIndicator>
            </div>
});

export default DepartmentDetails