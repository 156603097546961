import { useSessionStore } from "../../../../Stores/SessionStore";
import ClickIcon from "../../../Core/ClickIcon";
import ReinstateMember from "./ReinstateMember";
import { MemberStatuses } from "../../../../Stores/Members/MemberStore";
import { faBookDead, faEllipsisV, faEraser, faMoneyBillWaveAlt, faUserSlash } from "@fortawesome/free-solid-svg-icons";
import TerminateMemberModal from "../TerminateMemberModal";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import * as Bootstrap from "bootstrap"
import CreateCashDepositModal from "../Transactions/CreateCashDepositModal";
import CreateWriteOffModal from "../Transactions/CreateWriteOffModal";
import DeathBenefitPayoutModal from "../DeathBenefitPayoutModal";

export default function MoreActionsDropDown(props) {
	const [terminateModalShown, showTerminateModal] = useState(false);
	const [createDepositModalShown, showCreateDepositModal] = useState(false);
	const [createWriteOffModalShown, showCreateWriteOffModal] = useState(false);
	const [createDeathBenefitPayoutModalShown, showCreateDeathBenefitPayoutModal] = useState(false);

	const store = useSessionStore();

	function handleTerminateMemberComplete(success) {
        if(success) {
            store.MembersStore.CRUD.GetEntries();
        }

        showTerminateModal(false);
    }

	useEffect(() => {
		const drop = $(`#${props.member.Id}`);
		const ddn = new Bootstrap.Dropdown(drop);

		function handleClick(e) {
			if(e.path && (e.path[0] || e.path[1]) && (e.path[0].id !== props.member.Id && e.path[1].id !== props.member.Id)) {
				ddn.hide();
			}

			if(e.target && (e.target.id !== props.member.Id && e.target.parentNode && e.target.parentNode.id !== props.member.Id)) {
				ddn.hide();
			}
		}

		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		}

	}, []);

	return 	<span>
				<div className="dropdown" >
					<FontAwesomeIcon fixedWidth title="More Actions..." id={ props.member.Id } data-toggle="dropdown" style={{ cursor: "pointer" }} className="dropdown-toggle text-primary" icon={ faEllipsisV }></FontAwesomeIcon>
					<div className="dropdown-menu">
						{
							props.member.MemberStatusId !== MemberStatuses.Terminated &&
							<button className="dropdown-item" onClick={ e => showTerminateModal(true) }>
								<span style={{ width: "22px", display: "inline-block" }}>
									<ClickIcon title="Terminate Member"  icon={ faUserSlash }></ClickIcon>
								</span>
								<span className="ml-2"> Terminate Member</span>
							</button>
						}
						{
							props.member.MemberStatusId === MemberStatuses.Terminated &&
							<button className="dropdown-item">
								<ReinstateMember showText memberId={props.member.Id} onReinstating={ props.onReinstating } onMemberReinstated={ e => store.MembersStore.CRUD.GetEntries() }></ReinstateMember>
							</button>
						}
						<button className="dropdown-item" onClick={ e => showCreateDepositModal(true) }>
							<span style={{ width: "22px", display: "inline-block" }}>
								<ClickIcon title="Create Cash Deposit" icon={ faMoneyBillWaveAlt }></ClickIcon>
							</span>
							<span className="ml-2"> Create Cash Deposit</span>
						</button>
						<button className="dropdown-item" onClick={ e => showCreateWriteOffModal(true) }>
							<span style={{ width: "22px", display: "inline-block" }}>
								<ClickIcon title="Create Write Off" icon={ faEraser }></ClickIcon>
							</span>
							<span className="ml-2"> Create Write Off</span>
						</button>
						<button className="dropdown-item" onClick={ e => showCreateDeathBenefitPayoutModal(true) }>
							<span style={{ width: "22px", display: "inline-block" }}>
								<ClickIcon title="Create Write Off" icon={ faBookDead }></ClickIcon>
							</span>
							<span className="ml-2"> Death Benefit Payout</span>
						</button>
					</div>
				</div>

        		<TerminateMemberModal member={ props.member } memberId={ props.member.Id } show={ terminateModalShown } onClose={ handleTerminateMemberComplete }></TerminateMemberModal>
				<CreateCashDepositModal member={ props.member } show={ createDepositModalShown } onClose={ e => showCreateDepositModal(false) }></CreateCashDepositModal>
				<CreateWriteOffModal member={ props.member } show={ createWriteOffModalShown } onClose={ e => showCreateWriteOffModal(false) }></CreateWriteOffModal>
				<DeathBenefitPayoutModal member={props.member} show={ createDeathBenefitPayoutModalShown } onClose={ e => showCreateDeathBenefitPayoutModal(false) }></DeathBenefitPayoutModal>
			</span>
}