import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Row } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import * as yup from 'yup';
import { DateTime } from "luxon";
import Modal from "../../Core/Modal";
import { Form } from "react-bootstrap-formik";

export default function ReportDateFilterModal(props) {

	function okClicked(data) {
		props.onClose(data);
	}

	const validationSchema =
        yup.object({
            Date: yup.date().required("Date is required")
        }).required();

    return 	<Modal show={ props.show } title={"Report Filter - " + props.reportName } icon={ faFileAlt }>
				<Formik validationSchema={ validationSchema } initialValues={{ Date: DateTime.now().toISODate() }} onSubmit={ okClicked }>
					{
						({ errors }) =>
						<FormikForm>
							<Row className="mt-3">
								<Col>
									<Form.Input type="date" label="Audit Date" name="Date" />
								</Col>
							</Row>
							<Row className="modal-footer pb-0">
								<Button type="submit">OK</Button>
								<Button variant="danger" onClick={ () => props.onClose(null) }>Cancel</Button>
							</Row>
						</FormikForm>
					}
				</Formik>
    		</Modal>
}