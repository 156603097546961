import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { SaveReportFile } from "../../../../Stores/AdhocReportsStore";
import { useSessionStore } from "../../../../Stores/SessionStore";
import AccordianItem from "../../../Core/AccordianItem";
import Modal from "../../../Core/Modal";
import BalanceSummary from "../MemberDetails/BalanceSummary";
import HistoricalTransactions from "./HistoricalTransactions";
import MemberBalances from "./MemberBalances";
import MemberTransactions from "./MemberTransactions";

export default function MemberTransactionsAndBalancesModal(props) {
	const store = useSessionStore();

	const [dowloadingRecord, setDownloadingRecord] = useState(false);
	const [dowloadingStatement, setDownloadingStatement] = useState(false);
	const [dowloadingPreviousStatement, setDownloadingPreviousStatement] = useState(false);

	async function DownloadMemberRecord() {
		setDownloadingRecord(true);
		var result = await store.AdhocReportsService.GetMemberRecordReport(props.memberId);
		setDownloadingRecord(false);

		if(result && result.Success) {
			SaveReportFile(result.Data);
		} else {
			store.ToastStore.ShowErrorToast("An error ocurred trying to retrieve the member's record: " + result.Message, "Error");
		}
	}

	async function DownloadMemberStatement() {
		setDownloadingStatement(true);
		var result = await store.AdhocReportsService.GetMemberStatementReport(props.memberId);
		setDownloadingStatement(false);

		if(result && result.Success) {
			SaveReportFile(result.Data);
		} else {
			store.ToastStore.ShowErrorToast("An error ocurred trying to retrieve the member's statement: " + result.Message, "Error");
		}
	}

	async function DownloadPreviousMemberStatement() {
		setDownloadingPreviousStatement(true);
		var result = await store.AdhocReportsService.GetMemberStatementReportForPreviousYear(props.memberId);
		setDownloadingPreviousStatement(false);

		if(result && result.Success) {
			SaveReportFile(result.Data);
		} else {
			store.ToastStore.ShowErrorToast("An error ocurred trying to retrieve the member's statement: " + result.Message, "Error");
		}
	}

    var footer = <div>
					<Button disabled={ dowloadingPreviousStatement } onClick={ e => DownloadPreviousMemberStatement() } className="mr-3">
						{ (dowloadingPreviousStatement ? "Downloading...": "Download Previous Year Statement")}
					</Button>
					<Button disabled={ dowloadingStatement } onClick={ e => DownloadMemberStatement() } className="mr-3">{ (dowloadingStatement ? "Downloading...": "Download Statement")}</Button>
					<Button disabled={ dowloadingRecord } onClick={ e => DownloadMemberRecord() } className="mr-3">{ (dowloadingRecord ? "Downloading...": "Download Record")}</Button>
					<Button onClick={ props.onClose }>Close</Button>
				</div>

    return <Modal size="lg" icon={ faDollarSign } title={`Transactions & Balances - ${props.member && props.member.Name } ${props.member && props.member.ServiceNumber }`} show={ props.show } footer={ footer }>
        <BalanceSummary memberId={ props.memberId }></BalanceSummary>
        <Accordion defaultActiveKey="0">
            <AccordianItem eventKey="0" title="Balances">
                <div className="m-n1">
                    <MemberBalances memberId={ props.memberId }></MemberBalances>
                </div>
            </AccordianItem>
            <AccordianItem eventKey="1" title="Transactions">
                <MemberTransactions memberId={ props.memberId }></MemberTransactions>
            </AccordianItem>
			<AccordianItem eventKey="2" title="Transactions History">
                <HistoricalTransactions memberId={ props.memberId }></HistoricalTransactions>
            </AccordianItem>
        </Accordion>
    </Modal>
}