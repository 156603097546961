import APIBase from "./APIBase";

/**
 * @typedef Transaction
 * @property {string} Id
 * @property {number} Amount
 * @property {string} UserId
 * @property {string} MemberId
 * @property {string} BatchId
 * @property {number} TransactionTypeId
 * @property {string} ParentTransactionId
 * @property {number} TransactionNumber
 * @property {string} Reference
 * @property {string} TransactionDate
 * @property {string} MemberDetails
 * @property {string} Period
 * @property {number} BatchNumber
 */

/**
 * @typedef TransactionsService
 * @type {TransactionsService}
 */
export default class TransactionsService extends APIBase {    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Transactions", sessionStore);
    }

    /**
     * @param {string} year The year that posted transactions will be retrieved for
     * @param {string} memberId The id of the member whose posted transactions will be retrieved
     * @returns { import("./APIBase").EnumerableRequestResult<Transaction> }
     */
    async GetPostedTransactionsForYearForMember(year, memberId, sortColumn, sortDirection) {
        return this.Get(`GetPostedTransactionsForYearForMember?year=${year}&memberId=${memberId}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`);
    }

    /**
     * @param {string} batchId The ID of the batch to get transactions for
     * @param {number} startIndex
     * @param {number} pageCount
     */
    async GetTransactionsForBatch(batchId, startIndex, pageCount) {
        return this.Get(`GetTransactionsForBatch?batchId=${batchId}&startIndex=${startIndex}&pageCount=${pageCount}`);
    }

	/**
	 * Removes the given transaction from the given batch
	 * @param {string} batchId The id of the batch containing the transaction to delete
	 * @param {string} transactionId The id of the transaction to delete
	 * @returns { Promise<import("./APIBase").RequestResult<boolean>> }
	 */
	async DeleteTransactionFromBatch(batchId, transactionId) {
		return this.Post(`DeleteTransactionFromBatch?batchId=${batchId}&transactionId=${transactionId}`);
	}

    /**
     * @param {string} transactionId The ID of the transaction to reverse
     */
    async ReverseTransaction(transactionId) {
        return this.Post(`ReverseTransaction?transactionId=${transactionId}`);
    }

	/**
	 * Creates a new cash deposit transaction and batch for the member
	 * @returns { Promise<import("./APIBase").RequestResult<boolean> }
	 */
	async CreateCashDeposit(memberId, amount) {
        return this.Post(`CreateCashDeposit?memberId=${memberId}&amount=${ encodeURIComponent(amount) }`);
    }

	/**
	 * Creates a new cash deposit transaction and batch for the member
	 * @returns { Promise<import("./APIBase").RequestResult<boolean> }
	 */
	async CreateWriteOff(memberId, amount) {
        return this.Post(`CreateWriteOff?memberId=${memberId}&amount=${ encodeURIComponent(amount) }`);
    }

	/**
	 * Creates a new death benefit payout for the given memeber
	 * @returns { Promise<import("./APIBase").RequestResult<boolean> }
	 */
	async CreateDeathBenefitPayout(memberId, amount, bankAccount) {
		return this.Post(`CreateDeathBenefitPayout?memberId=${memberId}&amount=${ encodeURIComponent(amount) }`, bankAccount);
	}

	/**
     * returns the current details of the db fund
	 * @returns { Promise<import("./APIBase").RequestResult<object> }
     */
	 async GetDeathBenefitFundDetails() {
        return this.Get(`GetDeathBenefitFundDetails`);
    }
}