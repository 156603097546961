import APIBase from "./APIBase";

/**
 * @typedef Setting
 * @property {string} Id      
 * @property {string} Name
 * @property {string} Value
 * @property {string} Group
 * @property {string} Category
 * @property {string} Subcategory
 */

/**
 * @typedef SettingsService
 * @type {SettingsService}
 */
export default class SettingsService extends APIBase {

    /**
     * @param {string} apiUrl 
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Settings", sessionStore);
    }
    
    /**
     * @param {Array<Setting>} settings The collection on settings to update
     * @returns { Promise<import("./APIBase").RequestResult<boolean>> }
     */
    async UpdateSetiings(settings) {
        return this.Post(`UpdateSettings`, settings);
    }

    /**
     * @returns { import("./APIBase").EnumerableRequestResult<Setting> }
     */
    async GetSettings() {
        return this.Get(`GetSettings`);
    }
}