import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import InfoLabel from "../../Core/InfoLabel";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";

export var ActiveControl = observer(function ActiveControl(props){
    const store = useSessionStore();

    useEffect(() =>
    {
        store.ControlsStore.GetActiveControl();
    }, [store.ControlsStore]);

    return <Panel>
            <PanelHeader icon={ faClipboardCheck } title="Current Active Control"></PanelHeader>
        {
            store.ControlsStore.ActiveControl && store.ControlsStore.GettingActiveControl === false &&
            <div>
                <Row className="mb-3">
                    <Col>
                        <InfoLabel label="Processing Date" text={ DateTime.fromISO(store.ControlsStore.ActiveControl.ProcessingDate).toFormat("yyyy/MM/dd") }></InfoLabel>
                    </Col>
                    <Col>  
                        <InfoLabel label="Month End Date" text={ DateTime.fromISO(store.ControlsStore.ActiveControl.MonthEndDate).toFormat("yyyy/MM/dd") }></InfoLabel>
                    </Col>
                    <Col>   
                        <InfoLabel label="Year End Date" text={ DateTime.fromISO(store.ControlsStore.ActiveControl.YearEndDate).toFormat("yyyy/MM/dd") }></InfoLabel>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>        
                        <InfoLabel label="Bank Charges" text={ "R" + store.ControlsStore.ActiveControl.BankCharges.toFixed(2) }></InfoLabel>
                    </Col>
                    <Col>      
                        <InfoLabel label="Randage Rate" text={ store.ControlsStore.ActiveControl.RandageRate.toFixed(2) + "%" }></InfoLabel>
                    </Col>
                    <Col>      
                        <InfoLabel label="Death Benefit Fund Fee" text={ "R" + store.ControlsStore.ActiveControl.DeathBenefitFundFee.toFixed(2) }></InfoLabel>
                    </Col>
                </Row>
            </div>
        }
        {
            (store.ControlsStore.GettingActiveControl === false && (store.ControlsStore.ActiveControl === null || store.ControlsStore.ActiveControl === undefined)) &&
            <Row>
                <Col className="text-muted text-center"><b>No Active Control is Available</b></Col>
            </Row>
        }
        <BusyIndicator show={ store.ControlsStore.GettingActiveControl } text="Retrieving Active Control..."></BusyIndicator>
    </Panel>
});

export default ActiveControl;