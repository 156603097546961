import { faBookDead } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { getTransactionType } from "../../../Stores/TransactionsStore";
import BusyIndicator from "../../Core/BusyIndicator";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";

export default function DeathBenefitFundIndex(props) {
	const [fundDetails, setFundDetails] = useState(null);

	const store = useSessionStore();

	useEffect(() => {
		async function getFundDetails() {
			var result = await store.TransactionsService.GetDeathBenefitFundDetails();

			if(result && result.Success) {
				setFundDetails(result.Data);
			} else {
				store.ToastStore.ShowErrorToast("An error occurred retrieving the Death Benefit Fund Details", "Error");
			}
		}

		getFundDetails();
	}, []);

	return 	<Panel>
				<PanelHeader icon={ faBookDead } title="Death Benefit Fund"></PanelHeader>
				{
					fundDetails &&
					<React.Fragment>
						<Row>
							<Col className="d-flex align-items-center justify-content-end">
								<h5 className="text-right">{ `Current Balance: R ${fundDetails.CurrentBalance.toFixed(2)}` }</h5>
							</Col>
						</Row>
						<Row>
							<Col lg={12}>
								<Table striped bordered hover>
									<thead>
										<tr>
											<th>Batch</th>
											<th>Type</th>
											<th>Date</th>
											<th>Reference</th>
											<th className="text-right">Debit</th>
											<th className="text-right">Credit</th>
											<th>Period</th>
										</tr>
									</thead>
										<tbody>
										{
											fundDetails.Transactions.length > 0 &&
											fundDetails.Transactions.map((transaction, index) =>
											<tr key={index }>
												<td>{ transaction.BatchNumber }</td>
												<td>{ getTransactionType(transaction.TransactionTypeId) }</td>
												<td>{ DateTime.fromISO(transaction.TransactionDate).toFormat("yyyy-MM-dd") }</td>
												<td>{ transaction.Reference }</td>
												<td className="text-right">{ transaction.Amount < 0 ? `R ${Math.abs(transaction.Amount).toFixed(2)}` : "" }</td>
												<td className="text-right">{ transaction.Amount > 0 ? `R ${Math.abs(transaction.Amount).toFixed(2)}` : "" }</td>
												<td>{ (transaction.Period ? transaction.Period : store.ControlsStore.GetCurrentPeriod() ) }</td>
											</tr>
											)
										}

										{
											fundDetails.Transactions.length === 0 &&
											<tr className="text-center">
												<td colSpan="7"><b className="text-muted">No Transactions returned</b></td>
											</tr>
										}
										</tbody>

								</Table>
							</Col>
						</Row>
					</React.Fragment>
				}
				<BusyIndicator text="Retrieving Death Benefit Fund Details..." show={ fundDetails === null }></BusyIndicator>
			</Panel>
}