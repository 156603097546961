import { Button, Col, Row } from "react-bootstrap";

export default function ConfirmationDialog(props) {
	return 	<div>
				<h6 className="p-2">{props.text}</h6>
				<hr className="ml-n3 mr-n3"></hr>
				<Row>
					<Col className="d-flex justify-content-end">
						<Button onClick={ e => props.onResult(true) }>Yes</Button>
						<Button onClick={ e => props.onResult(false) } variant="secondary" className="ml-2">No</Button>
					</Col>
				</Row>
			</div>
}