import { useRef } from "react";
import { useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { Form } from "react-bootstrap-formik";
import { BankAccountTypes } from "../../../../Stores/Members/MemberStore";


export default function BankAccountDetails(props) {
    const [changingBankAccount, setChangingBankAccount] = useState(false)
    const oldBankAccount = useRef({});

    function forbidBankAccountEdit() {
        if(props.member.Id === null || props.member.Id === undefined) {
            return false;
        }
        else {
            return changingBankAccount === false;
        }
    }

    function startBankAccountChange() {
        oldBankAccount.current = { ...props.member.BankAccount };
        setChangingBankAccount(true);
    }

    function cancelBankAccountChange() {
        setChangingBankAccount(false);

        //reset bank details
        props.setFieldValue("BankAccount.Payee", oldBankAccount.current.Payee);
        props.setFieldValue("BankAccount.BankName", oldBankAccount.current.BankName);
        props.setFieldValue("BankAccount.BranchCode", oldBankAccount.current.BranchCode);
        props.setFieldValue("BankAccount.AccountNumber", oldBankAccount.current.AccountNumber);
        props.setFieldValue("BankAccount.BankAccountTypeId", oldBankAccount.current.BankAccountTypeId);
    }

    function applyBankAccountChange() {
        setChangingBankAccount(false);
        props.onBankAccountChanged();

        //update the bank account type name string
        props.setFieldValue("BankAccount.BankAccountType", BankAccountTypes.GetText(parseInt(props.member.BankAccount.BankAccountTypeId)));
    }

    return <div>
        <Form.Input disabled={ forbidBankAccountEdit() } label="Payee" name="BankAccount.Payee" />
        <Row>
            <Col>
                <Form.Input disabled={ forbidBankAccountEdit() } label="Bank Name" name="BankAccount.BankName" />
            </Col>
            <Col>
                <Form.Input disabled={ forbidBankAccountEdit() } label="Branch Code" name="BankAccount.BranchCode" />
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Input disabled={ forbidBankAccountEdit() } label="Account Number" name="BankAccount.AccountNumber" />
            </Col>
            <Col>
                <Form.Select disabled={ forbidBankAccountEdit() } label="Account Type" name="BankAccount.BankAccountTypeId">
                    <option value={ BankAccountTypes.Cheque }>{ `${BankAccountTypes.Cheque} - Cheque` }</option>
                    <option value={ BankAccountTypes.Savings }>{ `${BankAccountTypes.Savings} - Savings` }</option>
                    <option value={ BankAccountTypes.Transmission }>{ `${BankAccountTypes.Transmission} - Transmission` }</option>
                </Form.Select>
            </Col>
        </Row>
		{
			props.member.HasOutstandingBankAccountChangeApproval &&
			<Alert variant="danger">
				A bank account change approval is currently pending for this user.
			</Alert>
		}
        {
            (props.member.Id !== null && props.member.Id !== undefined && !props.member.HasOutstandingBankAccountChangeApproval) &&
            <div className="d-flex justify-content-end">
                {
                    changingBankAccount === false &&
                    <Button variant="success" onClick={ startBankAccountChange }>Change Bank Account</Button>
                }
                {
                    changingBankAccount &&
                    <div>
                        <Button variant="success" onClick={ applyBankAccountChange }>Apply</Button>
                        <Button variant="danger" className="ml-3" onClick={ cancelBankAccountChange }>Cancel</Button>
                    </div>
                }
            </div>
        }
    </div>
}