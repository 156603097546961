import APIBase from "./APIBase";

/**
 * @typedef Department
 * @property {string} Id
 * @property {string} Name
 * @property {string} Code
 */

/**
 * @typedef DepartmentsService
 * @type {DepartmentsService}
 */
export default class DepartmentsService extends APIBase {

    /**
     * @param {string} apiUrl 
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Departments", sessionStore);
    }
    
    /**
     * @param {Department} department The Department object to create
     * @returns { Promise<import("./APIBase").RequestResult<string>> }
     */
    async CreateDepartment(department) {
        return this.Post("CreateDepartment", department);
    }
    
    /**
     * @param {Department} department The Department object to update
     */
    async UpdateDepartment(department) {
        return this.Post("UpdateDepartment", department);
    }
        
    /**
     * @param {number} startIndex The 0 based start page index
     * @param {number} pageCount The total amount of results to return for the page
     * @param {string} search String containing a string to filter results by
     * @returns { import("./APIBase").EnumerableRequestResult<Department> }
     */
    async GetDepartments(startIndex, pageCount, search) {
        return this.Get(`GetDepartments?startIndex=${startIndex}&pageCount=${pageCount}&search=${encodeURIComponent(search)}`);
    }
    
    /**
     * @param {string} departmentId The Id of the department to retrieve
     * @returns { Promise<import("./APIBase").RequestResult<Department>> }
     */
    async GetDepartment(departmentId) {
        return this.Get(`GetDepartment?departmentId=${departmentId}`);
    }

    /**
     * @param {string} departmentId The Id of the department to delete
     * @returns { Promise<import("./APIBase").RequestResult<boolean>> }
     */
    async DeleteDepartment(departmentId) {
        return this.Post(`DeleteDepartment?departmentId=${departmentId}`);
    }
}