import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { SaveReportFile } from "../../../../../Stores/AdhocReportsStore";
import { useSessionStore } from "../../../../../Stores/SessionStore";

export default function Statements(props) {
	const [downloadingStatements, setDownloadingStatements] = useState(false);
	const [message, setMessage] = useState("Kindly note that the Thrift Fund office will be closed from the day before Christmas Eve to the first working day in January.");

	const store = useSessionStore();

	async function DownloadStatements() {
		setDownloadingStatements(true);
		var result = await store.PeriodEndService.GetMemberStatements(message);
		setDownloadingStatements(false);

		if(result && result.Success) {
			SaveReportFile(result.Data);
		} else {
			store.ToastStore.ShowErrorToast("An error ocurred trying to retrieve the statements: " + result.Message, "Error");
		}
	}

	return 	<div className="m-n1">
				<Form>
					<Row>
						<Col>
							<Form.Label>Message</Form.Label>
							<textarea className="form-control" value={ message } onChange={ e => setMessage(e.target.value) }></textarea>
						</Col>
					</Row>

					<Row className="clearfix mt-3">
						<Col>
							<Button className="float-right" onClick={ e => DownloadStatements() } disabled={ downloadingStatements || props.disabled } type="button">{ downloadingStatements ? "Downloading..." : "Download Statements" }</Button>
						</Col>
					</Row>
				</Form>
			</div>
}