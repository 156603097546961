import { useSessionStore } from "../../../../Stores/SessionStore";
import { useEffect, useState, useRef } from "react";
import BusyIndicator from "../../../Core/BusyIndicator";
import { getTransactionType } from "../../../../Stores/TransactionsStore";
import { DateTime } from "luxon";
import { Table } from "react-bootstrap";
import Pagination from "../../../../Utilities/Paginator";
import Paginator from "../../../Core/Paginator";

export default function HistoricalTransactions(props) {
	const [gettingTransactions, setGettingTransactions] = useState(true);
    const [transactions, setTransactions] = useState(null);
	const pagination = useRef(new Pagination(getHistoricalTransactions));
	pagination.current.MemberId = props.memberId;

	const store = useSessionStore();

    async function getHistoricalTransactions() {
		setGettingTransactions(true);
        var transactions = await store.HistoriesService.GetHistoricalTransactionsForMember(pagination.current.MemberId, pagination.current.PageIndex, pagination.current.PageCount);

		if(transactions) {
			if(transactions.Success) {
				pagination.current.TotalData = transactions.Total;
				setGettingTransactions(false);
				setTransactions(transactions.Data);
			} else {
				store.ToastStore.ShowErrorToast("An error ocurred retrieving the member's Transaction History: " + transactions.Message);
			}
		}
    }

    useEffect(() => {
        getHistoricalTransactions();
    }, [props.memberId]);

    return  <div className="m-n2">
                <BusyIndicator text="Retrieving Transactions History..." show={ gettingTransactions || transactions === null }></BusyIndicator>
                {
                    gettingTransactions === false && transactions !== null &&
                    <div>
                        <Table striped hover bordered size="sm">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Reference</th>
                                    <th className="text-right">Debit</th>
                                    <th className="text-right">Credit</th>
                                    <th>Period</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                gettingTransactions === false && transactions !== null && transactions.length > 0 &&
                                transactions.map((transaction) =>
                                <tr key={ transaction.Id }>
                                    <td>{ getTransactionType(transaction.TransactionTypeId) }</td>
                                    <td>{ DateTime.fromISO(transaction.TransactionDate).toFormat("yyyy-MM-dd") }</td>
                                    <td>{ transaction.Reference }</td>
                                    <td className="text-right">{ transaction.Amount < 0 ? `R ${Math.abs(transaction.Amount).toFixed(2)}` : "" }</td>
                                    <td className="text-right">{ transaction.Amount > 0 ? `R ${Math.abs(transaction.Amount).toFixed(2)}` : "" }</td>
                                    <td>{ transaction.Period }</td>
                                </tr>
                                )
                            }
                            {
                                gettingTransactions === false && transactions !== null && transactions.length === 0 &&
                                <tr className="text-center">
                                    <td colSpan="6"><b className="text-muted">No transactions returned</b></td>
                                </tr>
                            }
                            </tbody>
                        </Table>
						{
							gettingTransactions === false && transactions.length > 0 && pagination.current.TotalPages > 1 &&
                    		<Paginator paginator={ pagination.current }></Paginator>
                		}
                    </div>
                }

            </div>
}