import { faPlusCircle, faUserPlus, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import Modal from "../../Core/Modal";
import Paginator from "../../Core/Paginator";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import SearchBox from "../../Core/SearchBox";
import UserActions from "./UserActions";
import UserDetails from "./UserDetails";

export const UsersIndex = observer(function Index(props) {
    const session = useSessionStore();
    const [detailsModalShown, showDetailsModal] = useState(false);

    useEffect(() =>
    {
        session.UsersStore.CRUD.GetEntries();
    }, [session.UsersStore]);

    function handleAddNewUserClicked() {
        showDetailsModal(true);
    }

    function handleAddUserComplete(success) {
        if(success) {
            session.UsersStore.CRUD.GetEntries();
        }

        showDetailsModal(false);
    }

    return <Panel>
            <PanelHeader icon={ faUser } title="Users">
                <Button variant="outline-primary" onClick={ handleAddNewUserClicked }><FontAwesomeIcon icon={ faPlusCircle }></FontAwesomeIcon> Add User</Button>
            </PanelHeader>
            <Form className="mb-3 d-flex">
                <SearchBox action={ e => session.UsersStore.CRUD.GetEntries() } placeholder="Search Users..." value={ session.UsersStore.Search } onChange={ e => session.UsersStore.Search = e.target.value }></SearchBox>
                <Button onClick={ e => session.UsersStore.CRUD.GetEntries() }>Apply</Button>
            </Form>
            <Row>
                <Col lg={12}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email Address</th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        {
                            session.UsersStore.CRUD.GettingEntries === false &&
                            <tbody>
                            {
                                session.UsersStore.CRUD.Entries.length > 0 &&
                                session.UsersStore.CRUD.Entries.map((user) =>
                                <tr key={ user.Id }>
                                    <td>{ user.FirstName } { user.LastName }</td>
                                    <td>{ user.Email }</td>
                                    <td className="text-center">
                                        <UserActions userId={ user.Id }></UserActions>
                                    </td>
                                </tr>
                                )
                            }

                            {
                                session.UsersStore.CRUD.Entries.length === 0 &&
                                <tr className="text-center">
                                    <td colSpan="3"><b className="text-muted">No users returned</b></td>
                                </tr>
                            }
                            </tbody>
                        }
                    </Table>
                    {
                        session.UsersStore.CRUD.GettingEntries === false && session.UsersStore.CRUD.Entries.length > 0 &&
                        <Paginator paginator={ session.UsersStore.Paginator }></Paginator>
                    }
                    <BusyIndicator text="Retrieving Users..." show={ session.UsersStore.CRUD.GettingEntries }></BusyIndicator>
                </Col>
            </Row>
            <Modal show={ detailsModalShown } title="Add New User" icon={ faUserPlus } onHide={ e => handleAddUserComplete(false)} >
                <UserDetails onEditComplete={ handleAddUserComplete }></UserDetails>
            </Modal>
        </Panel>
});

export default UsersIndex;