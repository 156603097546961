import APIBase from "./APIBase";



/**
 * @typedef CATSTransfersService
 * @type {CATSTransfersService}
 */
export default class CATSTransfersService extends APIBase {

    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "CATSTransfers", sessionStore);
    }

    /**
     * @returns { Promise<import("./APIBase").RequestResult<import("./APIBase").DownloadFile>> }
     */
    async GetTRFFileContents(batchId) {
        return this.Get(`GetTRFFileContents?batchId=${batchId}`);
    }
}