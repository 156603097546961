import { faDownload, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import ClickIcon from "../../Core/ClickIcon";
import BatchTransactionsModal from "../Batches/BatchTransactionsModal";
import { saveAs } from "file-saver"

export default function CATSTransferActions(props) {
	const store = useSessionStore();

	const [showTransactions, setShowTransactions] = useState(false);
	const [downloadingCATSFile, setDownloadingCATSFile] = useState(false);

	async function handleViewTransactionsClicked() {
        setShowTransactions(true)
    }

	async function handleProcessCATSTransferClicked() {
		setDownloadingCATSFile(true);

		var result = await store.CATSTransfersService.GetTRFFileContents(props.batch.Id);

		if(result) {
			if(result.Success) {
				var blob = new Blob([result.Data.FileContents], {type: result.Data.MimeType});
				saveAs(blob, result.Data.FileName);

				props.onCATSFileDownloaded(props.batch.Id);

			} else {
				store.ToastStore.ShowErrorToast("Failed to download CATS File: " + result.Message);
			}
		}

		setDownloadingCATSFile(false);
	}

	async function handleBatchTransactionsModalClose() {
		setShowTransactions(false);
		props.onTransactionsModalClosed();
	}

	if(downloadingCATSFile === false) {
		return 	<Row  className="d-flex justify-content-center">
					<Col lg="1">
						<ClickIcon icon={ faEdit } title="View Transactions" onClick={ handleViewTransactionsClicked }></ClickIcon>
					</Col>
					<Col lg="1">
						<ClickIcon icon={ faDownload } title="Process CATS Transfer" onClick={ handleProcessCATSTransferClicked }></ClickIcon>
					</Col>
					<BatchTransactionsModal allowDelete={ props.batch.PostedDate === null } onClose={ handleBatchTransactionsModalClose } show={ showTransactions } batchId={ props.batch.Id } batchNumber={props.batch.BatchNumber }></BatchTransactionsModal>
				</Row>
	} else {
		return 	<Row className="d-flex justify-content-center">
					<BusyIndicator sm show></BusyIndicator>
				</Row>
	}
}