import { Formik, Form as FormikForm } from "formik";
import { Form } from "react-bootstrap-formik";
import { DateTime } from "luxon";
import { useState } from "react";
import { Button, Row } from "react-bootstrap";
import * as yup from 'yup';
import { useSessionStore } from "../../../../Stores/SessionStore";
import Modal from "../../../Core/Modal";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { SaveReportFile } from "../../../../Stores/AdhocReportsStore";

export default function EndOfMonthModal(props) {
	const [processing, setProcessing] = useState(false);

	const store = useSessionStore();

	async function handleSubmit(data) {
		setProcessing(true);
		var result = await store.PeriodEndService.ProcessEndOfMonth(data.NewMonthEndDate)
		setProcessing(false);

		if(result) {
			if(result.Success) {
				store.ToastStore.ShowSuccessToast("Processing Month End Complete.");
				for(var i = 0; i < result.Data.Reports.length; i++) {
					SaveReportFile(result.Data.Reports[i]);
				}

				props.onClose(result.Data);
			} else {
				store.ToastStore.ShowErrorToast(result.Message);
			}
		}
	}

	function getNewMonthEndDate() {
		var currentMonthEnd = DateTime.fromISO(props.control.MonthEndDate);
		var nextMonth = currentMonthEnd.plus({ months: 1 });
		var endOfMonth = nextMonth.endOf("month")

		while(endOfMonth.weekday > 5) {
			endOfMonth = endOfMonth.minus({ days: 1 });
		}

		return endOfMonth.toFormat("yyyy-MM-dd");
	}

	const validationSchema =
        yup.object({
			NewMonthEndDate: yup.date().required("New Month End Date is required.")
        }).required();

    return 	<Modal icon={ faCalendarCheck }  show={ props.show } title="Process Month End" onHide={ e => props.onClose(null)}>
				<Formik initialValues={ { NewMonthEndDate: getNewMonthEndDate() } } validationSchema={ validationSchema } onSubmit={ handleSubmit }>
					<FormikForm>
						<Form.Input min={ DateTime.fromISO(props.control.MonthEndDate).toISODate() } type="date" label="New Month End Date" name="NewMonthEndDate" disabled={ processing } />
						<Row className="modal-footer pb-0">
							<Button disabled={ processing } type="submit">{ processing ? "Processing..." : "Process" }</Button>
							<Button onClick={ e => props.onClose(null) } className="ml-2" variant="danger" disabled={ processing } type="button">Cancel</Button>
						</Row>
					</FormikForm>
				</Formik>
    		</Modal>
}