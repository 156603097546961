import { Formik, Form as FormikForm } from "formik";
import { Form } from "react-bootstrap-formik";
import { DateTime } from "luxon";
import { useState } from "react";
import { Button, Row } from "react-bootstrap";
import * as yup from 'yup';
import { useSessionStore } from "../../../../Stores/SessionStore";
import Modal from "../../../Core/Modal";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { SaveReportFile } from "../../../../Stores/AdhocReportsStore";

export default function EndOfDayModal(props) {
	const [processing, setProcessing] = useState(false);

	const store = useSessionStore();

	async function handleSubmit(data) {
		setProcessing(true);
		var result = await store.PeriodEndService.ProcessEndOfDay(data.NewProcessingDate)
		setProcessing(false);

		if(result) {
			if(result.Success) {
				store.ToastStore.ShowSuccessToast("Processing Day End Complete.");
				for(var i = 0; i < result.Data.Reports.length; i++) {
					SaveReportFile(result.Data.Reports[i]);
				}

				props.onClose(result.Data);
			} else {
				store.ToastStore.ShowErrorToast(result.Message);
			}
		}
	}

	function getNewProcessingDate() {
		var currentDay = DateTime.fromISO(props.control.ProcessingDate);
		var nextDay = currentDay.plus({ days: 1 });

		while(nextDay.weekday > 5) {
			nextDay = nextDay.plus({ days: 1 });
		}

		return nextDay.toFormat("yyyy-MM-dd");
	}

	const validationSchema =
        yup.object({
			NewProcessingDate: yup.date().required("New Processing Date is required.")
        }).required();

    return 	<Modal icon={ faCalculator }  show={ props.show } title="Process Day End" onHide={ e => props.onClose(null)}>
				<Formik initialValues={ { NewProcessingDate: getNewProcessingDate() } } validationSchema={ validationSchema } onSubmit={ handleSubmit }>
					<FormikForm>
						<Form.Input min={ DateTime.fromISO(props.control.ProcessingDate).toISODate() } type="date" label="New Processing Date" name="NewProcessingDate" disabled={ processing } />
						<Row className="modal-footer pb-0">
							<Button disabled={ processing } type="submit">{ processing ? "Processing..." : "Process" }</Button>
							<Button onClick={ e => props.onClose(null) } className="ml-2" variant="danger" disabled={ processing } type="button">Cancel</Button>
						</Row>
					</FormikForm>
				</Formik>
    		</Modal>
}