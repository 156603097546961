import { faPlusCircle, faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import Paginator from "../../Core/Paginator";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import ActiveControl from "./ActiveControl";
import CreateControlModal from "./CreateControlModal";

export const ControlsIndex = observer(function ControlsIndex(props) {
    const store = useSessionStore();

    const [createModalShown, showCreateModal] = useState(false);

    useEffect(() =>
    {
        store.ControlsStore.GetControls();
    }, [store.ControlsStore]);

    function handleAddNewControlClicked() {
        showCreateModal(true);
    }

    function handleCreateControlComplete(success) {
        if(success) {
            store.ControlsStore.GetControls();
            store.ControlsStore.GetActiveControl();
        }

        showCreateModal(false);
    }

    return <div>
            <ActiveControl></ActiveControl>
            <Panel>

            <PanelHeader icon={ faClipboard } title="Controls History">
                <Button variant="outline-primary" onClick={ handleAddNewControlClicked }><FontAwesomeIcon icon={ faPlusCircle }></FontAwesomeIcon> Set Control</Button>
            </PanelHeader>
            <Row>
                <Col lg={12}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Created On</th>
                                <th>Created By</th>
                                <th>Bank Charges</th>
                                <th>Randage Rate</th>
                                <th>Death Benefit Fund Fee</th>
                                <th>Processing Date</th>
                                <th>Month End Date</th>
                                <th>Year End Date</th>
                            </tr>
                        </thead>
                        {
                            store.ControlsStore.GettingControls === false &&
                            <tbody>
                            {
                                store.ControlsStore.Controls.length > 0 &&
                                store.ControlsStore.Controls.map((control, index) =>
                                <tr key={ index }>
                                    <td>{ DateTime.fromISO(control.DateCreated).toFormat("yyyy-MM-dd") }</td>
                                    <td>{ control.Username }</td>
                                    <td>R{ control.BankCharges.toFixed(2) }</td>
                                    <td>{ control.RandageRate.toFixed(2) }%</td>
                                    <td>R{ control.DeathBenefitFundFee.toFixed(2) }</td>
                                    <td>{ DateTime.fromISO(control.ProcessingDate).toFormat("yyyy-MM-dd") }</td>
                                    <td>{ DateTime.fromISO(control.MonthEndDate).toFormat("yyyy-MM-dd") }</td>
                                    <td>{ DateTime.fromISO(control.YearEndDate).toFormat("yyyy-MM-dd") }</td>
                                </tr>
                                )
                            }

                            {
                                store.ControlsStore.Controls.length === 0 &&
                                <tr className="text-center">
                                    <td colSpan="8"><b className="text-muted">No controls returned</b></td>
                                </tr>
                            }
                            </tbody>
                        }
                    </Table>
                    {
                        store.ControlsStore.GettingControls === false && store.ControlsStore.Controls.length > 0 &&
                        <Paginator paginator={ store.ControlsStore.Paginator }></Paginator>
                    }
                    <BusyIndicator text="Retrieving Controls History..." show={ store.ControlsStore.GettingControls }></BusyIndicator>
                </Col>
            </Row>
            <CreateControlModal show={ createModalShown } onClose={ handleCreateControlComplete }></CreateControlModal>
        </Panel>
    </div>
});

export default ControlsIndex;