import { DateTime } from "luxon";
import { makeAutoObservable, runInAction } from "mobx";
import Paginator from "../Utilities/Paginator";

/**
 * @typedef ControlStore
 * @type { ControlStore }
 */
export default class ControlStore {
    /** @type { Array<import("../Services/ControlsService").Control> } */
    Controls = [];

    /** @type { import("../Services/ControlsService").Control } */
    ActiveControl = null;

    GettingActiveControl = false;
    GettingControls = false;
    CreatingControl = false;

    constructor(controlsService, toastStore) {
        /**@type { import("../Services/ControlsService").ControlsService } */
        this.ControlsService = controlsService;

        /**@type { import("../Stores/ToastStore").ToastStore } */
        this.ToastStore = toastStore;

        this.Paginator = new Paginator(async () => this.GetControls());

        makeAutoObservable(this);
    }

    /**
     * Retrieves the active control
     */
    async GetActiveControl() {
        this.GettingActiveControl = true;

        var result = await this.ControlsService.GetActiveControl();
        runInAction(() => this.GettingActiveControl = false );

        if(result)
        {
            if(result.Success) {
                runInAction(() => this.ActiveControl = result.Data );
            } else {
                this.ToastStore.ShowErrorToast(`Failed to retrieve the Active Control: ${result.Message}`);
            }
        }
    }

    async GetControls() {
        this.GettingControls = true;

        var result = await this.ControlsService.GetControls(this.Paginator.PageIndex, this.Paginator.PageCount);
        runInAction(() => this.GettingControls = false );

        if(result)
        {
            if(result.Success) {
                runInAction(() => {
                    this.Controls = result.Data ;
                    this.Paginator.TotalData = result.Total;
                });
            } else {
                this.ToastStore.ShowErrorToast(`Failed to retrieve Controls: ${result.Message}`);
            }
        }
    }

    /**
     * @param { import('../Services/ControlsService').Control } control
     * @returns { Promise<import("../Services/APIBase").RequestResult<boolean>>  } True if the create was successful, false if not
    */
    async CreateControl(control) {
        this.CreatingControl = true;

        var result = await this.ControlsService.CreateControl(control);
        runInAction(() => this.CreatingControl = false);

        if(result)
        {
            if(result.Success) {
                this.ToastStore.ShowSuccessToast(`Control set successfully`);
            } else {
                this.ToastStore.ShowErrorToast(`Failed to set the new Control: ${result.Message}`);
            }

            return result.Success;
        }

        return false;
    }

	/***
	 * Gets the period for the current control (if the control is not null)
	 */
	GetCurrentPeriod() {
		if(this.ActiveControl) {
			var today = new DateTime.fromISO(this.ActiveControl.ProcessingDate);
			var yearEnd = new DateTime.fromISO(this.ActiveControl.YearEndDate);
			var yearStart = yearEnd.minus({ months: 11 }).startOf("month");

			var currentDate = yearStart;
			var monthDiff = 0;

			while(currentDate <= today)
			{
				monthDiff += 1;
				currentDate = currentDate.plus({ months: 1 });
			}

			return `${yearEnd.year}${String(monthDiff).padStart(2, '0')}`;
		}

		return "";
	}
}