import { makeAutoObservable, runInAction } from "mobx";

export var DailyWithdrawalsSortColumns = {
	TransactionNumber: 0,
	Member: 1,
	TransactionDate: 2,
	Reference: 3,
	Amount: 4
}

export default class MemberWithdrawalsStore {
    RetrievingNewWithdrawalState = false;
    RetrievingUnbatchedWithdrawals = false;
    PostingDailyWithdrawals = false;
	SortColumn = DailyWithdrawalsSortColumns.TransactionNumber;
	SortDirection = 0;

    constructor(memberWithdrawalsService, toastStore) {
        /**@type { import("../Services/MemberWithdrawalsService").MemberWithdrawalsService } */
        this.MemberWithdrawalsService = memberWithdrawalsService;

        /**@type { import("../Stores/ToastStore").ToastStore } */
        this.ToastStore = toastStore;

        makeAutoObservable(this);
    }

    async GetUnpostedWithdrawals() {

    }

    /**
     * @param amount {number} The total amount for the withdrawal
     * @param withdrawalDate {Date} The date of the withdrawal
     * @param memberId {String} The Id of the member the withdrawal is for
     */
    async CreateWithdrawalForMember(amount, withdrawalDate, memberId) {
        var result = await this.MemberWithdrawalsService.CreateWithdrawalForMember(amount, withdrawalDate, memberId);

        if(result) {
            if(result.Success) {
                this.ToastStore.ShowSuccessToast("Withdrawal successfully created.", "New Withdrawal Created.");
            } else {
                this.ToastStore.ShowErrorToast("An error occurred creating the withdrawal: " + result.Message, "Error Creating Withdrawal");
            }

            return result.Success;
        }

        return false;
    }

    /**
     * @param memberId {string} The id of the member whose new withdrawal state should be retrieved
     * @returns { import("../Services/MemberWithdrawalsService").NewWithdrawalState }
     */
    async GetStateForNewMemberWithdrawal(memberId) {
        this.RetrievingNewWithdrawalState = true;

        var result = await this.MemberWithdrawalsService.GetStateForNewMemberWithdrawal(memberId);
        runInAction(() => this.RetrievingNewWithdrawalState = false);

        if(result) {
            if(result.Success) {
                //we need to remove the time component from the date value
                result.Data.CurrentDate = result.Data.CurrentDate.split("T")[0];

                return result.Data;
            } else {
                this.ToastStore.ShowErrorToast("An error occurred attempting to retrieve the state for a new withdrawal: " + result.Message, "Error Creating Withdrawal");
            }
        }

        return null;
    }

    /**
     * @returns { Promise<Array<import("../Services/MemberWithdrawalsService").PendingTransaction>> }
     */
    async GetUnbatchedWithdrawals() {
        this.RetrievingUnbatchedWithdrawals = true;

        var result = await this.MemberWithdrawalsService.GetUnbatchedWithdrawals(this.SortColumn, this.SortDirection);
        runInAction(() => this.RetrievingUnbatchedWithdrawals = false);

        if(result) {
            if(result.Success) {
                return result.Data;
            } else {
                this.ToastStore.ShowErrorToast("An error occurred attempting to retrieve daily withdrawals: " + result.Message, "Error Retrieving Withdrawals");
            }
        }

        return null;
    }

    async DeleteUnbatchedWithdrawal(transactionId) {
        var result = await this.MemberWithdrawalsService.DeleteUnbatchedWithdrawal(transactionId);

        if(result) {
            if(result.Success) {
                this.ToastStore.ShowSuccessToast("Withdrawal successfully deleted.", "Withdrawal Deleted.");
                return result.Data;
            } else {
                this.ToastStore.ShowErrorToast("An error occurred attempting to delete the withdrawal: " + result.Message, "Error Deleting Withdrawal");
            }
        }

        return false;
    }

    async PostDailyWithdrawals() {
        this.PostingDailyWithdrawals = true;

        var result = await this.MemberWithdrawalsService.PostDailyWithdrawals();
        runInAction(() => this.PostingDailyWithdrawals = false);

        if(result) {
            if(result.Success) {
                this.ToastStore.ShowSuccessToast("Withdrawals posted successfully.", "Withdrawals Posted.");
            } else {
                this.ToastStore.ShowErrorToast("An error occurred attempting to post the withdrawals: " + result.Message, "Error Posting Withdrawals");
            }

            return result.Success;
        }

        return false;
    }
}