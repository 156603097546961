import { Formik, Form as FormikForm } from "formik";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Row, Button, Accordion } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import * as yup from 'yup';
import { BankAccountTypes, MemberStatuses } from "../../../Stores/Members/MemberStore";
import PersonalDetails from "./MemberDetails/PersonalDetails";
import BankAccountDetails from "./MemberDetails/BankAccountDetails";
import MemberNotes from "./MemberDetails/MemberNotes";
import { DateTime } from "luxon";
import { useRef } from "react";
import BalanceSummary from "./MemberDetails/BalanceSummary";
import AccordianItem from "../../Core/AccordianItem";
import ConfirmationDialog from "../../Core/ConfirmationDialog";

export var MemberDetails = observer(function MemberDetails(props) {
    const store = useSessionStore();

    const bankAccountChanged = useRef(false);
    const formContentChanged = useRef(false);

    const [showingCloseConfirm, showCloseConfirm] = useState(false);
    const [member, setMember] = useState({
            ServiceNumber: "", Name: "", Surname: "", IdNumber: "", MemberStatusId: MemberStatuses.ActiveMember, FICACompliant: false,
            DepartmentId: "", TaxNumber: "", JoinDate: "",
            Address: { Line1: "", Line2: "", Line3: "", Line4: ""},
            BankAccount: { Payee: "", BankName: "", BranchCode: "", AccountNumber: "", BankAccountTypeId: BankAccountTypes.Cheque },
            MemberNotes: [] });

    useEffect(() => {
        async function getMember() {
            var member = await store.MembersStore.CRUD.GetEntry(props.memberId);

            if(member) {
                //the date picker component can't handle the time portion of dates, we need to remove it
                member.JoinDate = DateTime.fromISO(member.JoinDate).toFormat("yyyy-MM-dd");

				//since depts can be soft deleted, its possible, the user has a dept id, but no dept, reset dept id then
				member.DepartmentId = (member.Department ? member.DepartmentId : "");
                setMember(member)

            } else {
                props.onEditComplete(false)
            }
        }

        if(props.memberId) {
            getMember();
        }
    }, [props, store.MembersStore]);

    async function handleSubmit (data) {
        var result = false;

        if(member.Id) {
            result = await store.MembersStore.CRUD.UpdateEntry(data, bankAccountChanged.current);

			if(result && bankAccountChanged.current) {
				store.ToastStore.ShowWarningToast("An approval for a change in the member's bank account details has been submitted", "Bank Account Change Approval Submitted");
			}
        } else {
            result = await store.MembersStore.CRUD.CreateEntry(data);
        }

        if(result) {
            props.onEditComplete(true);
        }
    }

    function handleBankAccountChanged() {
        bankAccountChanged.current = true;
    }

    function handleFormContentChanged() {
        if(member.Id) {
            formContentChanged.current = true;
        }
    }

    function handleCancelClicked() {
        if(formContentChanged.current === true) {
            showCloseConfirm(true);
        } else {
            props.onEditComplete(false);
        }
    }

    const validationSchema =
        yup.object({
            MemberStatusId: yup.number().required("Member Status is required"),
            ServiceNumber: yup.number().typeError("Service Number is required.").required("Service Number is required."),
            IdNumber: yup.string().typeError("ID Number is required.").length(13, "ID Number must be 13 digits long.").required("ID Number is required."),
            Name: yup.string().typeError("Name is required.").required("Name is required."),
            Surname: yup.string().typeError("Surname is required.").required("Surname is required."),
            DepartmentId: yup.string().typeError("Department is required.").required("Department is required.") ,
            JoinDate: yup.date().typeError("Join Date is required.").required("Join Date is required."),
            Address: yup.object().when("MemberStatusId", {
                is: (MemberStatusId) => MemberStatusId === MemberStatuses.ActivePensioner,
                then: yup.object({ Line1: yup.mixed("Address Field is required.").required("Address Field is required.")})
            })}
        ).required();

	/**
	 * Returns the first error in the list of formik errors
	 */
	function getFirstFieldError(errors) {
		for(var error in errors) {
			if(error) {
				if(typeof errors[error] === "string") {
					return errors[error];
				} else if (typeof errors[error] === "object") {
					for(var embeddedError in errors[error]) {
						if(embeddedError) {							
							return errors[error][embeddedError];
						}
					}
				}
			}
		}
		return "";
 	}

	 console.log(store.MembersStore.CRUD.UpdatingEntry);

    return  <div>
                {
                    store.MembersStore.CRUD.GettingEntry === false &&
                    <Formik validationSchema={ validationSchema } enableReinitialize={true} initialValues={member} onSubmit={ handleSubmit }>
                        {({ setFieldValue, errors, values }) => (
                            <FormikForm onChange={ handleFormContentChanged }>
                                {
                                    showingCloseConfirm === false &&
                                    <div>
                                        {
                                            props.memberId &&
                                            <BalanceSummary memberId={props.memberId}></BalanceSummary>
                                        }
                                        <Accordion defaultActiveKey="0" className="mb-3">
                                            <AccordianItem eventKey="0" title="Personal Details">
                                                <PersonalDetails member={member} setFieldValue={ setFieldValue } errors={ errors } ></PersonalDetails>
                                            </AccordianItem>
                                            <AccordianItem eventKey="1" title="Bank Details">
                                                <BankAccountDetails member={values} setFieldValue={ setFieldValue } onBankAccountChanged={ handleBankAccountChanged }></BankAccountDetails>
                                            </AccordianItem>
                                            <AccordianItem eventKey="2" title="Notes">
                                                <MemberNotes member={ values }></MemberNotes>
                                            </AccordianItem>
                                        </Accordion>
                                        <Row className="modal-footer pb-0">
											{
												errors &&
												<div className="text-danger">{getFirstFieldError(errors)}</div>
											}
                                            <Button disabled={ store.MembersStore.CRUD.UpdatingEntry } type="submit">{ store.MembersStore.CRUD.UpdatingEntry ? "Saving..." : "Save" }</Button>
                                            <Button onClick={ handleCancelClicked } className="ml-2" variant="danger" disabled={ store.MembersStore.CRUD.UpdatingEntry } type="button">Cancel</Button>
                                        </Row>
                                    </div>
                                }
                                {
                                    showingCloseConfirm &&
									<ConfirmationDialog onResult={ (e) => e ? props.onEditComplete(false) : showCloseConfirm(false) } text="Are you sure you want to cancel? All unsaved changes will be lost."></ConfirmationDialog>
                                }
                            </FormikForm>
                        )}

                    </Formik>
                }
                <BusyIndicator show={ store.MembersStore.CRUD.GettingEntry } text="Retrieving Member..."></BusyIndicator>
            </div>
});

export default MemberDetails