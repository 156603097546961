import { DateTime } from "luxon";
import { useState } from "react";
import ConfirmationModal from "../../../Core/ConfirmationModal";
import PeriodEndButton from "../PeriodEndButton";
import EndOfDayModal from "./EndOfDayModal";

export default function EndOfDay(props) {
	const [showError, setShowError] = useState(false);
	const [showProcessingModal, setShowProcessingModal] = useState(false);
	const [showMonthEndModal, setShowMonthEndModal] = useState(false);

	/**@type {import("../../../../Services/ControlsService").Control} */
	const control = props.control;
	const processingDate = DateTime.fromISO(control.ProcessingDate);

	function handleEndOfDayClicked() {
		if(props.error) {
			setShowError(true);
		} else {
			setShowProcessingModal(true);
		}
	}

	/**
	 * @param { import("../../../../Services/PeriodEndService").PeriodEndResult } result
	 */
	function handleProcessingComplete(result) {
		setShowProcessingModal(false);

		if(result) {
			if(result.MustProcessNextPeriod) {
				setShowMonthEndModal(true);
			} else {
				props.onProcessingComplete();
			}
		}
	}

	function isDisabled() {
		//end of day is disabled if the processing date is after month end or year end
		const monthEndDate = DateTime.fromISO(control.MonthEndDate);
		const yearEndDate = DateTime.fromISO(control.YearEndDate);

		var afterMonthEnd = (processingDate.diff(monthEndDate, "days").days > 0);
		var afterYearEnd = (processingDate.diff(yearEndDate, "days").days > 0);

		return afterMonthEnd || afterYearEnd;
	}

	return 	<div>
				<PeriodEndButton disabled={ isDisabled() } onClick={ handleEndOfDayClicked } date={ <span>{ processingDate.day }<br/>{ processingDate.monthShort.toUpperCase() }</span> } text="Day End" offset="38%">
				</PeriodEndButton>
				<EndOfDayModal show={ showProcessingModal } control={ props.control } onClose={ handleProcessingComplete }></EndOfDayModal>
				<ConfirmationModal hideCancel onResponse={ e => setShowError(false) } message={props.error} show={ showError } title="Day End"></ConfirmationModal>
				<ConfirmationModal hideCancel onResponse={ e => { setShowMonthEndModal(false); props.onProcessingComplete() } } message="Processing Day End Completed. Month End must now be processed." show={ showMonthEndModal } title="Day End"></ConfirmationModal>
			</div>
}