import { faBuilding, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import Modal from "../../Core/Modal";
import Paginator from "../../Core/Paginator";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import SearchBox from "../../Core/SearchBox";
import DepartmentActions from "./DepartmentActions";
import { DepartmentDetails } from "./DepartmentDetails";

export const DepartmentsIndex = observer(function DepartmentsIndex() {
    const session = useSessionStore();
    const [detailsModalShown, showDetailsModal] = useState(false);

    useEffect(() =>
    {
        session.DepartmentsStore.CRUD.GetEntries();
    }, [session.DepartmentsStore]);

    function handleAddNewDepartmentClicked() {
        showDetailsModal(true);
    }

    function handleDepartmentAddComplete(success) {
        //just refresh departments and then close the modal
        if(success) {
            session.DepartmentsStore.CRUD.GetEntries();
        }

        showDetailsModal(false);
    }

    return <Panel>
            <PanelHeader icon={ faBuilding } title="Departments">
                <Button variant="outline-primary" onClick={ handleAddNewDepartmentClicked }><FontAwesomeIcon icon={ faPlusCircle }></FontAwesomeIcon> Add Department</Button>
            </PanelHeader>
            <Form className="mb-3 d-flex">
                <SearchBox action={ e => session.DepartmentsStore.CRUD.GetEntries() } placeholder="Search Departments..." value={ session.DepartmentsStore.Search } onChange={ e => session.DepartmentsStore.Search = e.target.value }></SearchBox>
                <Button onClick={ e => session.DepartmentsStore.CRUD.GetEntries() }>Apply</Button>
            </Form>
            <Row>
                <Col lg={12}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Name</th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        {
                            session.DepartmentsStore.CRUD.GettingEntries === false &&
                            <tbody>
                            {
                                session.DepartmentsStore.CRUD.Entries.length > 0 &&
                                session.DepartmentsStore.CRUD.Entries.map((department) =>
                                <tr key={ department.Id }>
                                    <td>{ department.Code }</td>
                                    <td>{ department.Name }</td>
                                    <td className="text-center">
                                        <DepartmentActions departmentId={ department.Id }></DepartmentActions>
                                    </td>
                                </tr>
                                )
                            }

                            {
                                session.DepartmentsStore.CRUD.Entries.length === 0 &&
                                <tr className="text-center">
                                    <td colSpan="3"><b className="text-muted">No Departments returned</b></td>
                                </tr>
                            }
                            </tbody>
                        }
                    </Table>
                    {
                        session.DepartmentsStore.CRUD.GettingEntries === false && session.DepartmentsStore.CRUD.Entries.length > 0 &&
                        <Paginator paginator={ session.DepartmentsStore.Paginator }></Paginator>
                    }
                    <BusyIndicator text="Retrieving Departments..." show={ session.DepartmentsStore.CRUD.GettingEntries }></BusyIndicator>
                </Col>
            </Row>
            <Modal show={ detailsModalShown } title={ "Add Department" } onHide={ e => handleDepartmentAddComplete(false)} >
                <DepartmentDetails onEditComplete={ e => handleDepartmentAddComplete(true) }></DepartmentDetails>
            </Modal>
        </Panel>
});

export default DepartmentsIndex;