import APIBase from "./APIBase";

/**
 * @typedef AdhocReportsService
 * @type {AdhocReportsService}
 */
export default class AdhocReportsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "AdhocReports", sessionStore);
    }

    /**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetAllBalanceBandsReport(format) {
        return this.Get(`GetAllBalanceBandsReport&format=${format}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetSmallBalanceBandsReport(format) {
        return this.Get(`GetSmallBalanceBandsReport&format=${format}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetFICACompliantMembersReport() {
        return this.Get(`GetFICACompliantMembersReport`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetFICANonCompliantMembersReport() {
        return this.Get(`GetFICANonCompliantMembersReport`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetTransactionsAuditTrailReport(date) {
        return this.Get(`GetTransactionsAuditTrailReport?date=${encodeURIComponent(date)}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetDepartmentStrengthReport() {
        return this.Get(`GetDepartmentStrengthReport`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetDepartmentListingReport() {
        return this.Get(`GetDepartmentListingReport`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetEstateLateReport() {
        return this.Get(`GetEstateLateReport`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetTerminatedMemberBalancesReport() {
        return this.Get(`GetTerminatedMemberBalancesReport`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetNoMovementBalanceMoreThanR100NotActiveReport() {
        return this.Get(`GetNoMovementBalanceMoreThanR100NotActiveReport`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetNoMovementBalanceLessThanR100ActiveReport() {
        return this.Get(`GetNoMovementBalanceLessThanR100ActiveReport`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetNoMovementBalanceMoreThanR3ActiveReport() {
        return this.Get(`GetNoMovementBalanceMoreThanR3ActiveReport`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetMembersLessThanR3ActiveReport() {
        return this.Get(`GetMembersLessThanR3ActiveReport`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetMembersLessThanR0ActiveReport() {
        return this.Get(`GetMembersLessThanR0ActiveReport`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetMembersMoreThanSpecifiedNotTerminatedReport(amount) {
        return this.Get(`GetMembersMoreThanSpecifiedNotTerminatedReport?amount=${encodeURIComponent(amount)}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetMonthlyWithdrawalCountReport(from, to) {
        return this.Get(`GetMonthlyWithdrawalCountReport?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetDailyWithdrawalCountsForPeriodReport(period) {
        return this.Get(`GetDailyWithdrawalCountsForPeriodReport?period=${encodeURIComponent(period)}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetPensionersWithNoAddressReport() {
        return this.Get(`GetPensionersWithNoAddressReport`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetDuplicateBankingDetailsReport() {
        return this.Get(`GetDuplicateBankingDetailsReport`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetMemberRecordReport(memberId) {
        return this.Get(`GetMemberRecordReport?memberId=${memberId}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetMemberStatementReport(memberId) {
        return this.Get(`GetMemberStatementReport?memberId=${memberId}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
	async GetMemberStatementReportForPreviousYear(memberId) {
        return this.Get(`GetMemberStatementReportForPreviousYear?memberId=${memberId}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<import("./APIBase").DownloadFile> }
     */
     async GetBankChangeAuditReport(from, to) {
        return this.Get(`GetBankChangeAuditReport?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`);
    }
}