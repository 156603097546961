import { Formik, Form as FormikForm, Field } from "formik";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Col, FormGroup, Row } from "react-bootstrap";
import { Form } from "react-bootstrap-formik";
import { useSessionStore } from "../../../Stores/SessionStore";
import Modal from "../../Core/Modal";
import * as yup from 'yup';
import BusyIndicator from "../../Core/BusyIndicator";
import InfoLabel from "../../Core/InfoLabel";

export var TerminateMemberModal = observer(function TerminateMemberModal(props) {
    const store = useSessionStore();
    const [showingTerminateConfirm, showTerminateConfirm] = useState(false);
    const [terminationDetails, setTerminationDetails] = useState({ TerminationDate: DateTime.now().toFormat("yyyy-MM-dd"), PayInterest: false, Interest: 0.0 })

    function handleSubmit() {
        showTerminateConfirm(true);
    }

    async function handleOKClicked(values) {
        showTerminateConfirm(false);
        var result = await store.MembersStore.TerminateMember(props.memberId, values.TerminationDate, values.PayInterest, values.Interest);

        if(result) {
            props.onClose(true);
        }
    }

    async function getTerminationInterest (date, payInterest) {
        var interest = await store.MembersStore.GetMemberTerminationInterest(props.memberId, date);

        if(interest === null) {
            props.onClose(false);
        } else {
            setTerminationDetails({ Interest: parseFloat(interest.toFixed(2)), PayInterest: payInterest, TerminationDate: date });
        }
    }

    useEffect(() => {
		async function initialise() {
			if(props.show) {
				if(store.ControlsStore.ActiveControl === null) {
					await store.ControlsStore.GetActiveControl();
				}

				var date = DateTime.fromISO(store.ControlsStore.ActiveControl.ProcessingDate).toFormat("yyyy-MM-dd");

				getTerminationInterest(date, false);
			} else {
				setTerminationDetails({ Interest: 0.00, PayInterest: false, TerminationDate: date });
			}
		}

        initialise();

    }, [props.memberId, props.show])

    const validationSchema =
        yup.object({
            TerminationDate: yup.date().required("Termination Date is required."),
            Interest: yup.number().required("Interest is required.")
        }).required();

    return <Modal show={props.show} title="Terminate Member">
        <Formik validationSchema={ validationSchema } initialValues={terminationDetails} enableReinitialize onSubmit={ handleSubmit }>
            {({ values }) => (
                <FormikForm>
                    {
                        showingTerminateConfirm === false &&
                        <div>
							<Row className="mb-3">
								<Col>
									<InfoLabel invert label="Member Name" text={ props.member.Name }></InfoLabel>
								</Col>
								<Col>
									<InfoLabel invert label="Service Number (SNO)" text={ props.member.ServiceNumber }></InfoLabel>
								</Col>
							</Row>
                            <Form.Input onChange={ (e) => getTerminationInterest(e.target.value, values.PayInterest) } disabled={ store.MembersStore.GettingMemberTerminationInterest } label="Termination Date" name="TerminationDate" type="date"></Form.Input>
                            <hr></hr>
                            <InfoLabel label="Interest" text={`R ${values.Interest.toFixed(2)}`}></InfoLabel>
                            <FormGroup className="d-flex align-items-center mt-3">
                                <Field type="checkbox" name="PayInterest"></Field>
                                <label className="ml-2 mb-0"><span> Pay Interest</span></label>
                            </FormGroup>
                            <Row className="modal-footer pb-0">
                                <div className="position-absolute" style={{ left: "15px" }}>
                                    <BusyIndicator sm text="Retrieving Member Interest..." show={ store.MembersStore.GettingMemberTerminationInterest }></BusyIndicator>
                                </div>
                                <Button disabled={ store.MembersStore.TerminatingMember || store.MembersStore.GettingMemberTerminationInterest } type="submit">{ store.MembersStore.TerminatingMember ? "Terminating..." : "Accept" }</Button>
                                <Button onClick={ e => props.onClose(false) } className="ml-2" variant="danger" disabled={ store.MembersStore.TerminatingMember } type="button">Cancel</Button>
                            </Row>
                        </div>
                    }
                    {
                        showingTerminateConfirm &&
                        <div>
							{
								values.PayInterest &&
                            	<h6>{ `Accept termination with ${values.Interest} interest?` } </h6>
							}
							{
								values.PayInterest === false &&
                            	<h6>{ `Accept termination of member?` } </h6>
							}
                            <Row>
                                <Col className="d-flex justify-content-end">
                                    <Button onClick={ e => handleOKClicked(values) }>Yes</Button>
                                    <Button onClick={ e => showTerminateConfirm(false) } variant="secondary" className="ml-2">No</Button>
                                </Col>
                            </Row>
                        </div>
                    }
                </FormikForm>)
            }
        </Formik>
    </Modal>
});

export default TerminateMemberModal;