import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal as BootstrapModal } from "react-bootstrap"

export default function Modal (props) {
    return <BootstrapModal {...props} title="" backdrop="static">
        <BootstrapModal.Header closeButton={ props.closeButton }>
            <BootstrapModal.Title>                
                <h4 className="mb-0">{ props.icon && <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon> } { props.title }</h4>
            </BootstrapModal.Title>
        </BootstrapModal.Header>            
        <BootstrapModal.Body>
            {
                props.children
            }
        </BootstrapModal.Body>
        {
            props.footer &&
            <BootstrapModal.Footer>
            {
                props.footer
            }
            </BootstrapModal.Footer>
        }
        
    </BootstrapModal>

}