import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useSessionStore } from "../../../../Stores/SessionStore";
import BusyIndicator from "../../../Core/BusyIndicator";
import ClickIcon from "../../../Core/ClickIcon";

var MemberTransactionActions = observer(function MemberTransactionActions(props) {
    var store = useSessionStore();

    const [reversingTransaction, setReversingTransaction] = useState(false);

    async function handleReverseTransactionClicked() {
        setReversingTransaction(true);
        var result = await store.TransactionsStore.ReverseTransaction(props.transactionId);
        setReversingTransaction(false);
        
        if(result) {
            props.onTransactionReversed();
        }
    }

    return <div>
        {
            (reversingTransaction === false) && 
            <ClickIcon title="Reverse Transaction" onClick={ handleReverseTransactionClicked } icon={ faHistory }></ClickIcon>
        }      
        <BusyIndicator sm show={ reversingTransaction }></BusyIndicator>
    </div>
});

export default MemberTransactionActions;