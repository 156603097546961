import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import {  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Row } from "react-bootstrap";

export default function PeriodEndButton(props) {
	function handleCardClicked() {
		if(!props.disabled) {
			props.onClick();
		}
	}

	return 	<Card className={"shadow border-0 m-3 rounded " + (props.disabled ? "text-muted" : "")} style={{ cursor: props.disabled ? "default" : "pointer", width: "200px", height: "200px" }} onClick={ handleCardClicked }>
				<Card.Body className="text-center d-flex flex-column justify-content-around">
					<Row>
						<Col>
							<FontAwesomeIcon size="8x" icon={ faCalendar }></FontAwesomeIcon>
							<span className="position-absolute w-100 h-100" style={{ left: "0", top: props.offset }}>
								<span className="font-weight-bold align-middle" style={{ fontSize: "18px" }}>{props.date}</span>
							</span>
						</Col>
					</Row>
					<Row>
						<Col>
							<Card.Title className="m-0 p-0">{props.text}</Card.Title>
						</Col>
					</Row>
				</Card.Body>
			</Card>
}