import { faEdit, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import ClickIcon from "../../Core/ClickIcon";
import ConfirmationModal from "../../Core/ConfirmationModal";
import Modal from "../../Core/Modal";
import UserDetails from "./UserDetails";

var UserActions = observer(function UserActions(props) {
    const [deletingUser, setDeletingUser] = useState(false);
    const [detailsModalShown, showDetailsModal] = useState(false);
    const [confirmDeleteModalShown, showDeleteConfirmModal] = useState(false);

    const session = useSessionStore();

    function handleUserEditComplete(success) {
        //just refresh users and then close the modal  
        if(success) {      
            session.UsersStore.CRUD.GetEntries();
        }

        showDetailsModal(false);
    }

    function handleEditUserClicked() {
        showDetailsModal(true);
    }

    function handleDeleteUserClicked() {
        showDeleteConfirmModal(true);
    }

    async function handleDeleteResponse(response) {
        showDeleteConfirmModal(false);
                
        if(response) {
            setDeletingUser(true);
            var result = await session.UsersStore.CRUD.DeleteEntry(props.userId)   
            setDeletingUser(false);
            
            if(result) {
                session.UsersStore.CRUD.GetEntries();
            }
        }
    }

    return <div>
                {
                    deletingUser === false && 
                    <Row className="d-flex justify-content-center">
                        <Col lg="1">
                            <ClickIcon icon={ faEdit } title="Edit User" onClick={ handleEditUserClicked }></ClickIcon>
                        </Col>
                        <Col lg="1">
                            <ClickIcon icon={ faTrash } title="Delete User" onClick={ handleDeleteUserClicked }></ClickIcon>
                        </Col>
                    </Row>
                }
                <BusyIndicator sm show={deletingUser }></BusyIndicator>
            
            <Modal show={ detailsModalShown } icon={ faUserEdit } title="Edit User" onHide={ e => handleUserEditComplete(false)} >
                <UserDetails userId={ props.userId } onEditComplete={ handleUserEditComplete }></UserDetails>
            </Modal>
            <ConfirmationModal onResponse={ handleDeleteResponse } show={ confirmDeleteModalShown } title="Delete User" message="Are you sure you want to delete this User?"></ConfirmationModal>
    </div>
});

export default UserActions;