import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Row, InputGroup } from "react-bootstrap";
import { Formik, Form as FormikForm, Field } from "formik";
import * as yup from 'yup';
import Modal from "../../Core/Modal";
import { Form as BootstrapForm } from "react-bootstrap"
import NumberField from "../../Core/NumberField";

export default function ReportAmountFilterModal({ show, reportName, onClose }) {
	function okClicked(data) {
		onClose(data);
	}

	const validationSchema =
		yup.object({
			Amount: yup.number().required("Amount is required.")
		}).required();

    return 	<Modal show={ show } title={"Report Filter - " + reportName } icon={ faFileAlt }>
				<Formik validationSchema={ validationSchema } initialValues={{ Amount: 0.00 }} onSubmit={ okClicked }>
					{
						({ errors }) =>
						<FormikForm>
							<Row className="mt-3">
								<Col>
									<BootstrapForm.Group>
										<BootstrapForm.Label>Amount</BootstrapForm.Label>
										<InputGroup>
											<InputGroup.Text className="border-right-0">R</InputGroup.Text>
											<Field className={ "form-control " + (errors.Amount ? "is-invalid" : "") } component={ NumberField } name="Amount" />
										</InputGroup>
										{
											<small className="text-danger pb-3">{ errors.Amount }</small>
										}
									</BootstrapForm.Group>
								</Col>
							</Row>
							<Row className="modal-footer pb-0">
								<Button type="submit">OK</Button>
								<Button variant="danger" onClick={ () => onClose(null) }>Cancel</Button>
							</Row>
						</FormikForm>
					}
				</Formik>
    		</Modal>
}