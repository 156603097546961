import { observer } from "mobx-react-lite";
import { Alert, Toast } from "react-bootstrap"
import { useSessionStore } from "../../Stores/SessionStore";
import { ToastTypes } from "../../Stores/ToastStore";

export var Toastbar = observer(function Toastbar(props) {
    var store = useSessionStore();

    /**@param { import('../../Stores/ToastStore').Toast } toast */
    function getAlertVariant(toast) {
        switch (toast.Type) {
            case ToastTypes.Error:
                return "danger";
            case ToastTypes.Success:
                return "success";
            case ToastTypes.Warning:
                return "warning";
            default:
                return "info";
        }
    }

    return <div style={{ position: 'absolute', bottom: 15, right: 15, zIndex: 1050 }}>
        {
            store.ToastStore.Toasts.map((toast, index) =>
            <Toast key={index} onClose={() => store.ToastStore.HideToast(toast)} onClick={ () => store.ToastStore.HideToast(toast) } show={toast.Show} delay={20000} autohide>
                <Alert variant={getAlertVariant(toast)} className="mb-0">
                    <h5>{ toast.Title }</h5>
                    <p>{ toast.Message }</p>
                </Alert>
            </Toast>
            )
        }
    </div>
});

export default Toastbar;