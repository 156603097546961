import APIBase from "./APIBase";

/**
 * @typedef DataChangeApproval
 * @property {string} Id      
 * @property {number} DataChangeTypeId
 * @property {string} DataFrom
 * @property {string} DataTo
 * @property {Date} DateCreated
 * @property {string} RequestingUser
 * @property {string} RejectionReason
 */

/**
 * @typedef DataChangeApprovalsService
 * @type {DataChangeApprovalsService}
 */
export default class DataChangeApprovalsService extends APIBase {

    /**
     * @param {string} apiUrl 
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "DataChangeApprovals", sessionStore);
    }
    
    /**
     * @param {string} approvalId The id of the approval request to approve
     * @returns { Promise<import("./APIBase").RequestResult<boolean>> }
     */
    async Approve(approvalId) {
        return this.Post(`Approve?approvalId=${approvalId}`, null);
    }

    /**
     * @param {string} approvalId The id of the approval request to reject
     * @param {string} reason The rejection reason
     * @returns { Promise<import("./APIBase").RequestResult<boolean>> }
     */
     async Reject(approvalId, reason) {
        return this.Post(`Reject?approvalId=${approvalId}&reason=${encodeURIComponent(reason)}`, null);
    }

    /**
     * @param {number} startIndex The 0 based start page index
     * @param {number} pageCount The total amount of results to return for the page
     * @returns { import("./APIBase").EnumerableRequestResult<DataChangeApproval> }
     */
    async GetPendingApprovals(startIndex, pageCount) {
        return this.Get(`GetPendingApprovals?startIndex=${startIndex}&pageCount=${pageCount}`);
    }
    
    /**
     * @param {string} approvalId The Id of the data change approval to retrieve
     * @returns { Promise<import("./APIBase").RequestResult<DataChangeApproval>> }
     */
    async GetApproval(approvalId) {
        return this.Get(`GetApproval?approvalId=${approvalId}`);
    }
}