import { makeAutoObservable, runInAction } from "mobx";
import { saveAs } from "file-saver"

export function SaveReportFile(downloadFile) {
	const byteString = window.atob(downloadFile.FileContents);
	const arrayBuffer = new ArrayBuffer(byteString.length);
	const int8Array = new Uint8Array(arrayBuffer);
	for (let i = 0; i < byteString.length; i++) {
		int8Array[i] = byteString.charCodeAt(i);
	}

	var blob = new Blob([int8Array], {type: downloadFile.MimeType});
	saveAs(blob, downloadFile.FileName);
}

export default class AdhocReportsStore {
	DownloadingFICACompliantMembersReport = false;
	DownloadingFICANonCompliantMembersReport = false;
	DownloadingRetrievalOfAuditTrailsReport = false;
	DownloadingDepartmentStrengthReport = false;
	DownloadingEstateLateReport = false;
	DownloadingTerminatedMemberBalancesReport = false;
	DownloadingNoMovementBalanceMoreThanR100NotActiveReport = false;
	DownloadingNoMovementBalanceLessThanR100ActiveReport = false;
	DownloadingNoMovementBalanceMoreThanR3ActiveReport = false;
	DownloadingMembersLessThanR3ActiveReport = false;
	DownloadingMembersLessThanR0ActiveReport = false;
	DownloadingMonthlyWithdrawalCountReport = false;
	DownloadingDailyWithdrawalCountForPeriodReport = false;
	DownloadingMembersMoreThanSpecifiedNotTerminatedReport = false;
	DownloadingDepartmentListingReport = false;
	DownloadingPensionersWithNoAddressReport = false;
	DownloadingDuplicateBankingDetailsReport = false;
	DownloadingBankChangeAuditReport = false;

	/**
	 * @param {import("../Services/AdhocReportsService").AdhocReportsService} adhocReportsService
	 * @param { import("../Stores/ToastStore").ToastStore } toastStore
	 */
	constructor(adhocReportsService, toastStore) {
		this.AdhocReportsService = adhocReportsService;
		this.ToastStore = toastStore;

		makeAutoObservable(this);
	}


	/**
	 * @param { (boolean) => void } setBusyCallback function to call to set the busy indicator
	 * @param { () => Promise<import("../Services/APIBase").RequestResult<import("../Services/APIBase").DownloadFile>> } reportDownloadCallback The function to call to download the report
	 */
	async DownloadReport(setBusyCallback, reportDownloadCallback) {
		setBusyCallback(true);
		const result = await reportDownloadCallback();

		if(result) {
			runInAction(() => {
				setBusyCallback(false);
			})

			if(result.Success) {
				SaveReportFile(result.Data);

			} else {
				this.ToastStore.ShowErrorToast("An error ocurred downloading the report: " + result.Message);
			}
		}
	}

	async DownloadFICACompliantMembersReport() {
		this.DownloadReport((busy) => this.DownloadingFICACompliantMembersReport = busy,
		() => {
			return this.AdhocReportsService.GetFICACompliantMembersReport();
		});
	}

	async DownloadFICANonCompliantMembersReport() {
		this.DownloadReport((busy) => this.DownloadingFICANonCompliantMembersReport = busy,
		() => {
			return this.AdhocReportsService.GetFICANonCompliantMembersReport();
		});
	}

	async DownloadRetrievalOfAuditTrailsReport(date) {
		this.DownloadReport((busy) => this.DownloadingRetrievalOfAuditTrailsReport = busy,
		 () => {
			 return this.AdhocReportsService.GetTransactionsAuditTrailReport(date);
		 });
	}

	async DownloadDepartmentStrengthReport() {
		this.DownloadReport((busy) => this.DownloadingDepartmentStrengthReport = busy,
		 () => {
			 return this.AdhocReportsService.GetDepartmentStrengthReport();
		 });
	}

	async DownloadDepartmentListingReport() {
		this.DownloadReport((busy) => this.DownloadingDepartmentListingReport = busy,
		 () => {
			 return this.AdhocReportsService.GetDepartmentListingReport();
		 });
	}

	async DownloadEstateLateReport() {
		this.DownloadReport((busy) => this.DownloadingEstateLateReport = busy,
		 () => {
			 return this.AdhocReportsService.GetEstateLateReport();
		 });
	}

	async DownloadTerminatedMemberBalancesReport() {
		this.DownloadReport((busy) => this.DownloadingTerminatedMemberBalancesReport = busy,
		 () => {
			 return this.AdhocReportsService.GetTerminatedMemberBalancesReport();
		 });
	}

	async DownloadNoMovementBalanceMoreThanR100NotActiveReport() {
		this.DownloadReport((busy) => this.DownloadingNoMovementBalanceMoreThanR100NotActiveReport = busy,
		 () => {
			 return this.AdhocReportsService.GetNoMovementBalanceMoreThanR100NotActiveReport();
		 });
	}

	async DownloadNoMovementBalanceLessThanR100ActiveReport() {
		this.DownloadReport((busy) => this.DownloadingNoMovementBalanceLessThanR100ActiveReport = busy,
		 () => {
			 return this.AdhocReportsService.GetNoMovementBalanceLessThanR100ActiveReport();
		 });
	}

	async DownloadNoMovementBalanceMoreThanR3ActiveReport() {
		this.DownloadReport((busy) => this.DownloadingNoMovementBalanceMoreThanR3ActiveReport = busy,
		 () => {
			 return this.AdhocReportsService.GetNoMovementBalanceMoreThanR3ActiveReport();
		 });
	}

	async DownloadMembersLessThanR3ActiveReport() {
		this.DownloadReport((busy) => this.DownloadingMembersLessThanR3ActiveReport = busy,
		 () => {
			 return this.AdhocReportsService.GetMembersLessThanR3ActiveReport();
		 });
	}

	async DownloadMembersLessThanR0ActiveReport() {
		this.DownloadReport((busy) => this.DownloadingMembersLessThanR0ActiveReport = busy,
		 () => {
			 return this.AdhocReportsService.GetMembersLessThanR0ActiveReport();
		 });
	}

	async DownloadMembersMoreThanSpecifiedNotTerminatedReport(amount) {
		this.DownloadReport((busy) => this.DownloadingMembersMoreThanSpecifiedNotTerminatedReport = busy,
		 () => {
			 return this.AdhocReportsService.GetMembersMoreThanSpecifiedNotTerminatedReport(amount);
		 });
	}

	async DownloadMonthlyWithdrawalCountReport(from, to) {
		this.DownloadReport((busy) => this.DownloadingMonthlyWithdrawalCountReport = busy,
		 () => {
			 return this.AdhocReportsService.GetMonthlyWithdrawalCountReport(from, to);
		 });
	}

	async DownloadDailyWithdrawalCountForPeriodReport(period) {
		this.DownloadReport((busy) => this.DownloadingDailyWithdrawalCountForPeriodReport = busy,
		 () => {
			 return this.AdhocReportsService.GetDailyWithdrawalCountsForPeriodReport(period);
		 });
	}

	async DownloadPensionersWithNoAddressReport() {
		this.DownloadReport((busy) => this.DownloadingPensionersWithNoAddressReport = busy,
		 () => {
			 return this.AdhocReportsService.GetPensionersWithNoAddressReport();
		 });
	}

	async DownloadDuplicateBankingDetailsReport() {
		this.DownloadReport((busy) => this.DownloadingDuplicateBankingDetailsReport = busy,
		 () => {
			 return this.AdhocReportsService.GetDuplicateBankingDetailsReport();
		 });
	}

	async DownloadBankChangeAuditReport(from, to) {
		this.DownloadReport((busy) => this.DownloadingBankChangeAuditReport = busy,
		 () => {
			 return this.AdhocReportsService.GetBankChangeAuditReport(from, to);
		 });
	}
}