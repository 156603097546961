import { faBookDead } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, InputGroup, Row } from "react-bootstrap";
import Modal from "../../Core/Modal";
import InfoLabel from "../../Core/InfoLabel"
import { Form as BootstrapForm } from "react-bootstrap"
import { Formik, Form as FormikForm, Field } from "formik";
import { useSessionStore } from "../../../Stores/SessionStore";
import NumberField from "../../Core/NumberField";
import * as yup from 'yup';
import { useState } from "react";
import { BankAccountTypes } from "../../../Stores/Members/MemberStore";
import { Form } from "react-bootstrap-formik";

export default function DeathBenefitPayoutModal(props) {
    const store = useSessionStore();
    const [creatingDeathBenefitPayout, setCreatingDeathBenefitPayout] = useState(false);

    async function savePayout(data) {
        setCreatingDeathBenefitPayout(true);
        var result = await store.TransactionsService.CreateDeathBenefitPayout(props.member.Id, data.Amount, data.BankAccount);
		setCreatingDeathBenefitPayout(false);

        if(result) {
			if(result.Success) {
				store.ToastStore.ShowSuccessToast("Death Benefit Payout Created Successfully");
				props.onClose(true);
			} else {
				store.ToastStore.ShowErrorToast("An error ocurred creating the Death Benefit Payout: " + result.Message);
			}
        }
    }

    const validationSchema =
        yup.object({
            Amount: yup.number().required("Amount is required.").min(0.01, "Amount must be greater than 0"),
			BankAccount: yup.object({
				Payee: yup.string().required("Payee is required."),
				BankName : yup.string().required("Bank Name is required."),
				BranchCode: yup.string().required("Branch Code is required."),
				AccountNumber: yup.string().required("Account Number is required.")
			})
        }).required();

    return 	<Modal show={ props.show } title="Create Death Benefit Payout" icon={ faBookDead }>
				<Formik validationSchema={ validationSchema } initialValues={{ Amount: 0.00, BankAccount: { Payee: "", BankName: "", BranchCode: "", AccountNumber: "", BankAccountTypeId: 1 } }} onSubmit={ savePayout }>
					{
						({ errors }) =>
						<FormikForm>
							<Row>
								<Col>
									<InfoLabel invert label="Member Name" text={ props.member.Name }></InfoLabel>
								</Col>
								<Col>
									<InfoLabel invert label="Service Number (SNO)" text={ props.member.ServiceNumber }></InfoLabel>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<BootstrapForm.Group>
										<BootstrapForm.Label>Payout Amount</BootstrapForm.Label>
										<InputGroup>
											<InputGroup.Text className="border-right-0">R</InputGroup.Text>
											<Field className={ "form-control " + (errors.Amount ? "is-invalid" : "") } component={ NumberField } name="Amount" />
										</InputGroup>
										{
											<small className="text-danger pb-3">{ errors.Amount }</small>
										}
									</BootstrapForm.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Input label="Payee" name="BankAccount.Payee" />
									<Row>
										<Col>
											<Form.Input label="Bank Name" name="BankAccount.BankName" />
										</Col>
										<Col>
											<Form.Input label="Branch Code" name="BankAccount.BranchCode" />
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Input label="Account Number" name="BankAccount.AccountNumber" />
										</Col>
										<Col>
											<Form.Select label="Account Type" name="BankAccount.BankAccountTypeId">
												<option value={ BankAccountTypes.Cheque }>{ `${BankAccountTypes.Cheque} - Cheque` }</option>
												<option value={ BankAccountTypes.Savings }>{ `${BankAccountTypes.Savings} - Savings` }</option>
												<option value={ BankAccountTypes.Transmission }>{ `${BankAccountTypes.Transmission} - Transmission` }</option>
											</Form.Select>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="modal-footer pb-0">
								<Button disabled={ creatingDeathBenefitPayout } type="submit">{ creatingDeathBenefitPayout ? "Saving..." : "Save" }</Button>
								<Button disabled={ creatingDeathBenefitPayout } variant="danger" onClick={ props.onClose }>Cancel</Button>
							</Row>
						</FormikForm>
					}
				</Formik>
    		</Modal>
}