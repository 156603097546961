import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export var SortDirection = {
	Ascending: 0,
	Descending: 1
}

export default function SortableTH(props) {
	function getSortingIcon(column, currentSortingColumn, sortDirection) {
		if(column === currentSortingColumn) {
			if(sortDirection === SortDirection.Ascending) {
				return <FontAwesomeIcon icon={ faSortUp }></FontAwesomeIcon>
			} else {
				return <FontAwesomeIcon icon={ faSortDown }></FontAwesomeIcon>
			}
		} else {
			return false;
		}
	}

	function handleHeaderClicked() {
		if(props.store.SortColumn === props.column) {
			if(props.store.SortDirection === SortDirection.Ascending) {
				props.store.SortDirection = SortDirection.Descending;
			} else {
				props.store.SortDirection = SortDirection.Ascending;
			}
		} else {
			props.store.SortDirection = SortDirection.Ascending;
			props.store.SortColumn = props.column;
		}

		if(props.onUpdate) {
			props.onUpdate();
		}
	}

	return 	<th {...props} onClick={handleHeaderClicked} >
				 { getSortingIcon(props.column, props.store.SortColumn, props.store.SortDirection) } { props.children }
			</th>
}