import { Button, Row } from "react-bootstrap";
import Modal from "./Modal";

export default function ConfirmationModal(props) {

    const footer =  <Row>
						{
							!props.hideOK &&
							<Button onClick={ e => props.onResponse(true) }>OK</Button>
						}
						{
							!props.hideCancel &&
							<Button variant="danger" onClick={ e => props.onResponse(false) } className="ml-2">Cancel</Button>
						}
                    </Row>

    return <Modal show={ props.show } title={ props.title } footer={ footer } onHide={ e => props.onResponse(false)}>
        <h6>{props.message}</h6>
    </Modal>
}