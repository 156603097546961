import APIBase from "./APIBase";

/**
 * @typedef Control
 * @property {string} Id      
 * @property {number} BankCharges
 * @property {number} RandageRate
 * @property {number} DeathBenefitFundFee
 * @property {Date} ProcessingDate
 * @property {Date} MonthEndDate
 * @property {Date} YearEndDate
 * @property {string} UserId
 * @property {Date} DateCreated
 * @property {string} Username
 */

/**
 * @typedef ControlsService
 * @type {ControlsService}
 */
export default class ControlsService extends APIBase {

    /**
     * @param {string} apiUrl 
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Controls", sessionStore);
    }
    
    /**
     * @param {Control} control The control object to create
     * @returns { Promise<import("./APIBase").RequestResult<boolean>> }
     */
    async CreateControl(control) {
        return this.Post("CreateControl", control);
    }

    /**
     * @param {number} startIndex The 0 based start page index
     * @param {number} pageCount The total amount of results to return for the page
     * @returns { import("./APIBase").EnumerableRequestResult<Control> }
     */
    async GetControls(startIndex, pageCount) {
        return this.Get(`GetControls?startIndex=${startIndex}&pageCount=${pageCount}`);
    }
    
    /**
     * @returns { Promise<import("./APIBase").RequestResult<Control>> }
     */
    async GetActiveControl() {
        return this.Get(`GetActiveControl`);
    }
}