import { DateTime } from "luxon";
import { useState } from "react";
import ConfirmationModal from "../../../Core/ConfirmationModal";
import PeriodEndButton from "../PeriodEndButton";
import EndOfMonthModal from "./EndOfMonthModal";

export default function EndOfMonth(props) {
	/**@type {import("../../../../Services/ControlsService").Control} */
	const control = props.control;
	const processingDate = DateTime.fromISO(control.ProcessingDate);
	const monthEndDate = DateTime.fromISO(control.MonthEndDate);

	const [showProcessingModal, setShowProcessingModal] = useState(false);
	const [showYearEndModal, setShowYearEndModal] = useState(false);

	function handleEndOfMonthClicked() {
		setShowProcessingModal(true);
	}

	/**
	 * @param { import("../../../../Services/PeriodEndService").PeriodEndResult } result
	 */
	function handleProcessingComplete(result) {
		setShowProcessingModal(false);

		if(result) {
			if(result.MustProcessNextPeriod) {
				setShowYearEndModal(true);
			} else {
				props.onProcessingComplete();
			}
		}
	}

	function isDisabled() {
		//end of month is disabled if the processing date is before month end or the
		//if month end is after year end
		const monthEndDate = DateTime.fromISO(control.MonthEndDate);
		const yearEndDate = DateTime.fromISO(control.YearEndDate);

		var beforeProcessingEnd = (processingDate.diff(monthEndDate, "days").days <= 0);
		var afterYearEnd = (monthEndDate.diff(yearEndDate, "days").days > 0);

		return beforeProcessingEnd || afterYearEnd;
	}

	return 	<div>
				<PeriodEndButton onClick={ handleEndOfMonthClicked } disabled={ isDisabled() } date={ <span>{ monthEndDate.monthShort.toUpperCase() }<br/>{ monthEndDate.year }</span> } text="Month End" offset="38%"></PeriodEndButton>
				<EndOfMonthModal show={ showProcessingModal } control={ props.control } onClose={ handleProcessingComplete }></EndOfMonthModal>
				<ConfirmationModal hideCancel onResponse={ e => { setShowYearEndModal(false); props.onProcessingComplete() } } message="Processing Month End Completed. Year End must now be processed." show={ showYearEndModal } title="Month End"></ConfirmationModal>
			</div>
}