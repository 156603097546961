import { faArchive, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { Button, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { getTransactionType } from "../../../Stores/TransactionsStore";
import Pagination from "../../../Utilities/Paginator";
import Modal from "../../Core/Modal";
import BusyIndicator from "../../Core/BusyIndicator";
import Paginator from "../../Core/Paginator";
import ClickIcon from "../../Core/ClickIcon";
import ConfirmationDialog from "../../Core/ConfirmationDialog";

const BatchTransactionsModal = observer(function BatchTransactionsModal(props) {
    const store = useSessionStore();
    const pagination = useRef(new Pagination(getTransactionsForBatch));
	pagination.current.BatchId = props.batchId;

    const [transactions, setTransactions] = useState([]);
	const [currentTransaction, setCurrentTransaction] = useState(null);
	const [showingDeleteConfirm, showDeleteConfirm] = useState(false);
	const [deletingTransaction, setDeletingTransaction] = useState(false);

    async function getTransactionsForBatch() {
		await store.ControlsStore.GetActiveControl();
        var result = await store.TransactionsStore.GetTransactionsForBatch(pagination.current.BatchId, pagination.current.PageIndex, pagination.current.PageCount);

        if(result) {
            pagination.current.TotalData = result.Total;
            setTransactions(result.Data);
        } else {
            setTransactions([]);
        }
    }

	async function deleteTransaction(transactionId) {
		setCurrentTransaction(transactionId);
		showDeleteConfirm(true);
	}

	async function handleConfirmDelete(deleteTransaction) {
		showDeleteConfirm(false);

		if(deleteTransaction) {
			setDeletingTransaction(true);
			var result = await store.TransactionsStore.DeleteTransactionFromBatch(props.batchId, currentTransaction);
			setDeletingTransaction(false);
			setCurrentTransaction(null);

			if(result) {
				getTransactionsForBatch();
			}

		} else {
			setCurrentTransaction(null);
		}
	}

    useEffect(() =>
    {
        if(props.show === true && props.batchId) {
			pagination.current.PageIndex = 0;
            getTransactionsForBatch();
        }
    }, [props.show])

    return  <Modal show={ props.show } size="lg" title={`Batch Transactions - Batch: ${props.batchNumber}`} icon={ faArchive }>
		{
			showingDeleteConfirm === false && deletingTransaction === false &&
			<div>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>TNO</th>
							<th>Member</th>
							<th>Type</th>
							<th>Date</th>
							<th className="text-right">Amount</th>
							<th>Period</th>
							{
								props.allowDelete &&
								<th className="text-center">Actions</th>
							}
						</tr>
					</thead>
					{
						store.TransactionsStore.GettingTransactionsForBatch === false &&
						<tbody>
							{
								transactions.length > 0 &&
								transactions.map((transaction) =>
								<tr key={ transaction.Id }>
									<td>{ transaction.TransactionNumber }</td>
									<td>{ transaction.MemberDetails }</td>
									<td>{ getTransactionType(transaction.TransactionTypeId) }</td>
									<td>{ DateTime.fromISO(transaction.TransactionDate).toFormat("yyyy-MM-dd") }</td>
									<td className="text-right">R { transaction.Amount.toFixed(2) }</td>
									<td>{ store.ControlsStore.GetCurrentPeriod() }</td>
									{
										props.allowDelete &&
										<td className="text-center"><ClickIcon title="Delete Transaction" onClick={ e => deleteTransaction(transaction.Id) } icon={ faTrash }></ClickIcon></td>
									}
								</tr>)
							}
							{
								transactions.length === 0 &&
								<tr className="text-center">
									<td colSpan={ props.allowDelete ? "7": "6"}><b className="text-muted">This batch does not contain any Transactions</b></td>
								</tr>
							}
						</tbody>
					}
				</Table>
                {
					store.TransactionsStore.GettingTransactionsForBatch === false && transactions.length > 0 && pagination.current.TotalPages > 1 &&
                    <Paginator paginator={ pagination.current }></Paginator>
                }
                <BusyIndicator text="Retrieving Transactions..." show={ store.TransactionsStore.GettingTransactionsForBatch }></BusyIndicator>
                <Row className="modal-footer pb-0 mt-3">
                    <Button onClick={ e => props.onClose(false) } className="ml-2" type="button">Close</Button>
                </Row>
			</div>
			}
			{
				showingDeleteConfirm &&
				<ConfirmationDialog text="Are you sure you want to delete this transaction?" onResult={ handleConfirmDelete }></ConfirmationDialog>
			}
			<BusyIndicator text="Deleting Transaction..." show={ deletingTransaction }></BusyIndicator>
		</Modal>
});

export default BatchTransactionsModal;