import { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import Modal from "../../../../Core/Modal";
import InterestDistribution from "./InterestDistribution";
import Statements from "./Statements";
import AccordianItem from "../../../../Core/AccordianItem";
import IT3BForms from "./IT3BForms";
import YearEndRollover from "./YearEndRollover";
import BusyIndicator from "../../../../Core/BusyIndicator";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";

export default function EndOfYearModal(props) {
	const [gettingDetails, setGettingDetails] = useState(false);
	const [details, setDetails] = useState({ InterestRate: 0, TotalInterest: 0, InterestPaidOut: 0, InterestForYearAlreadyPaidOut: false });

	const store = useSessionStore();

	useEffect(() => {
		async function getDetails() {
			setGettingDetails(true);
			var result = await store.PeriodEndService.GetEndOfYearDetails();

			if(result) {
				setDetails(result.Data);
				setGettingDetails(false);
			}
		}

		if(props.show) {
			getDetails();
		}

	}, [props.show])

	function handleInterestProcessed(interestRate, totalInterest) {
		setDetails({ InterestRate: interestRate, TotalInterest: totalInterest, InterestPaidOut: totalInterest, InterestForYearAlreadyPaidOut: true });
	}

	const footer = <Button onClick={ e => props.onClose(null) } type="button">Close</Button>

    return 	<Modal icon={ faCalendarCheck } footer={ footer } show={ props.show } title="Process Year End" onHide={ e => props.onClose(null)}>
				{
					gettingDetails === false && details &&
					<Accordion defaultActiveKey="0">
						<AccordianItem eventKey="0" title={`1. Interest Distribution ${ details.InterestForYearAlreadyPaidOut ? "(Already Distributed)" : ""}`}>
							<InterestDistribution disabled={ props.endOfYearDisabled } details={ details } onInterestProcessed={ handleInterestProcessed }></InterestDistribution>
						</AccordianItem>
						<AccordianItem eventKey="1" title="2. Statements">
							<Statements disabled={ props.endOfYearDisabled }></Statements>
						</AccordianItem>
						<AccordianItem eventKey="2" title="3. IT3B Forms">
							<IT3BForms disabled={ props.endOfYearDisabled }></IT3BForms>
						</AccordianItem>
						<AccordianItem eventKey="3" title="4. Year End Rollover">
							<YearEndRollover disabled={ props.endOfYearDisabled } control={ props.control }></YearEndRollover>
						</AccordianItem>
					</Accordion>
				}
				<BusyIndicator text="Retrieving Year End Details..." show={ gettingDetails }></BusyIndicator>
    		</Modal>
}