import { makeAutoObservable } from "mobx";
import Paginator from "../Utilities/Paginator";
import SimpleCRUD from "../Utilities/SimpleCRUD";

export var Roles = {
    Admin: 1,
    Operator: 2
}

/**
 * @typedef UserStore
 * @type { UserStore }
 */
export default class UserStore { 
    Search = "";
        
    constructor(usersService, toastStore) {        
        /**@type { import("../Services/UsersService").UsersService } */
        this.UsersService = usersService;

        /**@type { import("../Stores/ToastStore").ToastStore } */
        this.ToastStore = toastStore;

        this.Paginator = new Paginator(async () => this.CRUD.GetEntries());

        this.CRUD = new SimpleCRUD("User", this.ToastStore, this.Paginator, this.GetUser, this.GetUsers, this.CreateUser, this.UpdateUser, this.DeleteUser);

        makeAutoObservable(this);
    }
    
    /**
     * @param {string} userId
     */
    GetUser = async (userId) => {
        return this.UsersService.GetUser(userId);
    }

    GetUsers = async () => {
        return this.UsersService.GetUsers(this.Paginator.PageIndex, this.Paginator.PageCount, this.Search, 0);
    }

    /**
     * @param { import('../Services/UsersService').User } user 
     * @returns { Promise<import("../Services/APIBase").RequestResult<string>>  } True if the create was successful, false if not
    */
    CreateUser = async (user) => {
        return this.UsersService.CreateUser(user);
    }

    /**
     * @param { import('../Services/UsersService').User } user 
     * @returns { Promise<import("../Services/APIBase").RequestResult<boolean>>  } True if the update was successful, false if not
    */
     UpdateUser = async (user) => {
        return this.UsersService.UpdateUser(user);
    }

    /**
     * @param { string } userId 
     * @returns { Promise<import("../Services/APIBase").RequestResult<boolean>>  } True if the delete was successful, false if not
    */
     DeleteUser = async (userId) => {
        return this.UsersService.DeleteUser(userId);
    }
}