import { makeAutoObservable, runInAction } from "mobx";

export function getTransactionType(transactionTypeId) {
    switch (transactionTypeId) {
        case 0:
            return "DB Subs";
        case 1:
            return "Cash";
        case 2:
            return "Withdrawal";
        case 3:
            return "Randage";
        case 4:
            return "Interest";
        case 5:
            return "Journal";
        case 6:
            return "Stop Order";
        case 7:
            return "Bank Charge";
        case 8:
            return "Cheque Fee";
        case 9:
            return "Opening Balance";
		default:
			return "Unknown"
    }
}

export var TransactionSortColumns = {
	Batch: 0,
	Type: 1,
	Date: 2,
	Reference: 3,
	Debit: 4,
	Credit: 5,
	Period: 6
}

/**
 * @typedef TransactionsStore
 * @type { TransactionsStore }
 */
export default class TransactionsStore {
    GettingPostedTransactionsForMember = false;
    GettingTransactionsForBatch = false;

	SortColumn = TransactionSortColumns.Date;
	SortDirection = 0;

    constructor(transactionsService, toastStore) {
        /**@type { import("../Services/TransactionsService").TransactionsService } */
        this.TransactionsService = transactionsService;

        /**@type { import("../Stores/ToastStore").ToastStore } */
        this.ToastStore = toastStore;

        makeAutoObservable(this);
    }

    /**
     * Retrieves the balances for the given member
     */
     async GetPostedTransactionsForYearForMember(year, memberId) {
        this.GettingPostedTransactionsForMember = true;

        var result = await this.TransactionsService.GetPostedTransactionsForYearForMember(year, memberId, this.SortColumn, this.SortDirection);
        runInAction(() => this.GettingPostedTransactionsForMember = false );

        if(result)
        {
            if(result.Success) {
                return result.Data
            } else {
                this.ToastStore.ShowErrorToast(`Failed to retrieve the member's transactions: ${result.Message}`);
            }
        }

        return null;
    }

    async GetTransactionsForBatch(batchId, startIndex, pageCount) {
        this.GettingTransactionsForBatch = true;

        var result = await this.TransactionsService.GetTransactionsForBatch(batchId, startIndex, pageCount);
        runInAction(() => this.GettingTransactionsForBatch = false);

        if(result)
        {
            if(result.Success) {
                return result
            } else {
                this.ToastStore.ShowErrorToast(`Failed to retrieve the member's transactions: ${result.Message}`);
            }
        }

        return null;
    }

    async ReverseTransaction(transactionId) {
        var result = await this.TransactionsService.ReverseTransaction(transactionId);

        if(result) {
            if(result.Success === false) {
                this.ToastStore.ShowErrorToast(`Failed to reverse the transactions: ${result.Message}`);
            }else {
				this.ToastStore.ShowSuccessToast("Transaction Reversed Successfully");
			}

            return result.Success;
        }

        return false;
    }

	/**
	 * Removes the given transaction from the given batch
	 * @param {string} batchId The id of the batch containing the transaction to delete
	 * @param {string} transactionId The id of the transaction to delete
	 * @returns { Promise<boolean> }
	 */
	async DeleteTransactionFromBatch(batchId, transactionId) {
        var result = await this.TransactionsService.DeleteTransactionFromBatch(batchId, transactionId);

        if(result) {
            if(result.Success === false) {
                this.ToastStore.ShowErrorToast(`Failed to delete the Transaction: ${result.Message}`);
            } else {
				this.ToastStore.ShowSuccessToast("Transaction Deleted Successfully");
			}

            return result.Success;
        }

        return false;
    }
}