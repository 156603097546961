import APIBase from "./APIBase";

/**
 * @typedef HistoriesService
 * @type {HistoriesService}
 */
export default class HistoriesService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Histories", sessionStore);
    }

    /**
     * @returns { Promise<import("./APIBase").EnumerableRequestResult<import("./TransactionsService").Transaction>> }
     */
     async GetHistoricalTransactionsForMember(memberId, startIndex, pageCount) {
        return this.Get(`GetHistoricalTransactionsForMember?memberId=${memberId}&startIndex=${startIndex}&pageCount=${pageCount}`);
    }
}