import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import BusyIndicator from "../../Core/BusyIndicator";
import ClickIcon from "../../Core/ClickIcon";

export default function Actions(props) {
	return	<div className="d-flex justify-content-center">
				{
					props.busy === false &&
					<div>
						<ClickIcon onClick={ e => props.onClick("pdf") } size="lg" icon={ faFilePdf } title="Download PDF Report"></ClickIcon>
					</div>
				}
				<BusyIndicator show={ props.busy } sm></BusyIndicator>
			</div>
}