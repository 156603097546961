export default function InfoLabel(props) {
    return <div>
        {
            props.invert && <span>{ props.label }</span>
        }

        {
            !props.invert && <b>{ props.label }</b>
        }
        <div>
            {
                props.invert && <b>{ props.text }</b>
            }

            {
                !props.invert && <span>{ props.text }</span>
            }
        </div>
    </div>
}