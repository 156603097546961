import { observer } from "mobx-react-lite"
import { Container } from "react-bootstrap";
import { HashRouter } from "react-router-dom";
import { useSessionStore } from "../Stores/SessionStore";
import Login from "./Pages/Login";
import Main from "./Pages/Main";

export const App = observer(function App(props) {
    const session = useSessionStore();

    return <HashRouter>
        <Container fluid className={`h-100`} style={{ backgroundColor: "rgb(250, 250, 250)" }}>
                {
                    session.UserIsLoggedIn &&
                    <Main></Main>
                }

                {
                    session.UserIsLoggedIn === false &&
                    <Login></Login>
                }
        </Container>
    </HashRouter>
});

export default App;