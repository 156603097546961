import { faDollarSign, faEdit, faUserEdit, faWallet } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import Modal from "../../../Core/Modal";
import MemberDetails from "../MemberDetails";
import MemberTransactionsAndBalancesModal from "../MemberTransactions/MemberTransactionsAndBalancesModal";
import CreateWithdrawalModal from "../Withdrawals/CreateWithdrawalModal";
import ClickIcon from "../../../Core/ClickIcon";
import MoreActionsDropDown from "./MoreActionsDropDown";
import BusyIndicator from "../../../Core/BusyIndicator";

var MemberActions = observer(function MemberActions(props) {
    var session = useSessionStore();

	const [reinstatingMember, setReinstatingMember] = useState(false);
    const [detailsModalShown, showDetailsModal] = useState(false);
    const [transactionsModalShown, showTransactionsModal] = useState(false);
    const [withdrawalModalShown, showWithdrawalModal] = useState(false);

    function handleMemberEditComplete(success) {
        if(success) {
            session.MembersStore.CRUD.GetEntries();
        }

        showDetailsModal(false);
    }

    return 	<div>
			{
				(reinstatingMember === false) &&
				<Row>
					<Col className="d-flex justify-content-around align-items-center">
						<ClickIcon title="Edit Member" onClick={ e => showDetailsModal(true) } icon={ faEdit }></ClickIcon>
						<ClickIcon title="Transactions & Balances" onClick={ e => showTransactionsModal(true) } icon={ faDollarSign }></ClickIcon>
						<ClickIcon title="Create a Withdrawal" onClick={ e => showWithdrawalModal(true) } icon={ faWallet }></ClickIcon>
						<MoreActionsDropDown onReinstating={ setReinstatingMember } member={ props.member }></MoreActionsDropDown>
					</Col>
				</Row>
			}

				<BusyIndicator sm show={ reinstatingMember }></BusyIndicator>
				<CreateWithdrawalModal memberId={ props.member.Id } memberName={ props.member.Name } memberServiceNumber={ props.member.ServiceNumber } onClose={ e => showWithdrawalModal(false) } show={ withdrawalModalShown }></CreateWithdrawalModal>
				<MemberTransactionsAndBalancesModal member={ props.member } memberId={ props.member.Id } onClose={ e => showTransactionsModal(false) } show={ transactionsModalShown }></MemberTransactionsAndBalancesModal>

				<Modal size="lg" show={ detailsModalShown } title="Edit Member" icon={ faUserEdit } onHide={ e => handleMemberEditComplete(false)}>
					<MemberDetails memberId={ props.member.Id } onEditComplete={ handleMemberEditComplete }></MemberDetails>
				</Modal>
			</div>
});

export default MemberActions;