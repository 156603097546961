import { makeAutoObservable, runInAction } from "mobx";

/**
 * @typedef SimpleCRUD<T>
 * @type { SimpleCRUD }
 * @template T
 */
export default class SimpleCRUD {
    GettingEntries = false;
    GettingEntry = false;    
    UpdatingEntry = false;
    DeletingEntry = false;

    /**@type { Array<T> } */
    Entries = [];

    GetEntryDelegate = null; 
    GetEntriesDelegate = null; 
    CreateEntryDelegate = null; 
    UpdateEntryDelegate = null; 
    DeleteEntryDelegate = null;

    DataType = "";

    constructor(dataType, toastStore, paginator, getEntry, getEntries, CreateEntry, updateEntry, deleteEntry) {
        this.DataType = dataType;
        this.ToastStore = toastStore;
        this.Paginator = paginator;
        
        this.GetEntriesDelegate = getEntries;
        this.GetEntryDelegate = getEntry;
        this.CreateEntryDelegate = CreateEntry;
        this.UpdateEntryDelegate = updateEntry;
        this.DeleteEntryDelegate = deleteEntry;

        makeAutoObservable(this);
    }

    /**
     * @param {string} entryId
     * @param { any } extraData
     */
     async GetEntry(entryId, extraData) {
        this.GettingEntry = true;

        var result = await this.GetEntryDelegate(entryId, extraData);

        runInAction(() => { this.GettingEntry = false; });

        if(result) {
            if(result.Success) {
                return result.Data;
            } else {
                this.ToastStore.ShowErrorToast(`An error occurred retrieving the ${this.DataType}. ${result.Message}`);
                return null;
            }
        }

        return null;
    }

    async GetEntries() {
        this.GettingEntries = true;

        var result = await this.GetEntriesDelegate();
        
        runInAction(() => { this.GettingEntries = false; });

        if(result) {
            if(result.Success) {
                runInAction(() => { 
                    this.Entries = result.Data;
                    this.Paginator.TotalData = result.Total;
                });
            } else  {            
                this.ToastStore.ShowErrorToast(`An error occurred retrieving the data. ${result.Message}`);
            }
        }
    }

    /**
     * @param { T } entry 
     * @param { any } extraData
     * @returns { Promise<boolean>  } True if the create was successful, false if not
    */
    async CreateEntry(entry, extraData) {
        this.UpdatingEntry = true;        
        
        var result = await this.CreateEntryDelegate(entry, extraData);

        runInAction(() => {this.UpdatingEntry = false; });

        if(result) {
            if(result.Success) {
                this.ToastStore.ShowSuccessToast(`${this.DataType} created successfully.`);
            }
            else {
                this.ToastStore.ShowErrorToast(`An error occurred creating the ${this.DataType}. ${result.Message}`);
            }

            return result.Success;
        }

        return false;
    }

    /**
     * @param { T } entry 
     * @param { any } extraData
     * @returns { Promise<boolean>  } True if the update was successful, false if not
    */
    async UpdateEntry(entry, extraData) {
        this.UpdatingEntry = true;
        
        var result = await this.UpdateEntryDelegate(entry, extraData);
        
        runInAction(() => {this.UpdatingEntry = false; });

        if(result) {
            if(result.Success) {
                this.ToastStore.ShowSuccessToast(`${this.DataType} updated successfully.`);
            }
            else {
                this.ToastStore.ShowErrorToast(`An error occurred updating the ${ this.DataType }. ${result.Message}`);
            }
    
            return result.Success;
        }

        return false;        
    }

    /**
     * @param { string } entryId 
     * @param { any } extraData
     * @returns { Promise<boolean>  } True if the delete was successful, false if not
    */
     async DeleteEntry(entryId, extraData) {
        this.DeletingEntry = true;  

        var result = await this.DeleteEntryDelegate(entryId, extraData);
        runInAction(() => { this.DeletingEntry = false; });
        
        if(result) {
            if(result.Success) {
                this.ToastStore.ShowSuccessToast(`${ this.DataType } deleted successfully.`);
            }
            else {
                this.ToastStore.ShowErrorToast(`An error occurred deleting the ${this.DataType}. ${result.Message}`);
            }
            
            return result.Success;
        }

        return false;
    }
}