import { Table } from "react-bootstrap";
import ReinstateMember from "../Members/Actions/ReinstateMember";

export default function TerminatedMembersList(props) {
	return <Table striped bordered hover>
		<thead>
			<tr>
				<th>Service Number</th>
				<th>Member Name</th>
				<th>Department</th>
				<th>Actions</th>
			</tr>
		</thead>
		<tbody>
			{
				props.members.length > 0 && props.members.map((member) =>
				<tr>
					<td>{ member.ServiceNumber }</td>
					<td>{ member.Name }</td>
					<td>{ member.Department }</td>
					<td>
						<ReinstateMember memberId={member.Id} onMemberReinstated={ e => props.onMemberReinstated(member.ServiceNumber) }></ReinstateMember>
					</td>
				</tr>)
			}
			{
				props.members.length === 0 &&
				<tr><td colSpan="4" className="text-muted  text-center">There are no terminated members in this import file</td></tr>
			}
		</tbody>
	</Table>
}