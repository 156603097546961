import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import BusyIndicator from "../../../Core/BusyIndicator";

const BalanceSummary = observer(function BalanceSummary(props) {
    const [balances, setBalances] = useState({ MaxTransfer: 0, FreeBalance: 0, CurrentBalance: 0 })
    const store = useSessionStore();

    useEffect(() => {
        async function getMemberBalanceSummary() {
            var balances = await store.MemberBalancesStore.GetBalanceSummaryForMember(props.memberId);

            if(balances) {
                setBalances(balances);
            }
        }
        if(props.memberId) {
            getMemberBalanceSummary();
        }
    }, []);


    return <div className="bg-primary text-white p-3 mb-3 mt-n3 mx-n3">
        {
            store.MemberBalancesStore.GettingMemberBalanceSummary === false &&
            <Row className="d-flex justify-content-between" style={{ paddingRight: "1px", paddingLeft: "1px" }}>
                <Col><b>Balance: R { balances.CurrentBalance.toFixed(2) }</b></Col>
                <Col className="text-center"><b>Max Transfer: R { balances.MaxTransfer.toFixed(2) }</b></Col>
                <Col className="text-right"><b>Free Balance: R { balances.FreeBalance.toFixed(2) }</b></Col>
            </Row>
        }
        <BusyIndicator white sm show={ store.MemberBalancesStore.GettingMemberBalanceSummary } text="Retrieving Member Balance Summary..."></BusyIndicator>
    </div>
});

export default BalanceSummary;