import { faBuilding, faUser, faUsers, faClipboard, faThumbsUp, faCogs, faWallet, faArchive, faFileUpload, faDownload, faCalendarCheck, faFileAlt, faBookDead } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useSessionStore } from "../../Stores/SessionStore";
import { Roles } from "../../Stores/UserStore";

function SidebarNavItem(props) {
    const activeSidebarItemStyle = { backgroundColor: "rgb(245, 245, 245)" };
    const sidebarItemStyle = { color: "#555", fontSize: "15px", fontFamily: "sans-serif", textDecoration: "none", display: "block", width: "100%", padding: "10px 10px 10px 15px"};

    return <Nav.Item style={{ width: "100%" }}>
                <NavLink to={ props.to } style={ sidebarItemStyle } activeStyle={ activeSidebarItemStyle }>
                    <FontAwesomeIcon color="#555" icon={ props.icon } style={{ width: "24px" }} /> <span style={{ marginLeft: "10px" }}>{ props.text }</span>
                </NavLink>
            </Nav.Item>
}

export const Sidebar = observer(function Sidebar(props) {
    const store = useSessionStore();
    const sidebarStyle = { display: "block", zIndex: "1000", width: "260px", position: "fixed", marginLeft: "-15px" };

    return  <Nav className="shadow h-100 bg-white" style={ sidebarStyle }>
                <SidebarNavItem to="/Members" icon={ faUsers } text="Members"></SidebarNavItem>
                <SidebarNavItem to="/DailyWithdrawals" icon={ faWallet } text="Daily Withdrawals"></SidebarNavItem>
				<SidebarNavItem to="/CATSTransfers" icon={ faDownload } text="CATS Transfers"></SidebarNavItem>
				<SidebarNavItem to="/DepositsImport" icon={ faFileUpload } text="Deposits Import"></SidebarNavItem>
                <SidebarNavItem to="/Batches" icon={ faArchive } text="Batches"></SidebarNavItem>
				<SidebarNavItem to="/PeriodEnd" icon={ faCalendarCheck } text="Period End"></SidebarNavItem>
				<SidebarNavItem to="/AdhocReports" icon={ faFileAlt } text="Adhoc Reports"></SidebarNavItem>
				<SidebarNavItem to="/DeathBenefitFund" icon={ faBookDead } text="Death Benefit Fund"></SidebarNavItem>
                {
                    store.Session.User.RoleId === Roles.Admin &&
                    <div>
                        <span style={{ marginLeft: "15px" }} className="d-block text-muted pt-3 pb-1">Admin</span>
                        <SidebarNavItem to="/Approvals" icon={ faThumbsUp } text="Approvals"></SidebarNavItem>
                        <SidebarNavItem to="/Controls" icon={ faClipboard } text="Controls"></SidebarNavItem>
                        <SidebarNavItem to="/Departments" icon={ faBuilding } text="Departments"></SidebarNavItem>
                        <SidebarNavItem to="/Users" icon={ faUser } text="Users"></SidebarNavItem>
                        <SidebarNavItem to="/Settings" icon={ faCogs } text="Settings"></SidebarNavItem>
                    </div>
                }
            </Nav>
});

export default Sidebar;