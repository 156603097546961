import { DateTime } from "luxon";
import { useState } from "react";
import PeriodEndButton from "../PeriodEndButton";
import EndOfYearModal from "./EndOfYearModal/EndOfYearModal";

export default function EndOfYear(props) {
	const [showProcessingModal, setShowProcessingModal] = useState(false);

	/**@type {import("../../../../Services/ControlsService").Control} */
	const control = props.control;
	const processingDate = DateTime.fromISO(control.ProcessingDate);
	const yearEndDate = DateTime.fromISO(control.YearEndDate);

	function handleEndOfYearClicked() {
		setShowProcessingModal(true);
	}

	function handleModalClosed(result) {
		props.onProcessingComplete();
	}

	function isDisabled() {
		//end of year is disabled if the processing date is before year end or the
		//month end end date is before year end
		var onProcessingDate = (processingDate.diff(yearEndDate, "days").days < 0);

		return onProcessingDate;
	}

	return	<div>
				<PeriodEndButton disabled={ false } onClick={ handleEndOfYearClicked } date={ <span>{ yearEndDate.year }</span> } text="Year End" offset="50%"></PeriodEndButton>
				<EndOfYearModal endOfYearDisabled={ isDisabled() } show={ showProcessingModal } control={ control } onClose={ handleModalClosed }></EndOfYearModal>
			</div>
}