import { ReactComponent as BusyIcon } from "../../Images/busy-icon.svg";
import { ReactComponent as BusyIconSmall } from "../../Images/busy-icon-small.svg";
import { ReactComponent as BusyIconSmallWhite } from "../../Images/busy-icon-small-white.svg";

export default function BusyIndicator(props) {

    function getAlignmentClass() {
        if(props.left) {
            return "text-left";
        } else if(props.right) {
            return "text-right";
        } else {
            return "text-center";
        }
    }

    if(props.show) {
        return <div className={getAlignmentClass()}>
                    {
                        props.sm &&
                        <div>
                            {
                                !props.white && <BusyIconSmall></BusyIconSmall>
                            }
                            {
                                props.white && <BusyIconSmallWhite></BusyIconSmallWhite>
                            }
                            {
                                props.text &&
                                <span className="mt-3"> { props.text }</span>
                            }
                        </div>
                    }
                    {
                        !props.sm &&
                        <div>
                            {
                                props.white && <BusyIcon></BusyIcon>
                            }
                            {
                                !props.white && <BusyIcon></BusyIcon>
                            }
                            {
                                props.text &&
                                <h5 className="mt-3">{ props.text }</h5>
                            }
                        </div>
                    }
                </div>
    } else {
        return <div></div>
    }
}