import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import Actions from "./Actions";
import ReportDateFilterModal from "./ReportDateFilterModal";
import ReportDateRangeFilterModal from "./ReportDateRangeFilterModal";
import ReportPeriodFilterModal from "./ReportPeriodFilterModal";
import ReportPeriodRangeFilterModal from "./ReportPeriodRangeFilterModal";
import ReportAmountFilterModal from "./ReportAmountFilterModal";

export const AdhocReports = observer(function AdhocReports() {
	const store = useSessionStore();

	const [reportData, setReportData] = useState({ ReportName: "", onClose: () => null, Show: false });
	const [reportPeriodData, setReportPeriodData] = useState({ ReportName: "", onClose: () => null, Show: false });
	const [reportDateRangeFilterData, setReportDateRangeFilterData] = useState({ ReportName: "", onClose: () => null, Show: false });
	const [reportPeriodRangeFilterData, setReportPeriodRangeFilterData] = useState({ ReportName: "", onClose: () => null, Show: false });

	async function clearReportData() {
		setReportData({ ReportName: "", Show: false, onClose: (data) => null});
		setReportPeriodData({ ReportName: "", Show: false, onClose: (data) => null});
		setReportDateRangeFilterData({ ReportName: "", Show: false, onClose: (data) => null});
		setReportPeriodRangeFilterData({ ReportName: "", Show: false, onClose: (data) => null});
	}

	async function handleRetrievalOfAuditTrailsClicked(format) {
		setReportData({ ReportName: "Retrieval of Audit Trails", Show: true,
			onClose: (data) => {
				if(data) {
					store.AdhocReportsStore.DownloadRetrievalOfAuditTrailsReport(data.Date);
				}

				clearReportData();
			}
		});
	}

	async function handleMonthlyWithdrawalsCountReportClicked(format) {
		setReportPeriodRangeFilterData({ ReportName: "Monthly Withdrawals Count", Show: true,
			onClose: (data) => {
				if(data) {
					store.AdhocReportsStore.DownloadMonthlyWithdrawalCountReport(data.From, data.To);
				}

				clearReportData();
			}
		});
	}

	async function handleDailyWithdrawalsCountForPeriodReportClicked(format) {
		setReportPeriodData({ ReportName: "Daily Withdrawals Count For Period", Show: true,
			onClose: (data) => {
				if(data) {
					store.AdhocReportsStore.DownloadDailyWithdrawalCountForPeriodReport(data.Date);
				}

				clearReportData();
			}
		});
	}

	async function handleBankChangeAuditReport(format) {
		setReportDateRangeFilterData({ ReportName: "Bank Change Audit", Show: true,
			onClose: (data) => {
				if(data) {
					store.AdhocReportsStore.DownloadBankChangeAuditReport(data.From, data.To);
				}

				clearReportData();
			}
		});
	}

	async function handleMembersMoreThanSpecifiedNotTerminatedReport(format) {
		setReportData({ ReportName: "Status 1 & 3 Balances over specified amount", Show: true,
			onClose: (data) => {
				if(data) {
					store.AdhocReportsStore.DownloadMembersMoreThanSpecifiedNotTerminatedReport(data.Amount);
				}

				clearReportData();
			}
		});
	}

	function ReportEntry(props) {
		return 	<tr>
					<td>{ props.title }</td>
					<td><Actions onClick={ props.onClick } busy={ props.busy }></Actions></td>
				</tr>
	}

	return 	<Panel>
				<PanelHeader icon={ faFileAlt } title="Adhoc Reports"></PanelHeader>
				<Row>
					<Col lg={12}>
						<Table hover bordered>
							<tbody>
								<ReportEntry title="Bank Change Audit" onClick={ handleBankChangeAuditReport }  busy={ store.AdhocReportsStore.DownloadingBankChangeAuditReport }></ReportEntry>
								<ReportEntry title="Daily Withdrawal Count For Period" onClick={ handleDailyWithdrawalsCountForPeriodReportClicked } busy={ store.AdhocReportsStore.DownloadingDailyWithdrawalCountForPeriodReport }></ReportEntry>
								<ReportEntry title="Department Strengths" onClick={ (format) => store.AdhocReportsStore.DownloadDepartmentStrengthReport() } busy={ store.AdhocReportsStore.DownloadingDepartmentStrengthReport }></ReportEntry>
								<ReportEntry title="Duplicate Banking Details" onClick={ (format) => store.AdhocReportsStore.DownloadDuplicateBankingDetailsReport() } busy={ store.AdhocReportsStore.DownloadingDuplicateBankingDetailsReport }></ReportEntry>

								<ReportEntry title="Estate Late" onClick={ (format) => store.AdhocReportsStore.DownloadEstateLateReport() } busy={ store.AdhocReportsStore.DownloadingEstateLateReport  }></ReportEntry>

								<ReportEntry title="FICA Compliant Members" onClick={ (format) => store.AdhocReportsStore.DownloadFICACompliantMembersReport() } busy={ store.AdhocReportsStore.DownloadingFICACompliantMembersReport }></ReportEntry>
								<ReportEntry title="FICA Non-Compliant Members" onClick={ (format) => store.AdhocReportsStore.DownloadFICANonCompliantMembersReport() } busy={ store.AdhocReportsStore.DownloadingFICANonCompliantMembersReport }></ReportEntry>

								<ReportEntry title="Members With Minus Balance" onClick={ (format) => store.AdhocReportsStore.DownloadMembersLessThanR0ActiveReport() } busy={ store.AdhocReportsStore.DownloadingMembersLessThanR0ActiveReport }></ReportEntry>
								<ReportEntry title="Monthly Withdrawal Count" onClick={ handleMonthlyWithdrawalsCountReportClicked } busy={ store.AdhocReportsStore.DownloadingMonthlyWithdrawalCountReport }></ReportEntry>

								<ReportEntry title="No Movement: < R100 and Status = 1" onClick={ (format) => store.AdhocReportsStore.DownloadNoMovementBalanceLessThanR100ActiveReport() } busy={ store.AdhocReportsStore.DownloadingNoMovementBalanceLessThanR100ActiveReport }></ReportEntry>
								<ReportEntry title="No Movement: > R100 and Status ≠ 1" onClick={ (format) => store.AdhocReportsStore.DownloadNoMovementBalanceMoreThanR100NotActiveReport() } busy={ store.AdhocReportsStore.DownloadingNoMovementBalanceMoreThanR100NotActiveReport }></ReportEntry>
								<ReportEntry title="No Movement: > R3 and Status = 1" onClick={ (format) => store.AdhocReportsStore.DownloadNoMovementBalanceMoreThanR3ActiveReport() } busy={ store.AdhocReportsStore.DownloadingNoMovementBalanceMoreThanR3ActiveReport }></ReportEntry>

								<ReportEntry title="Pensioners With No Address" onClick={ (format) => store.AdhocReportsStore.DownloadPensionersWithNoAddressReport() } busy={ store.AdhocReportsStore.DownloadingPensionersWithNoAddressReport }></ReportEntry>

								<ReportEntry title="Retrieval of Audit Trails" onClick={ handleRetrievalOfAuditTrailsClicked } busy={ store.AdhocReportsStore.DownloadingRetrievalOfAuditTrailsReport }></ReportEntry>

								<ReportEntry title="Status 1 Members < R3" onClick={ (format) => store.AdhocReportsStore.DownloadMembersLessThanR3ActiveReport() } busy={ store.AdhocReportsStore.DownloadingMembersLessThanR3ActiveReport }></ReportEntry>
								<ReportEntry title="Status 1 & 3 Balances over specified amount" onClick={ (format) => handleMembersMoreThanSpecifiedNotTerminatedReport(format) } busy={ store.AdhocReportsStore.DownloadingMembersMoreThanSpecifiedNotTerminatedReport }></ReportEntry>

								<ReportEntry title="Terminated Member Balances" onClick={ (format) => store.AdhocReportsStore.DownloadTerminatedMemberBalancesReport() } busy={ store.AdhocReportsStore.DownloadingTerminatedMemberBalancesReport }></ReportEntry>
								{
/**
									<tr>
									<td>Balance Bands All</td>
									<td><Actions onClick={ handleBalanceBandsAllClicked } busy={ downloadingBalanceBandsAll }></Actions></td>
								</tr>
								<tr>
									<td>Balance Bands Small</td>
									<td><Actions onClick={ handleBalanceBandsSmallClicked } busy={ downloadingBalanceBandsSmall }></Actions></td>
								</tr>
								*/}
							</tbody>
						</Table>
					</Col>
				</Row>
				<ReportDateFilterModal reportName={ reportData.ReportName } onClose={ reportData.onClose } show={ reportData.Show }></ReportDateFilterModal>
				<ReportAmountFilterModal reportName={ reportData.ReportName } onClose={ reportData.onClose } show={ reportData.Show }></ReportAmountFilterModal>
				<ReportPeriodFilterModal reportName={ reportPeriodData.ReportName } onClose={ reportPeriodData.onClose } show={ reportPeriodData.Show }></ReportPeriodFilterModal>
				<ReportDateRangeFilterModal reportName={ reportDateRangeFilterData.ReportName } onClose={ reportDateRangeFilterData.onClose } show={ reportDateRangeFilterData.Show }></ReportDateRangeFilterModal>
				<ReportPeriodRangeFilterModal reportName={ reportPeriodRangeFilterData.ReportName } onClose={ reportPeriodRangeFilterData.onClose } show={ reportPeriodRangeFilterData.Show }></ReportPeriodRangeFilterModal>
			</Panel>
});

export default AdhocReports;