import { makeAutoObservable, runInAction } from "mobx";
import Paginator from "../../Utilities/Paginator";
import SimpleCRUD from "../../Utilities/SimpleCRUD";

export var MemberStatuses = {
    ActiveMember: 1,
    ActivePensioner: 3,
    Terminated: 4,

    GetMemberStatusText: function(memberStatusId) {
        switch (memberStatusId) {
            case this.ActiveMember:
                return "1 - Active Member";
            case this.ActivePensioner:
                return "3 - Active Pensioner";
            case this.Terminated:
                return "4 - Terminated";
            default:
                return "Unknown";
        }
    }
}

export var BankAccountTypes = {
    Cheque: 1,
    Savings: 2,
    Transmission: 3,

    GetText: function(bankAccountTypeId) {
        switch (bankAccountTypeId) {
            case this.Cheque:
                return "1 - Cheque";
            case this.Savings:
                return "2 - Savings";
            case this.Transmission:
                return "3 - Transmission";
            default:
                return "Unknown";
        }
    }
}

/**
 * @typedef MemberStore
 * @type { MemberStore }
 */
export default class MemberStore {
    Search = "";
    MemberStatusId = 0;
    TerminatingMember = false;
    ReinstatingMember = false;
    GettingMemberTerminationInterest = false

    constructor(membersService, toastStore) {
        /**@type { import("../Services/MembersService").MembersService } */
        this.MembersService = membersService;

        /**@type { import("../Stores/ToastStore").ToastStore } */
        this.ToastStore = toastStore;

        this.Paginator = new Paginator(async () => this.CRUD.GetEntries());

        /**@type { import("../Utilities/SimpleCRUD").SimpleCRUD<import("../Services/MembersService").Member> } */
        this.CRUD = new SimpleCRUD("Member", this.ToastStore, this.Paginator, this.GetMember, this.GetMembers, this.CreateMember, this.UpdateMember, null);

        makeAutoObservable(this);
    }

    /**
     * @param {string} memberId
     */
    GetMember = async (memberId) => {
        return this.MembersService.GetMember(memberId);
    }

    GetMembers = async () => {
        return this.MembersService.GetMembers(this.Paginator.PageIndex, this.Paginator.PageCount, this.MemberStatusId, this.Search);
    }

    /**
     * @param { import('../Services/MembersService').Member } member
     * @returns { Promise<import("../Services/APIBase").RequestResult<string>>  } True if the create was successful, false if not
    */
    CreateMember = async (member) => {
        return this.MembersService.CreateMember(member);
    }

    /**
     * @param { import('../Services/MembersService').Member } member
     * @param {boolean} updateBankAccount
     * @returns { Promise<import("../Services/APIBase").RequestResult<boolean>>  } True if the update was successful, false if not
    */
     UpdateMember = async (member, updateBankAccount) => {
        return this.MembersService.UpdateMember(member, updateBankAccount);
    }

    /**
     * @param {string} memberId The Id of the member to terminate
     * @param {Date} terminationDate The date of the members termination
     * @param {boolean} payInterest Whether the member should pay interest or not
     * @param {number} interest The amount of interest the member should
     */
    async TerminateMember(memberId, terminationDate, payInterest, interest) {
        this.TerminatingMember = true;

        var result = await this.MembersService.TerminateMember(memberId, terminationDate, payInterest, interest);

        runInAction(() => this.TerminatingMember = false);

        if(result) {
            if(result.Success) {
                this.ToastStore.ShowSuccessToast(`Member successfully terminated.`, "Member Terminated.");
            } else {
                this.ToastStore.ShowErrorToast(`Failed to terminate member: ${result.Message}`, "Member Termination Failed.");
            }

            return result.Success;
        }

        return false;
    }

    /**
     * @param {string} memberId The Id of the member to terminate
     * @param {Date} terminationDate The date of the members termination
     * @param {boolean} payInterest Whether the member should pay interest or not
     * @param {number} interest The amount of interest the member should
     */
     async GetMemberTerminationInterest(memberId, terminationDate) {
        this.GettingMemberTerminationInterest = true;

        var result = await this.MembersService.GetMemberTerminationInterest(memberId, terminationDate);

        runInAction(() => this.GettingMemberTerminationInterest = false);

        if(result) {
            if(result.Success) {
                return result.Data;
            } else {
                this.ToastStore.ShowErrorToast(`Failed to retrieve member termination interest: ${result.Message}`, "Member Termination Failed.");
            }
        }

        return null;
    }

    /**
     * @param {string} memberId The Id of the member to reinstate
     */
     async ReinstateMember(memberId) {
        this.ReinstatingMember = true;

        var result = await this.MembersService.ReinstateMember(memberId);

        runInAction(() => this.ReinstatingMember = false);

        if(result) {
            if(result.Success) {
                this.ToastStore.ShowSuccessToast(`Member Successfully Reinstated.`, "Member Reinstated.");
            } else {
                this.ToastStore.ShowErrorToast(`Failed to reinstate member: ${result.Message}`, "Member Reinstate Failed.");
            }

            return result.Success;
        }

        return false;
    }
}